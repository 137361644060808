/* eslint-disable react/self-closing-comp */
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as S from './Logo.styled';

function Logo({ history: { push } }) {
  const brasao = useSelector(state =>
    state.configuration.highContrast
      ? state.configuration.brasaoPB
      : state.configuration.brasao
  );

  function onClick() {
    push('/');
  }

  return (
    <S.Logo
      onKeyDown={event => {
        if (event.keyCode === 13) onClick();
      }}
      onClick={onClick}
      tabIndex={0}
      src={brasao}
      alt="Brasão"
    ></S.Logo>
  );
}

export default withRouter(Logo);
