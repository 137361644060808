export function getAndFormatSearchParams(data, filtersArray) {
  let searchParams = new URLSearchParams();
  const filtersArrayLowwer = filtersArray.map(item => item.toLowerCase());

  Object.entries(data).forEach(key => {
    const values = key.filter(
      () => filtersArrayLowwer.includes(key[0].toLowerCase()) && key[1]
    );

    if (values.length > 0) searchParams.append(values[0], values[1]);
  });

  const queryString = searchParams.toString();
  return queryString;
}

export function getAndFormatSearchParamsBuscaAvancada(data, filtersArray) {
  let searchParams = new URLSearchParams();
  const filtersArrayLowwer = filtersArray.map(item => item.toLowerCase());

  Object.entries(data).forEach(key => {
    const values = key.filter(() => {
      if (key[0].toLowerCase() !== 'filtros')
        return filtersArrayLowwer.includes(key[0].toLowerCase()) && key[1];
    });

    if (values.length > 0) searchParams.append(values[0], values[1]);
  });

  const chavesFiltro = Object.keys(data.filtros);

  chavesFiltro.forEach(filtro => {
    const valuesFiltro = [];

    if (data.filtros[`${filtro}`] !== undefined) {
      valuesFiltro.push(data.filtros[`${filtro}`].Campo);
      valuesFiltro.push(data.filtros[`${filtro}`].Valor);
      valuesFiltro.push(data.filtros[`${filtro}`].TipoValor);

      if (valuesFiltro.length > 0) {
        searchParams.append(
          valuesFiltro[0],
          `${valuesFiltro[1]},${valuesFiltro[2]}`
        );
      }
    }
  });

  const queryString = searchParams.toString();
  return queryString;
}
