import React from 'react';
import moment from 'moment';

import * as S from './RangePicker.styled';

const dateFormat = 'DD/MM/YYYY';

function RangePickerComponent({ onCalendarChange, valorPadrao }) {
  return (
    <S.StyledRangePicker
      onCalendarChange={onCalendarChange}
      format={dateFormat}
      defaultValue={
        valorPadrao?.length > 1 && [
          moment(valorPadrao[0], dateFormat),
          moment(valorPadrao[1], dateFormat),
        ]
      }
    />
  );
}

export default RangePickerComponent;
