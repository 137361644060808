import styled from 'styled-components';
import { Modal as ModalAntDesign } from 'antd';

export const Modal = styled(ModalAntDesign).attrs(props => ({
  bodyStyle: {
    maxHeight: props.maxHeight,
    minHeight: props.minHeight,
    overflow: 'auto',
  },
}))`
  .ant-modal-title {
    color: ${({ corFonteTituloTelas }) => `${corFonteTituloTelas}`};
    font-size: ${({ theme }) => `${16 + 2 * theme.zoomFonte}px`};
  }
`;
