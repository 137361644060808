import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import SectionTitle from '../SectionTitle/SectionTitle.component';
import { Container, Content } from './style';
import RemoveDiacritic from '../../hooks/removeDiacritic';

const InformacaoFilter = forwardRef(
  ({ titulo, informacao, icon, setSearchValue }, ref) => {
    const [html, setHtml] = useState();
    const tagsPermitidas = ['LI', 'P', 'BODY'];
    const regexToRemoveHtmlTag = /(<([^>]+)>)/gi;

    useImperativeHandle(ref, () => ({
      filterElements(value) {
        const buscaSemAcentos = RemoveDiacritic(value);
        if (buscaSemAcentos) {

          const newDocumentHTML = new DOMParser().parseFromString(
            informacao,
            'text/html'
          );

          const elementos = newDocumentHTML.body.getElementsByTagName('*');

          const hasMatchWord = string => {
            return RemoveDiacritic(string)?.indexOf(buscaSemAcentos) !== -1;
          };

          let ultimoElementoPermitido; 

          for(let i = 0; i < elementos.length; i++) {
            let elemento = elementos[i];
            
            let elementoSemAcento = elemento.innerHTML.replace(regexToRemoveHtmlTag, '');
            elementoSemAcento = RemoveDiacritic(elementoSemAcento);
            
            let IndexFound = hasMatchWord(elementoSemAcento);
            let isTagPermitida = tagsPermitidas.includes(elemento.tagName);

            //Nesse if verificamos se encontramos o termo na busca e se ele é uma das tags permitidas definidas nas consts no começo
            //da classe, caso encontrado ele define o display como block, caso encontre e não seja uma tag permitida, definimos como
            // '', e caso não encontre colocamos 'none'.

            if(IndexFound && isTagPermitida) {
              ultimoElementoPermitido = elemento;
              elemento.style.display= "block";
            }
            
            else if(IndexFound || (!isTagPermitida && ultimoElementoPermitido)) 
              elemento.style.display= "";
            
            else {
              elemento.style.display= "none";
            }
          };

        const novoHtml = newDocumentHTML.body.innerHTML;
        return setHtml(novoHtml);
        } else {
          setSearchValue('');
        }
      },
      onChangeInfo(e) {
        setSearchValue(e.target.value);
        if (!e.target.value) {
          setHtml(informacao);
        }
      },
    }));

    useEffect(() => {
      setSearchValue('');
      setHtml(informacao);
    }, [informacao]);

    return (
      <>
        <SectionTitle titulo={titulo} icon={icon} />
        <Container>
          <Content dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </>
    );
  }
);

export default InformacaoFilter;
