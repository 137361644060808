/* eslint-disable import/no-cycle */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { iconEnum } from '~/modules/Helper/iconEnum';
import { useMenus } from '~/contexts/menu.context';
import { useSelector } from 'react-redux';
import { getUrlDominio } from '~/modules/Helper/getUrl-helper';
import * as S from './Menu.styled';
import Tooltip from '../Tooltip/Tooltip.component';

function Menu({
  openKeys,
  setOpenKeys,
  setCollapsed,
  collapsed,
  history: { push },
}) {
  const { menus, setMenuSelecionado } = useMenus();
  const { highContrast } = useSelector(state => state.configuration);

  function handleTitleClick(name) {
    if (openKeys.includes(name))
      setOpenKeys(openKeys.filter(key => key !== name));
    else setOpenKeys([openKeys, name]);
  }

  function handleRedirect(externo, uri, subMenu) {
    if (externo) {
      let newUri = '';
      if (subMenu !== null) {
        newUri = uri;
      } else {
        newUri = getUrlDominio() + uri;
      }
      window.open(newUri);
    } else {
      push(uri);
    }
  }

  function renderMenuItem(menu) {
    const { TituloMenuPai, SubMenu, Icone, Titulo, URI, Externo } = menu;
    const TitleMenu = () => <span>{Titulo}</span>;

    const TitleMenuTooltip = () => (
      <Tooltip placement="right" title={Titulo}>
        <span className="menu-titulo" title="">
          {Titulo}
        </span>
      </Tooltip>
    );

    if (SubMenu) {
      return (
        <S.SubMenu
          key={Titulo}
          title={collapsed ? <TitleMenu /> : <TitleMenuTooltip />}
          tabIndex={0}
          icon={Icone && iconEnum.find(x => x.name === Icone)?.value()}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              event.preventDefault();
              event.stopPropagation();
              handleTitleClick(Titulo);
            }
          }}
          onTitleClick={() => {
            handleTitleClick(Titulo);
          }}
        >
          {SubMenu &&
            SubMenu.map(submenu =>
              renderMenuItem({ TituloMenuPai: Titulo, ...submenu })
            )}
        </S.SubMenu>
      );
    }

    return (
      <S.MenuItem
        key={TituloMenuPai + Titulo}
        tabIndex={0}
        icon={Icone && iconEnum.find(x => x.name === Icone)?.value()}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            setOpenKeys([]);
            handleRedirect(Externo, URI, SubMenu);
          }
        }}
        onClick={() => {
          handleRedirect(Externo, URI, SubMenu);
          setMenuSelecionado(menu);
          if (setCollapsed) setCollapsed(false);
        }}
        style={{ height: 'auto' }}
        title={Titulo}
      >
        {collapsed ? <TitleMenu /> : <TitleMenuTooltip />}
      </S.MenuItem>
    );
  }

  return (
    <>
      <S.MenuStyles />
      <S.Menu
        theme={!highContrast ? 'dark' : 'light'}
        mode="inline"
        inlineIndent={16}
        forceSubMenuRender
      >
        {menus && menus.map(renderMenuItem)}
      </S.Menu>
    </>
  );
}

export default withRouter(Menu);
