import { TAMANHO_HOME_ITEM_ENUM } from '~/modules/Helper/tamanhoHomeItemEnum';

export const calcularAlturaLargura = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '225px';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '300px';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '375px';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '450px';
    default:
      return '150px';
  }
};

export const calcularAlturaLarguraIconeMedio = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_1x3:
      return '70px';
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '130px';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '180px';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '200px';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '210px';
    default:
      return '100px';
  }
};
