import * as S from './ButtonGoBack.styled';
import React from 'react';
function ButtonGoBack({ OnClick, MarginLeft = '0px' }) {
  return (
    <>
      <S.ButtonGoBack style={{ marginLeft: MarginLeft }} onClick={OnClick}>
        Voltar
      </S.ButtonGoBack>
    </>
  );
}

export default ButtonGoBack;
