import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleHighContrast,
  increaseFontSizePage,
  decreaseFontSizePage,
  resetFontSizePage,
} from '~/store/modules/configuration/configuration.actions';
import { Button } from '../Button/Button.styled';
import Icons from '../Icons/Icons.component';
import Tooltip from '../Tooltip/Tooltip.component';
import {
  MIN_ZOOM_FONTE,
  MAX_ZOOM_FONTE,
} from '~/store/modules/configuration/configuration.reducer';

function Acessibility() {
  const dispatch = useDispatch();

  const { zoomFonte } = useSelector(state => state.configuration);

  const botaoAumentarDesabilitado = zoomFonte >= MAX_ZOOM_FONTE;
  const botaoDiminuirDesabilitado = zoomFonte <= MIN_ZOOM_FONTE;

  // se a tela tiver menos de 1024px,
  // remove os botões de zoom de fonte
  // e reseta o font-size da página para o origial
  const exibeBotoesZoom = useMediaQuery({ query: '(min-width: 1024px)' });

  useEffect(() => {
    dispatch(resetFontSizePage());
  }, [exibeBotoesZoom]);

  return (
    <>
      {exibeBotoesZoom && (
        <>
          <Tooltip title="Tamanho Original" destroyTooltipOnHide>
            <Button
              tabIndex={0}
              type="primary"
              style={{
                width: 25,
                padding: 0,
                height: 25,
                fontSize: 14,
                marginRight: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              onClick={() => dispatch(resetFontSizePage())}
            >
              A
            </Button>
          </Tooltip>

          <Tooltip title="Aumentar Fonte" destroyTooltipOnHide>
            <Button
              tabIndex={0}
              type="primary"
              style={{
                width: 25,
                padding: 0,
                height: 25,
                fontSize: 14,
                marginRight: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              disabled={botaoAumentarDesabilitado}
              onClick={() => dispatch(increaseFontSizePage())}
            >
              A+
            </Button>
          </Tooltip>

          <Tooltip title="Diminuir Fonte" destroyTooltipOnHide>
            <Button
              tabIndex={0}
              type="primary"
              style={{
                width: 25,
                padding: 0,
                height: 25,
                fontSize: 14,
                marginRight: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              disabled={botaoDiminuirDesabilitado}
              onClick={() => dispatch(decreaseFontSizePage())}
            >
              A-
            </Button>
          </Tooltip>
        </>
      )}
      <Tooltip title="Alto Contraste">
        <Button
          tabIndex={0}
          type="primary"
          style={{
            width: 25,
            padding: 0,
            height: 25,
            fontSize: 14,
            marginRight: '40px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
          onClick={() => dispatch(toggleHighContrast())}
        >
          <Icons.BulbOutlined style={{ fontSize: 16 }}></Icons.BulbOutlined>
        </Button>
      </Tooltip>
    </>
  );
}

export default Acessibility;
