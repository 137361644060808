import styled from 'styled-components';
import { Card, Icons, Typography } from '~/components';

export const CardNoConfigHome = styled(Card)`
  && {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    margin: 100px 0px;
  }
`;

export const IconNoConfigHome = styled(Icons.SettingOutlined)`
  && {
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(201, 201, 201);
  }
`;

export const TextNoConfigHome = styled(Typography.Text)`
  && {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    color: rgb(201, 201, 201);
  }
`;
