import api from '~/services/api';

function getHomeService(exercicio) {
  const response = api.get(`/home/${exercicio}`);
  return response;
}

function getHomeItemService(id, exercicio) {
  const response = api.get(`/home/${id}/${exercicio}`);
  return response;
}

export { getHomeService, getHomeItemService };
