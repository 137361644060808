import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEstruturaOrganizacionalDetalheService } from '../services/estruturaOrganizacional.service';
import {
  fileManagerPaiDownload,
  getFullUrl,
} from '~/services/FileManager/file-manager.service';

import {
  Card,
  Typography,
  Button,
  Tooltip,
  Icons,
  Row,
  Col,
  MapButton,
  ButtonGoBack,
} from '~/components/';
import { Title, Content } from './style';
import { ChooseIconFile } from '~/modules/Helper/chooseIconFile';
import { iconEnum } from '~/modules/Helper/iconEnum';

function EstruturaOrganizacionalDetalhe({ match }) {
  const corTema = useSelector(state => state.configuration.corTema);
  const [
    estruturaOrganizacionalDetalhe,
    setEstruturaOrganizacionalDetalhe,
  ] = useState();
  const [imgSecretario, setImgSecretario] = useState();
  const history = useHistory();

  async function getUrl(response) {
    return getFullUrl(response);
  }
  useEffect(() => {
    async function getEstruturaOrganizacionalConfiguracao() {
      const response = await getEstruturaOrganizacionalDetalheService(
        Number(match.params.id)
      );
      setEstruturaOrganizacionalDetalhe(response.data);

      const { UrlImagemSecretario } = response.data;
      let img = null;

      if (UrlImagemSecretario) {
        img = await getUrl(UrlImagemSecretario);
      }
      setImgSecretario(img);
    }
    getEstruturaOrganizacionalConfiguracao();
  }, [match]);

  function goBack() {
    history.goBack();
  }
  return (
    <>
      {estruturaOrganizacionalDetalhe && (
        <>
          <Title>
            <Typography.Title
              level={1}
              style={{
                color: corTema,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {estruturaOrganizacionalDetalhe.Nome}
            </Typography.Title>

            <Typography.Title level={4} style={{ color: corTema }}>
              {estruturaOrganizacionalDetalhe.SecretarioResponsavel}
            </Typography.Title>

            <Typography.Text
              level={4}
              style={{ color: '#000', fontStyle: 'italic' }}
            >
              Secretário/Responsável
            </Typography.Text>
          </Title>

          <Row
            style={{
              margin: '20px',
              alignItems: 'center',
            }}
          >
            {imgSecretario != null && (
              <Col style={{ marginRight: '1%', width: '19%' }}>
                <img
                  src={imgSecretario}
                  alt="Foto do Secretário / Responsável"
                  style={{
                    maxWidth: '300px',
                    maxHeight: '250px',
                    borderRadius: '2px',
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </Col>
            )}

            <Col style={{ width: imgSecretario != null ? '80%' : '100%' }}>
              <Card title="Informação">
                <Typography.Text style={{ color: '#000' }}>
                  Telefone:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Telefone}
                  </Typography.Text>
                </Typography.Text>

                <br />

                <Typography.Text style={{ color: '#000' }}>
                  E-mail:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Email}
                  </Typography.Text>
                </Typography.Text>

                <br />

                <Typography.Text style={{ color: '#000' }}>
                  Atendimento ao Publico:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.AtendimentoPublico}
                  </Typography.Text>
                </Typography.Text>

                <br />
              </Card>
            </Col>
          </Row>

          <Card title="Endereço" style={{ margin: 20 }}>
            <Row>
              <Col>
                <Typography.Text style={{ color: '#000' }}>
                  Endereço:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Logradouro}
                  </Typography.Text>{' '}
                </Typography.Text>
                <Typography.Text style={{ color: '#000' }}>
                  Nº:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Numero}
                  </Typography.Text>
                </Typography.Text>

                <br />

                <Typography.Text style={{ color: '#000' }}>
                  Bairro:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Bairro}
                  </Typography.Text>
                </Typography.Text>

                <br />
                <Typography.Text style={{ color: '#000' }}>
                  Cidade:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.Cidade}
                  </Typography.Text>
                </Typography.Text>

                <br />
                <Typography.Text style={{ color: '#000' }}>
                  CEP:{' '}
                  <Typography.Text style={{ color: corTema }}>
                    {estruturaOrganizacionalDetalhe.CEP}
                  </Typography.Text>
                </Typography.Text>

                <br />
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '25px',
                }}
              >
                <MapButton
                  rua={estruturaOrganizacionalDetalhe.Logradouro}
                  numero={estruturaOrganizacionalDetalhe.Numero}
                  bairro={estruturaOrganizacionalDetalhe.Bairro}
                  cidade={estruturaOrganizacionalDetalhe.Cidade}
                />
              </Col>
            </Row>
          </Card>

          <Card title="O que fazemos" style={{ margin: 20 }}>
            <Typography.Text style={{ color: '#000' }}>
              <Content
                dangerouslySetInnerHTML={{
                  __html: estruturaOrganizacionalDetalhe.TextoInformativo,
                }}
              />
            </Typography.Text>
          </Card>
          {estruturaOrganizacionalDetalhe.NomeArquivo &&
            estruturaOrganizacionalDetalhe.UrlArquivo && (
              <Card
                title="Arquivo Anexo"
                style={{
                  margin: 20,
                }}
              >
                <Tooltip title="Baixar Arquivo" placement="topRight">
                  <Row
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() =>
                      fileManagerPaiDownload(
                        estruturaOrganizacionalDetalhe.UrlArquivo
                      )
                    }
                  >
                    <Col style={{ marginRight: 8 }}>
                      <Typography.Text style={{ cursor: 'pointer' }}>
                        {iconEnum
                          .find(
                            x =>
                              x.name ===
                              `file-${ChooseIconFile(
                                estruturaOrganizacionalDetalhe?.NomeArquivo?.split(
                                  '.',
                                  2
                                )[1].toLowerCase()
                              )}`
                          )
                          ?.value({ fontSize: '20px' })}
                      </Typography.Text>
                    </Col>
                    <Col lg={22}>
                      <Typography.Link>
                        {estruturaOrganizacionalDetalhe.NomeArquivo}
                      </Typography.Link>
                    </Col>
                    <Col lg={1}>
                      <Button
                        shape="circle"
                        style={{ float: 'right', backgroundColor: corTema }}
                        icon={
                          <Icons.DownloadOutlined style={{ color: '#FFF' }} />
                        }
                      />
                    </Col>
                  </Row>
                </Tooltip>
              </Card>
            )}
          <Row>
            <ButtonGoBack MarginLeft="22px" OnClick={goBack} />
          </Row>
        </>
      )}
    </>
  );
}

export default EstruturaOrganizacionalDetalhe;
