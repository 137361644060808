import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './Content.styled';

function Content({ children }) {
  const location = useLocation().pathname;

  const bgImage = useSelector(state =>
    location === '/'
      ? state.configuration.bgImageHome
      : state.configuration.bgImageTelas
  );

  return bgImage ? (
    <S.Content src={bgImage}>{children}</S.Content>
  ) : (
    <S.Content>{children}</S.Content>
  );
}

export default Content;
