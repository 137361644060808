import produce from 'immer';

const INITIAL_STATE = {
  count: 0,
};

function loaderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@loader/ADD_REQUEST':
      return produce(state, draft => {
        draft.count = state.count + 1;
      });

    case '@loader/REMOVE_REQUEST':
      return produce(state, draft => {
        draft.count = state.count - 1;
      });

    default:
      return state;
  }
}

export default loaderReducer;
