import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  PageTitle,
  Divider,
  List,
  Card,
  Col,
  Row,
  ButtonGoBack,
} from '~/components';
import { useMenus } from '~/contexts/menu.context';
import * as ModuloService from '../../../services/modulos.service';
import { CHAVES_MODULO } from '../../Helper/chave-modulo-constants';

function Sumario() {
  const history = useHistory();
  const { menus } = useMenus();
  const corTema = useSelector(state => state.configuration.corTema);

  function handleRedirect(externo, uri) {
    if (externo) {
      window.open(uri);
    } else {
      history.push(uri);
    }
  }

  function goBack() {
    history.push('/');
  }

  useEffect(() => {
    ModuloService.verificaModuloHabilitado(CHAVES_MODULO.SUMARIO);
  }, []);

  return (
    <>
      <PageTitle titulo="Sumário" />
      <Col>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={menus}
            renderItem={item => (
              <List.Item style={{ display: 'list-item', borderBottom: '0px' }}>
                <List.Item.Meta
                  title={
                    <a
                      onClick={() => {
                        handleRedirect(item.Externo, item.URI);
                      }}
                    >
                      {item.Titulo}
                    </a>
                  }
                />
                <Divider
                  style={{
                    margin: '0px',
                    color: corTema,
                    borderTop: '1px solid',
                    width: '95%',
                  }}
                />

                {item.SubMenu && (
                  <ul
                    style={{ listStyleType: 'none', paddingInlineStart: '0px' }}
                  >
                    {item.SubMenu.map(sub => (
                      <li style={{ color: corTema }}>
                        <a
                          style={{ color: corTema }}
                          onClick={() => {
                            handleRedirect(sub.Externo, sub.URI);
                          }}
                        >
                          {sub.Titulo}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default Sumario;
