import React from 'react';

import { Button, Tooltip, Icons } from '~/components';

const ButtonLinkAnexo = ({ onClick }) => (
  <Tooltip title="Acessar">
    <Button
      shape="circle"
      onClick={onClick}
      type="primary"
      icon={<Icons.LinkOutlined />}
      style={{ margin: '9px' }}
    />
  </Tooltip>
);

export default ButtonLinkAnexo;
