import styled from 'styled-components';

export const Content = styled.div`
  background: white;
  font-size: ${({ theme }) => `${16 + 2 * theme.zoomFonte}px`};
  padding: 14px;
  margin-top: 10px;
`;

export const ContainerExportar = styled.div`
  display: inline-flex;
  width: 120px;
  margin-bottom: 10px;
  margin-left: 5px;
  position: relative;
  top: -1px;
`;
