import styled from 'styled-components';

export const ContainerInformacao = styled.div`
  div.ant-typography {
    margin-bottom: 0px;
  }

  div:nth-child(2) div {
    padding: 0px;
  }
`;
