import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function ReCaptcha({ handleOnCaptchaChange }) {
  return (
    <ReCAPTCHA
      sitekey="6LeSHQETAAAAACjgu00LxcbscsmkkwMIqhfd1-Gh"
      onChange={handleOnCaptchaChange}
    />
  );
}
export default ReCaptcha;