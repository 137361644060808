import React from 'react';
import { notification } from 'antd';

const PreserveBreakLine = ({ message }) => (
  <div style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
);

function showSuccess(successMessage) {
  notification.success({
    message: 'Sucesso',
    description: <PreserveBreakLine message={successMessage} />,
  });
}

function showWarning(warningMessage) {
  notification.warning({
    message: 'Alerta',
    description: <PreserveBreakLine message={warningMessage} />,
  });
}

function showError(errorMessage) {
  notification.error({
    message: 'Erro',
    description: <PreserveBreakLine message={errorMessage} />,
  });
}

export { showSuccess, showWarning, showError };
