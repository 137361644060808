import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.9);
`;

export const IconSection = styled.div`
  margin-right: 5px;
`;

export const Content = styled.div`
  padding: 10px;
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
`;
