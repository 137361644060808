import React from 'react';
import MailGo from 'mailgo';
// eslint-disable-next-line import/no-cycle
import * as S from './Socials.styled';
// eslint-disable-next-line import/no-cycle
import { Tooltip } from '../index';
import { SocialsList } from './SocialsList';

export default function Socials() {
  MailGo();
  return (
    <S.SocialsContainer>
      {SocialsList &&
        SocialsList().map(
          item =>
            item.link && (
              <Tooltip title={item.ToolTip} placement="leftTop">
                <S.ButtonStyled
                  href={item?.mail ? item.link : null}
                  onClick={
                    !item.mail ? () => window.open(item.link, '_blank') : null
                  }
                  background={item?.background}
                  type="primary"
                  color="#fff"
                  icon={item?.icon}
                />
              </Tooltip>
            )
        )}
    </S.SocialsContainer>
  );
}
