import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

function persist(reducers) {
  const persistedReducers = persistReducer(
    {
      key: 'smarapd-pai-portal',
      storage,
      whitelist: ['configuration'],
    },
    reducers
  );

  return persistedReducers;
}

export default persist;
