import { Tooltip } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as S from './ImageButton.styled';

function ImageButton({ type, shape, imagem, uri, alt, tooltip }) {
  function onClick() {
    if (uri) {
      const redirect = !uri.match(/http(s)*:\/\//g) ? `http://${uri}` : uri;
      window.open(redirect);
    }
  }

  return (
    <Tooltip title={tooltip}>
      <S.ImageButton
        type={type || 'primary'}
        shape={shape}
        onKeyDown={event => {
          if (event.keyCode === 13) onClick();
        }}
        onClick={onClick}
        tabIndex={0}
      >
        <S.Image src={`data:image/png;base64, ${imagem}`} alt={alt}></S.Image>
      </S.ImageButton>
    </Tooltip>
  );
}

export default withRouter(ImageButton);
