import React, { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import { TileLayer, Marker, Popup } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import Geocode from 'react-geocode';
import * as S from './Map.styled';
import { geocodeKey } from '~/utils/keys';

Geocode.setApiKey(geocodeKey);
Geocode.setLanguage('cs');
Geocode.setRegion('br');

function MapContainer({ rua, numero, bairro, cidade, corFonte }) {
  const discardCompleFromRua = rua?.split('-')[0];
  let query = `${discardCompleFromRua || ' '} ${numero || ''} ${bairro ||
    ''} ${cidade || ''}`;
  query = query.replace(/\s/g, '+');

  function redirect() {
    window.open(`https://www.google.com/maps/search/?api=1&query=${query}`);
  }

  const [data, setData] = useState(false);

  useEffect(() => {
    Geocode.fromAddress(
      `${discardCompleFromRua || ' '} ${bairro || ' '} ${cidade || ' '}`
    ).then(
      response => {
        setData(response.results[0].geometry.location);
      },
      error => {
        setData(null);
      }
    );
  }, []);

  const loadMap = () => {
    if (data) {
      return (
        <S.Map center={data} zoom={17}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={data}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [13, 40],
              })
            }
          >
            <S.PopupStyles />
            <Popup>
              <S.PopupLink onClick={redirect} style={{ color: corFonte }}>
                {rua}
              </S.PopupLink>
            </Popup>
          </Marker>
        </S.Map>
      );
    }

    if (data === null) {
      return <S.MapTextCard>Localização não encontrada.</S.MapTextCard>;
    }

    do {
      return <S.MapTextCard>Carregando</S.MapTextCard>;
    } while (!data);
  };

  return <>{loadMap()}</>;
}

export default MapContainer;
