export const TIPO_COLUNA = {
  TEXTO_50: 1,
  TEXTO_100: 2,
  INTEIRO: 3,
  DECIMAL: 4,
  DATA: 5,
  LINK: 6,
  BOOLEANO: 7,
  TEXTO: 8,
};
