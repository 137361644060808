import api from '~/services/api';

export async function obterConfiguracoes(modulo, visao) {
  return api.get(`modulovisao/${modulo}/${visao}/configuracao`);
}

export async function obterConfiguracoesGerais(modulo) {
  return api.get(`modulo/${modulo}/configuracoes`);
}

export async function filtrar(data) {
  return api.post('modulovisao/filter', data);
}

export async function exportarVisao(tipoExportacao, data) {
  return api.post(`modulovisao/exportacaogrid/${tipoExportacao}`, data);
}

export async function obterTotalizadores(data) {
  return api.post(`modulovisao/colunasTotalizadoras`, data);
}
