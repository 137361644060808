import styled from 'styled-components';
import { Tree } from '~/components';

export const TreeTransparente = styled(Tree)`
  &&.draggable-tree {
    background: transparent !important;
  }

  .ant-tree-list-scrollbar-show {
    display: initial !important;
  }
`;

export const LinkArquivo = styled.a`
  font-style: italic;
  margin-left: 5px;
`;
