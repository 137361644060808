import React from 'react';
import * as S from './BackTop.styled';

function BackTopPage({ icon, backgroundColor, color }) {
  return (
    <S.BackTop>
      <S.BackTopStyle backgroundColor={backgroundColor} color={color}>
        {icon}
      </S.BackTopStyle>
    </S.BackTop>
  );
}

export default BackTopPage;

BackTopPage.defaultProps = {
  icon: 'UP',
  backgroundColor: '#009688',
  color: '#fff',
};
