import React from 'react';
import { Row, Col, Card, Typography } from '~/components';
import { ButtonArquivoDigital } from './style';

import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import ButtonDownloadFileManager from '~/modules/Modulos/components/Grid/arquivos/ButtonDowloadFileManager';
import ButtonLinkAnexo from '~/modules/Modulos/components/Grid/arquivos/ButtonLinkAnexo';

function listaAnexos({ anexos }) {
  return (
    <Row style={{ marginBottom: '25px' }}>
      <Col lg={24}>
        <Card
          title="Anexos"
          extra={
            <Typography.Text style={{ fontWeight: 500 }}>Ações</Typography.Text>
          }
        >
          {anexos.length > 0
            ? anexos.map(arquivo => (
                <Row
                  key={arquivo.IdArquivoDigital}
                  style={{ marginBottom: '10px' }}
                >
                  <Col lg={23} style={{ textAlign: 'left' }}>
                    <ButtonArquivoDigital
                      onClick={() => {
                        if (arquivo?.IsLinkAnexo) {
                          window.open(arquivo.LinkAnexo, '_blank');
                        } else {
                          fileManagerPaiDownload(arquivo.UrlArquivoDigital);
                        }
                      }}
                      type="link"
                      block
                    >
                      {arquivo.NomeArquivoDigital}
                    </ButtonArquivoDigital>
                  </Col>
                  <Col lg={1} style={{ textAlign: 'right', margin: 'auto' }}>
                    {arquivo?.IsLinkAnexo ? (
                      <ButtonLinkAnexo
                        key={arquivo.IdArquivoDigital}
                        onClick={() => {
                          window.open(arquivo.LinkAnexo, '_blank');
                        }}
                      />
                    ) : (
                      <ButtonDownloadFileManager
                        key={arquivo.IdArquivoDigital}
                        onClick={() => {
                          fileManagerPaiDownload(arquivo.UrlArquivoDigital);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ))
            : 'Não há anexos disponíveis.'}
        </Card>
      </Col>
    </Row>
  );
}

export default listaAnexos;
