import { useEffect, useState } from 'react';
import { useMenus } from '~/contexts/menu.context';
import {
  breadcrumbEstruturaOrganizacional,
  breadcrumbEsic,
  breadcrumbFaq,
  breadcrumbDadosAbertos,
  breadcrumbGlossario,
  breadcrumbHome,
  breadcrumbSumario,
  breadcrumbFaleConosco,
  breadcrumbEstatisticas,
  breadcrumbAutenticidade,
} from '~/utils/breadcrumb';

export function useBreadcrumb() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { menus, menuSelecionado } = useMenus();

  function obterBreadcrumbModulos() {
    const newBreadcrumb = [];
    menus.forEach(menuModulo => {
      if (menuModulo?.SubMenu?.length) {
        menuModulo.SubMenu.forEach(item => {
          if (!item.Externo) {
            const ehDinamico = item.URI.includes('/dinamico');

            newBreadcrumb.push({
              chaveModulo: menuModulo.ChaveModulo,
              path: item.URI,
              breadcrumb: [{ name: menuModulo.Titulo }, { name: item.Titulo }],
            });

            newBreadcrumb.push({
              chaveModulo: menuModulo.ChaveModulo,
              path: ehDinamico
                ? `${item.URI}/detalhevisao`
                : `${item.URI}/:itemVisao`,
              breadcrumb: [
                { name: menuModulo.Titulo },
                { name: item.Titulo, path: item.URI },
                {
                  name: ehDinamico ? 'Detalhe Visão Analítica' : 'Item',
                },
              ],
            });

            newBreadcrumb.push({
              chaveModulo: menuModulo.ChaveModulo,
              path: `${item.URI}/busca`,
              breadcrumb: [
                { name: menuModulo.Titulo },
                { name: item.Titulo, path: item.URI },
                {
                  name: 'Busca Avançada',
                },
              ],
            });
          }
        });
      }
    });
    return newBreadcrumb;
  }

  useEffect(() => {
    const breadcrumbModulos = obterBreadcrumbModulos();

    const newBreadcrumb = [
      ...breadcrumbModulos,
      ...breadcrumbEstruturaOrganizacional,
      ...breadcrumbFaq,
      ...breadcrumbDadosAbertos,
      ...breadcrumbEsic,
      ...breadcrumbSumario,
      ...breadcrumbGlossario,
      ...breadcrumbHome,
      ...breadcrumbFaleConosco,
      ...breadcrumbEstatisticas,
      ...breadcrumbAutenticidade,
    ];

    setBreadcrumbs(newBreadcrumb);
  }, [menus]);

  function obterBreadcrumbPorUri(uri, params) {
    const isModuloDinamico = uri.includes('/dinamico/');
    const isModulofixo = uri.includes('/fixo/');

    let newUri = uri;

    if (isModuloDinamico || isModulofixo) {
      const { modulo, visao } = params;

      newUri = newUri.replace(':modulo', modulo).replace(':visao', visao);

      if (breadcrumbs?.length) {
        const breadcrumbPaginaAtual = breadcrumbs.find(x => {
          if (menuSelecionado) {
            return (
              x.path === newUri && x.chaveModulo === menuSelecionado.ChaveModulo
            );
          }
          return x.path === newUri && x.path.includes(x.chaveModulo);
        });

        if (breadcrumbPaginaAtual) return breadcrumbPaginaAtual.breadcrumb;
      }
    }

    if (breadcrumbs?.length) {
      const breadcrumbPaginaAtual = breadcrumbs.find(x => x.path === newUri);
      return breadcrumbPaginaAtual?.breadcrumb;
    }

    return undefined;
  }

  return { breadcrumbs, obterBreadcrumbPorUri };
}
