import React, { useState, useEffect } from 'react';

import { Modal, FormV3 as Form, Row, Col, Table } from '~/components';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';

import { obterAnexos } from '~/modules/Modulos/services/arquivos.service';
import ButtonLinkFileManagerLinkAnexo from './ButtonLinkFileManager';
import ButtonDownloadFileManager from './ButtonDowloadFileManager';
import ButtonLinkAnexo from './ButtonLinkAnexo';

function ArquivosForm({
  visible,
  onCancel,
  chaveModulo,
  filtroPesquisaArquivosDigitais,
}) {
  const [arquivosAnexados, setArquivosAnexados] = useState([]);
  const titulo = 'Listagem dos Anexos';

  useEffect(() => {
    async function buscarAnexos() {
      if (filtroPesquisaArquivosDigitais) {
        const response = await obterAnexos(filtroPesquisaArquivosDigitais);
        setArquivosAnexados(response);
      }
    }
    if (visible) buscarAnexos();
  }, [chaveModulo, filtroPesquisaArquivosDigitais, visible]);

  function onCloseModal() {
    onCancel();
  }

  function showAnexos() {
    const columns = [
      {
        title: 'Anexos',
        dataIndex: 'NomeArquivoDigital',
        key: 'NomeArquivoDigital',
        render: (text, record) => {
          return {
            children: (
              <ButtonLinkFileManagerLinkAnexo
                key={record.IdArquivoDigital}
                onClick={() => {
                  if (record?.IsLinkAnexo) {
                    window.open(record.LinkAnexo, '_blank');
                  } else {
                    fileManagerPaiDownload(record.UrlArquivoDigital);
                  }
                }}
              >
                {text}
              </ButtonLinkFileManagerLinkAnexo>
            ),
            props: {
              'data-title': 'Anexos',
            },
          };
        },
      },
      {
        title: 'Ações',
        dataIndex: 'Acao',
        align: 'center',
        key: 'Acao',
        width: 100,
        render: (text, record) => {
          return {
            children: record?.IsLinkAnexo ? (
              <ButtonLinkAnexo
                key={record.IdArquivoDigital}
                onClick={() => {
                  window.open(record.LinkAnexo, '_blank');
                }}
              />
            ) : (
              <ButtonDownloadFileManager
                key={record.IdArquivoDigital}
                onClick={() => {
                  fileManagerPaiDownload(record.UrlArquivoDigital);
                }}
              />
            ),
            props: {
              'data-title': 'Ações',
            },
          };
        },
      },
    ];

    return (
      <Table
        bordered
        dataSource={arquivosAnexados}
        rowKey={row => row.IdArquivoDigital || row.NomeArquivoDigital}
        pagination={false}
        scroll={{ x: 650 }}
        columns={columns}
      />
    );
  }

  return (
    <Modal title={titulo} width={750} visible={visible} onCancel={onCloseModal}>
      <Form>
        <Row gutter={8}>
          <Col span={24}>{showAnexos()}</Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ArquivosForm;
