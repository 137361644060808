export const ChooseIconFile = fileExtension => {
  let fileIcon = 'pdf';
  if (fileExtension) {
    switch (fileExtension) {
      case 'pdf':
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
        fileIcon = 'img';
        break;
      case 'csv':
      case 'xls':
      case 'xlsx':
        fileIcon = 'excel';
        break;
      default:
        fileIcon = 'text';
    }
  }
  return fileIcon;
};
