import { useState, useEffect } from 'react';

export const tiposDevice = {
  mobile: 'Mobile',
  desktop: 'Desktop',
};

export function useWindowListener() {
  const isClient = typeof window === 'object';
  const [widthWindow, setWidthWindow] = useState(getSize());

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
      device:
        isClient && window.innerWidth && window.innerWidth < 990
          ? tiposDevice.mobile
          : tiposDevice.desktop,
    };
  }

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      const size = getSize();
      setWidthWindow(size);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient, widthWindow]);

  return widthWindow;
}
