import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

function Loader({ children }) {
  const count = useSelector(state => state.loader.count);

  return (
    <Spin size="large" tip="Carregando..." spinning={count > 0} delay={500}>
      {children}
    </Spin>
  );
}

export default Loader;
