import { createGlobalStyle } from 'styled-components';

const highContrastColors = {
  background: 'black',
  color: 'yellow',
};

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Segoe UI' !important;

    ::placeholder {
      color: ${props =>
        props.theme.highContrast && highContrastColors.color} !important;  
    }

    ::selection {
      background-color: ${props =>
        props.theme.highContrast && highContrastColors.color} !important;
    }

    background-color: ${props =>
      props.theme.highContrast && highContrastColors.background} !important;

    border-color: ${props =>
      props.theme.highContrast && highContrastColors.color} !important;

    color: ${props =>
      props.theme.highContrast && highContrastColors.color} !important;
  }
  
  .ant-table-footer{
    padding: 14px 14px;
  }

  &&.access-button{
    width: 23.5% !important
  }

  .maxHeight500 {
    max-height: 500px;
  }
`;

export default GlobalStyle;
