import React, { useEffect } from 'react';
import * as ModuloService from '../../../services/modulos.service';
import { CHAVES_MODULO } from '../../Helper/chave-modulo-constants';
import AutenticidadeForm from '../components/autenticidade.form.component';

function Autenticidade() {
  useEffect(() => {
    ModuloService.verificaModuloHabilitado(CHAVES_MODULO.AUTENTICIDADE);
  }, []);

  return <AutenticidadeForm />;
}

export default Autenticidade;
