import React from 'react';
import { Accordion } from '~/components';
import { Content } from './style';

const { Panel } = Accordion;

function FaqItem({ pergunta, resposta }) {
  return (
    <>
      <Accordion
        accordion
        style={{
          marginBottom: 5,
          background: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <Panel forceRender header={pergunta} key="1">
          <Content dangerouslySetInnerHTML={{ __html: resposta }} />
        </Panel>
      </Accordion>
    </>
  );
}

export default FaqItem;
