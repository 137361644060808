import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { Typography } from '../Typography/Typography.styled';
import MapButton from '~/components/MapButton/MapButton.component';
import * as S from './Footer.styled';

function Footer() {
  const configuration = useSelector(state => state.configuration);
  const {
    corFonteRodape,
    endereco,
    bairro,
    cidade,
    cep,
    telefone,
    atendimento,
  } = configuration;

  return (
    <S.Footer>
      <Row style={{ justifyContent: 'Center' }}>
        <Col>
          <Typography.Paragraph style={{ color: corFonteRodape }}>
            {endereco} - {bairro} - {cidade} - {cep}
          </Typography.Paragraph>

          <Typography.Paragraph
            style={{ marginBottom: 0, color: corFonteRodape }}
          >
            {telefone} - {atendimento}
          </Typography.Paragraph>
        </Col>
        <S.MapButtonWrapper
          lg={2}
          xs={3}
          style={{
            display: 'Flex',
            justifyContent: 'Center',
            alignItems: 'Center',
          }}
        >
          <MapButton rua={endereco} bairro={bairro} cidade={cidade} />
        </S.MapButtonWrapper>
      </Row>
    </S.Footer>
  );
}

export default Footer;
