import styled from 'styled-components';
import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  && {
    display: inline;
    font-size: ${({ theme }) => `${22 + 2 * theme.zoomFonte}px`};
    color: ${({ color }) => color};
  }
`;
