import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { Icons } from '~/components';
import { Visualizar, CardWrapper } from './style';
import ExercicioSelect from './ExercicioSelect.component';

function EmptyCardGrafico({
  id,
  titulo,
  valorLink,
  tamanho,
  corFonte,
  showEyeButton = false,
  showExercicioSelect,
}) {
  const { zoomFonte } = useSelector(state => state.configuration);

  useEffect(() => {}, [zoomFonte]);
  return (
    <CardWrapper
      title={
        <span style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}>
          {titulo}
        </span>
      }
      extra={
        <>
          {showExercicioSelect && <ExercicioSelect id={id} />}
          {showEyeButton && (
            <Visualizar>
              <Link to={valorLink} style={{ color: corFonte }}>
                <Icons.EyeOutlined />
              </Link>
            </Visualizar>
          )}
        </>
      }
      size="small"
      style={{
        height: '100%',
        fontSize: '25px',
      }}
    >
      <Empty
        description="Nenhum registro encontrado"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ margin: 0, color: '#c9c9c9' }}
        imageStyle={{
          maxHeight: tamanho > 1 ? 'auto' : '3rem',
          marginTop: tamanho > 1 ? '4rem' : 0,
        }}
      />
    </CardWrapper>
  );
}

export default EmptyCardGrafico;
