import React, { useEffect, useState } from 'react';
import EmptyCardGrafico from '../../../components/Grafico/EmptyCardGraphic.component';
import LineGraphic from '../../../components/Grafico/LineGraphic.component';
import * as EstatisticasService from '../services/Estatisticas.service';

export default function GraficoAnos() {
  const [acessosAnos, setAcessosAnos] = useState([]);

  useEffect(() => {
    EstatisticasService.getEstatisticasAcessoAno().then(({ data }) => {
      if (data) setAcessosAnos(data);
    });
  }, []);

  return acessosAnos?.length ? (
    <div className="maxHeight500">
      <LineGraphic
        id="acessosAnos"
        titulo="Histórico de Acessos por Mês"
        data={acessosAnos}
        xField="Mes"
        yField="Acessos"
        seriesField="Ano"
        legendaEixoX="Mês"
        legendaEixoY="Acessos"
        legenda=""
      />
    </div>
  ) : (
    <EmptyCardGrafico
      id="acessosAnos"
      titulo="Histórico de Acessos por Mês"
      tamanho={2}
    />
  );
}
