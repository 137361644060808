import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './Header.styled';

function Header({ children }) {
  const banner = useSelector(state => state.configuration.banner);

  return <S.Header src={banner}>{children}</S.Header>;
}

export default Header;
