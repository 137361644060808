import styled from 'styled-components';

export const Title = styled.div`
  margin: 0px 20px 0px 20px;
`;

export const Content = styled.div`
  margin: 15px;
  margin-right: 20px;
  margin-left: 20px;
`;

export const Section = styled.div`
  margin-bottom: 15px;
`;
