import React, { useEffect, useState, useContext } from 'react';
import { Column } from '@antv/g2plot';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Icons, Typography } from '~/components';
import { GraficoContext } from '~/modules/ESic/context/listaGraficoContext';
import { GraficoColumn, Visualizar, CardWrapper } from './style';
import Filter from './Filter.component';
import ExercicioSelect from './ExercicioSelect.component';

const NumberFormatter = function NumberFormatter(value) {
  let newNum = value;
  const suffix = ['', ' Mil', ' Mi', ' Bi', ' Tri'];
  let suffixNum = 0;
  if (newNum > 10000) {
    while (newNum >= 1000) {
      newNum /= 1000;
      suffixNum += 1;
    }
    return newNum + suffix[suffixNum];
  }
  return newNum;
};

function ColumnGraphic({
  id,
  titulo,
  cor,
  data,
  legendaEixoX,
  legendaEixoY,
  ValorLink,
  hasFilter,
  filterDescription,
  contentFilter,
  showEyeButton = false,
  corFonte,
  showExercicioSelect,
  // onFilter,
}) {
  const { eSic, setESic } = useContext(GraficoContext);
  const [column, setColumn] = useState(null);
  const {
    graficHighContrastStyle,
    highContrast,
    graficDefaultStyle,
    zoomFonte,
  } = useSelector(state => state.configuration);
  const getConfigStyle = highContrast
    ? graficHighContrastStyle
    : graficDefaultStyle;

  const [config] = useState({
    padding: [20, 20, 100, 70],
    title: {
      visible: false,
      text: legendaEixoX,
      alignTo: 'middle',
      style: getConfigStyle,
    },
    showTitle: false,
    autoFit: true,
    color: cor,
    data,
    xField: 'EixoX',
    yField: 'EixoY',
    xAxis: {
      visible: true,
      title: {
        visible: legendaEixoX || false,
        text: legendaEixoX || '',
        style: getConfigStyle,
      },
      label: {
        formatter: v => {
          return v.trim().length >= 7 ? `${v.substring(0, 7)}...` : v.trim();
        },
        rotate: -20,
        offsetX: -30,
        offsetY: -6,
        style: getConfigStyle,
      },
    },
    yAxis: {
      visible: true,
      title: {
        visible: legendaEixoY || false,
        text: legendaEixoY || '',
        offset: 60,
        style: getConfigStyle,
      },
      label: {
        formatter: v => {
          return NumberFormatter(v);
        },
        style: getConfigStyle,
      },
    },
    tooltip: {
      showTitle: true,
      title: legendaEixoY,
      formatter: ({ EixoX, EixoY }) => {
        return {
          name: EixoX,
          value: new Intl.NumberFormat('pt-BR').format(EixoY),
        };
      },
    },
  });

  useEffect(() => {
    if (column) return;
    const element = document.getElementById(id);

    if (element) {
      const columnRender = new Column(element, { data, ...config });
      setColumn(columnRender);
      columnRender.render();
    }
  }, [column, config, data, id]);

  useEffect(() => {
    if (column) {
      column.update({
        title: {
          ...config.title,
          style: getConfigStyle,
        },
        xAxis: {
          ...config.xAxis,

          title: {
            ...config.xAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.xAxis.label,
            style: getConfigStyle,
          },
        },
        yAxis: {
          ...config.yAxis,
          title: {
            ...config.yAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.yAxis.label,
            style: getConfigStyle,
          },
        },
      });

      column.render();
    }
  }, [
    highContrast,
    config.text,
    config.label,
    getConfigStyle,
    column,
    config.title,
    config.xAxis,
    config.yAxis,
    zoomFonte,
  ]);

  useEffect(() => {
    if (column) {
      column.update({
        data,
      });
      column.render();
    }
  }, [data, column]);

  return (
    <>
      <CardWrapper
        title={
          <span
            style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
          >
            {titulo}
          </span>
        }
        extra={
          <>
            {showExercicioSelect && <ExercicioSelect id={id} />}
            {showEyeButton && (
              <Visualizar>
                <Link to={ValorLink} style={{ color: corFonte }}>
                  <Icons.EyeOutlined />
                </Link>
              </Visualizar>
            )}
          </>
        }
        size="small"
      >
        <Row>
          {hasFilter && (
            <Col flex="45px" style={{ display: 'flex' }}>
              <Filter
                idGrafico={id}
                contentFilter={contentFilter}
                // onFilter={onFilter}
                eSic={eSic}
                setESic={setESic}
              />
            </Col>
          )}
          {filterDescription && (
            <Col flex="auto">
              <Typography.Text
                style={{
                  position: 'relative',
                  top: -6,
                }}
              >
                {filterDescription}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Row>
          <GraficoColumn id={id} />
        </Row>
      </CardWrapper>
    </>
  );
}

export default ColumnGraphic;
