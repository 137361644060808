import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEstruturaOrganizacionalConfiguracaoService } from '../services/estruturaOrganizacional.service';
import TreeViewEstruturaOrganizacional from '../components/treeViewEstruturaOrganizacional.component';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';

import { Typography, Tooltip, Row, Col, Card, PageTitle, ButtonGoBack } from '~/components';

function EstruturaOrganizacional({ history }) {
  const { corTema } = useSelector(state => state.configuration);

  const [
    estruturaOrganizacionalConfiguracao,
    setEstruturaOrganizacionalConfiguracao,
  ] = useState(null);

  useEffect(() => {
    async function getEstruturaOrganizacionalConfiguracao() {
      const response = await getEstruturaOrganizacionalConfiguracaoService();
      if (response.data) setEstruturaOrganizacionalConfiguracao(response.data);
    }
    getEstruturaOrganizacionalConfiguracao();
  }, []);

  function goBack() {
    history.push('/');
  }

  return (
    <>
      {estruturaOrganizacionalConfiguracao && (
        <>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col md={24}>
              <PageTitle titulo="Estrutura Organizacional" />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            {estruturaOrganizacionalConfiguracao.ExibicaoLegislacao &&
              estruturaOrganizacionalConfiguracao.UrlLegislacao && (
                <Col
                  md={
                    estruturaOrganizacionalConfiguracao.ExibicaoLegislacao &&
                      estruturaOrganizacionalConfiguracao.UrlLegislacao
                      ? 12
                      : 24
                  }
                  xs={24}
                >
                  <Card
                    title={<Typography.Text>Legislação</Typography.Text>}
                    size="small"
                  >
                    <Tooltip title="Acesso">
                      <Typography.Text style={{ paddingLeft: 4 }}>
                        <span
                          style={{
                            color: corTema,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            fileManagerPaiDownload(
                              estruturaOrganizacionalConfiguracao.UrlLegislacao
                            );
                          }}
                          role="link"
                          tabIndex="0"
                          aria-hidden="true"
                        >
                          {
                            estruturaOrganizacionalConfiguracao.ExibicaoLegislacao
                          }
                        </span>
                      </Typography.Text>
                    </Tooltip>
                  </Card>
                </Col>
              )}

            {estruturaOrganizacionalConfiguracao.ExibicaoFluxograma &&
              estruturaOrganizacionalConfiguracao.UrlFluxograma && (
                <Col
                  md={
                    estruturaOrganizacionalConfiguracao.ExibicaoLegislacao &&
                      estruturaOrganizacionalConfiguracao.UrlLegislacao
                      ? 12
                      : 24
                  }
                  xs={24}
                >
                  <Card
                    title={<Typography.Text>Fluxograma</Typography.Text>}
                    size="small"
                  >
                    <Tooltip title="Acesso">
                      <Typography.Text style={{ paddingLeft: 4 }}>
                        <span
                          style={{
                            color: corTema,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            fileManagerPaiDownload(
                              estruturaOrganizacionalConfiguracao.UrlFluxograma
                            );
                          }}
                          role="link"
                          tabIndex="0"
                          aria-hidden="true"
                        >
                          {
                            estruturaOrganizacionalConfiguracao.ExibicaoFluxograma
                          }
                        </span>
                      </Typography.Text>
                    </Tooltip>
                  </Card>
                </Col>
              )}
          </Row>

          <Row gutter={16}>
            <Col xs={24}>
              <Card
                title={
                  <Typography.Text>Estrutura Organizacional</Typography.Text>
                }
                size="small"
              >
                <TreeViewEstruturaOrganizacional
                  treeView={
                    estruturaOrganizacionalConfiguracao.ListaEstruturaOrganizacional
                  }
                  history={history}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <ButtonGoBack
              OnClick={goBack}
            />
          </Row>
        </>
      )}
    </>
  );
}

export default EstruturaOrganizacional;
