import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './Sider.styled';

function Sider({ collapsed, children }) {
  const corTema = useSelector(state => state.configuration.corTema);

  return (
    <S.Sider trigger={null} width={256} collapsed={collapsed} collapsible>
      {children}
    </S.Sider>
  );
}

export default Sider;
