import React, { useState, useEffect } from 'react';
import { Divider, Typography, PageTitle, Card } from '~/components';
import GlossarioButtons from '../componentes/glossario-buttons.component';
import { getGlossario } from '../services/glossario.service';
import { Container, ItemGlossarioArea } from './style';

function Glossario() {
  const [letrasAtivas, setLetrasAtivas] = useState([]);
  const [glossario, setGlossario] = useState([]);

  async function obterGlossario() {
    const response = await getGlossario();
    if (response?.data?.Itens?.length) setGlossario(response.data.Itens);
  }

  useEffect(() => {
    obterGlossario();
  }, []);

  useEffect(() => {
    const letras = glossario.map(item => item.Letra);

    setLetrasAtivas(letras);
  }, [glossario]);

  return (
    <>
      <PageTitle titulo="Glossário" />
      <Container>
        <GlossarioButtons letrasAtivas={letrasAtivas} />
        <Card style={{ width: '100%' }} bodyStyle={{ paddingBottom: '6rem' }}>
          {Boolean(glossario?.length) &&
            glossario.map(({ Letra, PalavrasSignificados }) => (
              <ItemGlossarioArea key={Letra} id={Letra}>
                <Typography.Title style={{ marginBottom: 0 }}>
                  {Letra}
                </Typography.Title>

                <Divider style={{ margin: '.2em 0 1em 0' }} />

                {Boolean(PalavrasSignificados?.length) &&
                  PalavrasSignificados.map(({ Palavra, Significado }) => (
                    <Typography.Paragraph
                      key={Palavra}
                      style={{ marginBottom: '2em' }}
                    >
                      <strong>{Palavra}:</strong> {Significado}
                    </Typography.Paragraph>
                  ))}
              </ItemGlossarioArea>
            ))}
        </Card>
      </Container>
    </>
  );
}

export default Glossario;
