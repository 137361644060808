import React, { useContext } from 'react';
import { Select, Tooltip } from '~/components';
import { HomeGraficoContext } from '~/modules/Dashboard/context/listaHomeGraficoContext';
import { getHomeItemService } from '~/modules/Dashboard/services/dashboard.service';

function ExercicioSelect({ id }) {
  const { anoAtual, home, setHome, exercicio, setExercicio } = useContext(
    HomeGraficoContext
  );
  const exercicios = Array.from({ length: 5 }, (_, i) => anoAtual - i);

  async function onChange(ano) {
    if (ano) {
      setExercicio(prevState => ({ ...prevState, [id]: ano }));
      const response = await getHomeItemService(id, ano);
      if (response && response.data) {
        const updatedHome = home?.ListaHomeItem.map(item => {
          if (item.Id == response.data.Id) item = response.data;

          return item;
        });
        setHome({ ListaHomeItem: updatedHome });
      }
    }
  }

  return (
    <Tooltip title="Exercício">
      <Select
        value={exercicio[id]}
        defaultValue={anoAtual}
        onChange={ano => onChange(ano)}
        style={{ width: '80px', marginLeft: '5px' }}
      >
        {exercicios.map(ano => (
          <Select.Option key={ano} Option value={ano}>
            {ano}
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
}

export default ExercicioSelect;
