export const TAMANHO_HOME_ITEM_ENUM = {
  T_1x3: 1,
  T_2x6: 2,
  T_2x12: 3,
  T_2x9: 4,
  T_3x6: 5,
  T_3x9: 6,
  T_3x12: 7,
  T_4x12: 8,
  T_5x12: 9,
  T_6x12: 10,
  T_05x12: 11,
  T_1x12: 12,
};
