import React, { useState } from 'react';
import { Form } from 'antd';
import { filtrarGrafico } from '~/modules/ESic/services/esic.service';
import Popover from '~/components/Popover/Popover.component';
import Button from '~/components/Button/Button.component';
import Icons from '~/components/Icons/Icons.component';
import { FilterButton } from './style';

function Filter({ idGrafico, contentFilter, eSic, setESic }) {
  const [visible, setVisible] = useState(false);

  async function onFilter(Id, values) {
    const { Periodo } = values;
    const response = await filtrarGrafico({ Id, Periodo });
    const newLista = [...eSic.ListaGraficos];
    const item = {
      ...newLista[
        eSic.ListaGraficos.findIndex(obj => obj.Id === response.data.Id)
      ],
      Dados: response.data.Dados,
      DescricaoPeriodo: response.data.DescricaoPeriodo,
    };
    newLista[
      eSic.ListaGraficos.findIndex(obj => obj.Id === response.data.Id)
    ] = item;
    setESic({ ...eSic, ListaGraficos: newLista });
  }

  const content = (
    <>
      <Form
        name="filter"
        initialValues={{
          remember: true,
          Periodo: contentFilter.Periodicidade,
        }}
        onFinish={values => onFilter(idGrafico, values)}
      >
        {contentFilter.Content}
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => setVisible(false)}
        >
          Filtrar
        </Button>
      </Form>
    </>
  );

  const handleVisibleChange = visibleEvent => setVisible(visibleEvent);

  return (
    <>
      <Popover
        visible={visible}
        onVisibleChange={handleVisibleChange}
        content={content}
        trigger="click"
      >
        <FilterButton>
          <Icons.FilterOutlined
            style={{ position: 'relative', top: 1, left: -7, fontSize: 16 }}
          ></Icons.FilterOutlined>
        </FilterButton>
      </Popover>
    </>
  );
}

export default Filter;
