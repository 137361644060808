import React from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { Icons } from '../index';

export function SocialsList() {
  const {
    RazaoSocial,
    EmailSocials,
    FacebookSocials,
    InstagramSocials,
    LinkedInSocials,
    TwitterSocials,
    WhatsAppSocials,
    YoutubeSocials,
  } = useSelector(state => state?.configuration);

  function retornaWpp() {
    const { input } = WhatsAppSocials?.replace(/\D/g, '').match(
      /(\d{0,3})(\d{0,3})(\d{0,4})/
    );
    return input;
  }

  const ArraySocials = [
    {
      icon: (
        <Icons.TwitterOutlined style={{ fontSize: '20px', marginTop: '6px' }} />
      ),
      link: TwitterSocials || null,
      ToolTip: 'Twitter',
      background: '#1DA1F2',
    },
    {
      icon: (
        <Icons.FacebookFilled style={{ fontSize: '20px', marginTop: '6px' }} />
      ),
      link: FacebookSocials || null,
      ToolTip: 'Facebook',
      background: '#1877F2',
    },
    {
      icon: (
        <Icons.InstagramOutlined
          style={{ fontSize: '20px', marginTop: '5px' }}
        />
      ),
      link: InstagramSocials || null,
      ToolTip: 'Instagram',
      background:
        'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
    },
    {
      icon: (
        <Icons.WhatsAppOutlined
          style={{ fontSize: '20px', marginTop: '5px' }}
        />
      ),
      link:
        (WhatsAppSocials &&
          retornaWpp() &&
          `https://wa.me/55${retornaWpp()}/?text=Olá ${RazaoSocial}. Gostaria de informações sobre o Portal de Transparência.`) ||
        null,
      ToolTip: 'WhatsApp',
      background: '#1A9447',
    },
    {
      icon: (
        <Icons.YoutubeFilled style={{ fontSize: '20px', marginTop: '6px' }} />
      ),
      link: YoutubeSocials || null,
      ToolTip: 'Youtube',
      background: '#CD201F',
    },
    {
      icon: (
        <Icons.LinkedinFilled style={{ fontSize: '20px', marginTop: '6px' }} />
      ),
      link: LinkedInSocials || null,
      ToolTip: 'LinkedIn',
      background: '#0A66C2',
    },
    {
      mail: true,
      icon: <Icons.MailFilled style={{ fontSize: '20px', marginTop: '6px' }} />,
      link: (EmailSocials && `mailto:${EmailSocials}`) || null,
      ToolTip: 'E-mail',
      background: '#0061FF',
    },
  ];
  return ArraySocials;
}
