import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReCaptcha from '~/components/ReCaptcha/reCacptcha.component';

import {
  Tooltip,
  Row,
  Col,
  Card,
  PageTitle,
  FormV3 as Form,
  Input,
  Button,
  Notification,
  Map,
} from '~/components';
import { insertFaleConoscoService } from '../services/faleconosco.service';

function FaleConoscoForm({ form }) {
  const { endereco, bairro, cidade, corFonte } = useSelector(
    state => state.configuration
  );
  const { getFieldDecorator } = form;
  const [captcha, SetCaptcha] = useState(null);
  const recaptchaInputRef = useRef({});

  function handleOnCaptchaChange(value) {
    SetCaptcha(value);
  }

  function resetForm() {
    form.resetFields();
    SetCaptcha(null);
    window.grecaptcha.reset();
  }

  function handleSubmit(event) {
    event.preventDefault();

    form.validateFields(async function _(err, values) {
      if (!err) {
        const { Nome, Email, Assunto, Mensagem } = values;

        const faleConosco = {
          Nome,
          Email,
          Assunto,
          Mensagem,
          captcha,
        };
        await insertFaleConoscoService(faleConosco)
          .then(x => {
            Notification.showSuccess('Mensagem enviada com sucesso');
            resetForm();
          })
          .catch(res => Notification.showError(res.response.data));
      }
    });
  }

  return (
    <>
      <PageTitle titulo="Fale Conosco" />
      <Card>
        <Row gutter={16}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form onSubmit={handleSubmit} layout="vertical">
              <Row style={{ marginBottom: 16 }}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item label="Nome" name="Nome" colon={false}>
                    <Tooltip title="Digite seu nome">
                      {getFieldDecorator('Nome', {
                        rules: [
                          {
                            required: true,
                            message: 'Campo obrigatório.',
                          },
                        ],
                      })(
                        <Input minLength={3} maxLength={100} placeholder="Nome" />
                      )}
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: 16 }}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item label="E-mail" colon={false}>
                    <Tooltip title="Digite seu e-mail">
                      {getFieldDecorator('Email', {
                        rules: [
                          {
                            type: 'email',
                            message: 'Email inválido',
                          },
                          {
                            required: true,
                            message: 'Campo obrigatório.',
                          },
                        ],
                      })(
                        <Input
                          maxLength={100}
                          type="email"
                          placeholder="E-Mail"
                        />
                      )}
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: 16 }}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item label="Assunto" colon={false}>
                    <Tooltip title="Digite o Assunto">
                      {getFieldDecorator('Assunto', {
                        rules: [
                          {
                            required: true,
                            message: 'Campo obrigatório.',
                          },
                        ],
                      })(
                        <Input
                          minLength={5}
                          maxLength={100}
                          placeholder="Assunto"
                        />
                      )}
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: 16 }}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item label="Mensagem" colon={false}>
                    <Tooltip title="Digite a Mensagem">
                      {getFieldDecorator('Mensagem', {
                        rules: [
                          {
                            required: true,
                            message: 'Campo obrigatório.',
                          },
                          {
                            min: 10,
                            message:
                              'O campo Mensagem deve ter no mínimo 10 caracteres',
                          },
                        ],
                      })(
                        <Input.TextArea
                          rows={4}
                          min={10}
                          maxLength={500}
                          placeholder="Mensagem"
                          autoComplete="off"
                        />
                      )}
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Col
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{
                  marginBottom: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ReCaptcha
                  ref={recaptchaInputRef}
                  handleOnCaptchaChange={handleOnCaptchaChange}
                />
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Button
                    type="primary"
                    style={{ float: 'right', width: '100%', marginBottom: 16 }}
                    htmlType="submit"
                    disabled={captcha == null}
                  >
                    Enviar
                  </Button>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Map
              rua={endereco}
              bairro={bairro}
              cidade={cidade}
              corFonte={corFonte}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Form.create({
  name: 'FaleConosco',
})(FaleConoscoForm);
