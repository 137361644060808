import { combineReducers } from 'redux';
import loaderReducer from './loader/loader.reducer';
import configurationReducer from './configuration/configuration.reducer';

function rootReducer() {
  return combineReducers({
    loader: loaderReducer,
    configuration: configurationReducer,
  });
}

export default rootReducer();
