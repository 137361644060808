import styled from 'styled-components';
import { Button } from 'antd';

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const ImageButton = styled(Button)`
  && {
    display: inline-block;
    vertical-align: top;
    height: 90px;
    width: 180px;
    padding: 12px;
    margin-bottom: 20px;
  }
`;
