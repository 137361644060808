import styled from 'styled-components';
import { Card } from '~/components';

export const CardWithTitleStyled = styled(Card)`
  .ant-card-head {
    font-weight: 700;
  }

  .ant-card-body {
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const InfosContainer = styled.div`
  .info-autenticidade {
    font-weight: 700;
    margin-right: 10px;
  }

  .ant-tag-error {
    background: #fff2f0 !important;
  }
`;

export const SubInfosContainer = styled.div`
  margin-left: 15px;
`;

export const ContainerAviso = styled.div`
  margin-top: 12px;

  p {
    margin-bottom: 0px;
  }

  .anticon {
    margin-right: 10px;
  }

  div.ant-typography {
    margin-bottom: 0px;
  }

  div:nth-child(2) {
    margin-left: 25px;
    margin-bottom: 0px;
  }

  div:nth-child(2) div {
    padding: 0px;
  }
`;
