import React from 'react';

import { Button } from '~/components';

const ButtonLinkFileManagerLinkAnexo = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    type="link"
    block
    style={{
      wordWrap: 'anywhere',
      whiteSpace: 'normal',
      textAlign: 'left',
    }}
  >
    {children}
  </Button>
);

export default ButtonLinkFileManagerLinkAnexo;
