import React from 'react';
import styled from 'styled-components';
import { Icon as AntDesignIcon } from 'antd';

export const Icon = styled(props => <AntDesignIcon {...props} />)`
  && {
    padding: ${props => props.padding}px;
    padding-left: ${props => props.pl}px;
    padding-right: ${props => props.pr}px;
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
  }
`;
