import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const Imagem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
