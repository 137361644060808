export function getUrlSemDominio() {
  const url = window.location.href;

  return url.slice(url.indexOf('#') + 1);
}

export function getUrlComDominio() {
  return window.location.href;
}

export function getUrlDominio() {
  const url = window.location.href;

  return url.split('#')[0];
}

export default { getUrlSemDominio, getUrlComDominio };
