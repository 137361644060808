import React from 'react';
import { useHistory } from 'react-router-dom';
import Result from '../Result/Result.component';
import { Button } from '../Button/Button.styled';

function NotFound() {
  const history = useHistory();

  const voltarParaHome = () => {
    history.push('/');
  };

  const tituloPagina = 'Página não Encontrada';

  return (
    <>
      <Result
        status="warning"
        title={tituloPagina}
        extra={
          <Button type="primary" onClick={voltarParaHome}>
            Voltar para Home
          </Button>
        }
      />
    </>
  );
}

export default NotFound;
