function toggleHighContrast() {
  return {
    type: '@configuration/TOGGLE_HIGHCONTRAST',
  };
}

function decreaseFontSizePage() {
  return {
    type: '@configuration/DECREASE_FONTSIZE_PAGE',
  };
}

function increaseFontSizePage() {
  return {
    type: '@configuration/INCREASE_FONTSIZE_PAGE',
  };
}

function resetFontSizePage() {
  return {
    type: '@configuration/RESET_FONTSIZE_PAGE',
  };
}

function setConfiguration({
  BannerUrl,
  BrasaoUrl,
  BrasaoPretoBrancoUrl,
  ImagemFundoHomeUrl,
  ImagemFundoTelasUrl,
  CorTema,
  Titulo,
  CorFonte,
  CorFonteTituloPortal,
  CorFonteTituloTelas,
  CorFonteRodape,
  RazaoSocial,
  Endereco,
  Bairro,
  Cidade,
  CEP,
  Telefone,
  Atendimento,
  CorFundoGrid,
  CorFundoTituloGrid,
  CorTituloGrid,
  EmailSocials,
  FacebookSocials,
  InstagramSocials,
  LinkedInSocials,
  TwitterSocials,
  WhatsAppSocials,
  YoutubeSocials,
}) {
  return {
    type: '@configuration/SET_CONFIGURATION',
    payload: {
      BannerUrl,
      BrasaoUrl,
      BrasaoPretoBrancoUrl,
      ImagemFundoHomeUrl,
      ImagemFundoTelasUrl,
      CorTema,
      Titulo,
      CorFonte,
      CorFonteTituloPortal,
      CorFonteTituloTelas,
      CorFonteRodape,
      RazaoSocial,
      Endereco,
      Bairro,
      Cidade,
      CEP,
      Telefone,
      Atendimento,
      CorFundoGrid,
      CorFundoTituloGrid,
      CorTituloGrid,
      EmailSocials,
      FacebookSocials,
      InstagramSocials,
      LinkedInSocials,
      TwitterSocials,
      WhatsAppSocials,
      YoutubeSocials,
    },
  };
}

export {
  toggleHighContrast,
  increaseFontSizePage,
  decreaseFontSizePage,
  resetFontSizePage,
  setConfiguration,
};
