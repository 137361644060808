import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tag, Col, Row, Button, Icons, Tooltip } from '~/components';
import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';

function ShowFiltros({
  filtros,
  setFiltros,
  form,
  removeTodosOsFiltros,
  colunasFiltro,
}) {
  const { corTema } = useSelector(state => state.configuration);
  const [tags, setTags] = useState(null);

  function removeFiltro(event, campo) {
    event.preventDefault();

    const novosFiltros = filtros.filter(filtro => {
      return filtro.Campo !== campo;
    });

    if (novosFiltros.length === 0) removeTodosOsFiltros();
    else setFiltros(novosFiltros);

    form.resetFields([campo]);
  }

  function transformaFiltrosEmTags() {
    const arrayTags = [];

    filtros.forEach(filtro => {
      let nomePadrao;

      colunasFiltro.Grupos.forEach(grupo => {
        grupo.Colunas.forEach(coluna => {
          if (coluna.FonteDados === filtro.Campo)
            nomePadrao = coluna.NomePadrao;
        });
      });

      let valor = `${filtro.Valor}`;

      if (filtro.TipoValor === TIPO_COLUNA.DECIMAL) {
        const pos = valor.lastIndexOf('.');
        valor = `${valor.substring(0, pos)},${valor.substring(pos + 1)}`;
      }

      if (filtro.TipoValor === TIPO_COLUNA.DATA) {
        let aux = filtro.Valor.replace(',', '-');
        aux = aux.split('-');
        valor = `De ${aux[2]}/${aux[1]}/${aux[0]} até ${aux[5]}/${aux[4]}/${aux[3]}`;
      }

      arrayTags.push(
        <Col style={{ margin: '0 5px 5px 5px' }}>
          <span style={{ marginRight: '5px' }}>{nomePadrao}:</span>
          <Tag
            closable
            onClose={event => removeFiltro(event, filtro.Campo)}
            color={corTema}
          >
            {valor}
          </Tag>
        </Col>
      );
    });

    setTags(arrayTags);
  }

  useEffect(() => {
    if (filtros.length > 0) transformaFiltrosEmTags();
    else setTags([]);
  }, [filtros]);

  return (
    <>
      {tags && tags.length > 0 ? (
        <>
          <Row style={{ marginBottom: '5px' }}>
            <strong style={{ marginRight: '10px' }}>Filtros Aplicados:</strong>
            {tags}
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title="Remove Todos os Filtros">
              <Button
                type="primary"
                style={{
                  float: 'right',
                  marginBottom: 16,
                  fontSize: 14,
                }}
                onClick={() => removeTodosOsFiltros()}
              >
                <Icons.ClearOutlined style={{ fontSize: '15px' }} /> Limpar
                Filtros
              </Button>
            </Tooltip>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShowFiltros;
