import React from 'react';
import { Router, Switch } from 'react-router-dom';
import DefaultLayout from '~/modules/_layouts/default/default.layout';
import Dashboard from '~/modules/Dashboard/containers/dashboard.container';
import Faq from '~/modules/FAQ/containers/faq.container';
import DadosAbertos from '~/modules/DadosAbertos/containers/dadosAbertos.container';
import ESic from '~/modules/ESic/containers/esic.container';
import Sumario from '~/modules/Sumario/containers/sumario.container';
import EstruturaOrganizacional from '~/modules/EstruturaOrganizacional/containers/estruturaOrganizacional.container';
import EstruturaOrganizacionalDetalhe from '~/modules/EstruturaOrganizacional/containers/estruturaOrganizacionalDetalhe.container';
import NotFound from '~/components/NotFound/NotFound.component';
import EmManutencao from '~/components/EmManutencao/EmManutencao.component';
import Glossario from '~/modules/Glossario/containers/glossario.container';
import ModuloDinamico from '~/modules/Modulos/ModuloDinamico.component';
import ModuloFixo from '~/modules/Modulos/ModuloFixo.component';
import DetalheVisaoAnalitica from '~/modules/DetalheVisaoAnalitica/containers/detalheVisaoAnalitica.container';
import BuscaAvancada from '~/modules/BuscaAvancada/containers/buscaAvancada.container';
import ItemVisao from '~/modules/Modulos/containers/visaoItem.container';

import history from '~/services/history';
import FaleConosco from '~/modules/FaleConosco/containers/faleConosco';
import Autenticidade from '~/modules/Autenticidade/containers/autenticidade';
import Estatisticas from '~/modules/Estatisticas/containers/Estatisticas';
import Route from './Route';

function Routes() {
  return (
    <Router history={history}>
      <DefaultLayout>
        <Switch>
          <Route path="/" exact component={Dashboard} hasStatistics />
          <Route
            path="/estrutura_organizacional/detalhe/:id/"
            exact
            component={EstruturaOrganizacionalDetalhe}
            hasStatistics
          />
          <Route
            path="/estrutura_organizacional"
            exact
            component={EstruturaOrganizacional}
            hasStatistics
          />
          <Route
            exact
            path="/dinamico/:modulo/:visao"
            component={ModuloDinamico}
            hasStatistics
          />
          <Route
            exact
            path="/dinamico/:modulo/:visao/detalhevisao"
            component={DetalheVisaoAnalitica}
            hasStatistics
          />
          <Route
            exact
            path="/dinamico/:modulo/:visao/busca"
            component={BuscaAvancada}
            hasStatistics
          />
          <Route
            exact
            path="/fixo/:modulo/:visao"
            component={ModuloFixo}
            hasStatistics
          />
          <Route
            exact
            path="/fixo/:modulo/:visao/:itemVisao"
            component={ItemVisao}
            hasStatistics
          />
          <Route path="/faq" exact component={Faq} hasStatistics />
          <Route path="/esic" exact component={ESic} hasStatistics />
          <Route path="/sumario" exact component={Sumario} hasStatistics />
          <Route path="/glossario" exact component={Glossario} hasStatistics />
          <Route path="/dados_abertos" exact component={DadosAbertos} hasStatistics />
          <Route
            path="/estatisticas"
            exact
            component={Estatisticas}
            hasStatistics
          />
          <Route
            path="/faleconosco"
            exact
            component={FaleConosco}
            hasStatistics
          />
          <Route
            path="/autenticidade"
            exact
            component={Autenticidade}
            hasStatistics
          />
          <Route path="/manutencao" component={EmManutencao} />
          <Route path="/notfound" component={NotFound} />
          <Route path="*" component={NotFound} />
        </Switch>
      </DefaultLayout>
    </Router>
  );
}

export default Routes;
