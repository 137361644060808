import React from 'react';
import { Row, Col, Divider } from '~/components';
import { TIPO_AGRUPAMENTO } from '~/modules/Helper/tipo-agrupamento';
import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';

function Totalizadores({ totalizadores }) {
  return (
    <>
      <Divider style={{ marginBottom: '-10px', paddingBottom: '20px' }} />
      <Row
        gutter={[8, 10]}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {totalizadores.map(item => (
          <Col
            lg={{ span: 6 }}
            key={Math.random()}
            style={{
              textAlign: 'center',
              display: 'grid',
              marginBottom: '10px',
            }}
          >
            <span>{item.DescricaoTotalizador}</span>
            <b>
              {item.TipoValor === TIPO_COLUNA.DECIMAL &&
              item.TipoAgrupamento !== TIPO_AGRUPAMENTO.CONTADOR
                ? item.Total.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : item.Total}
            </b>
          </Col>
        ))}
      </Row>
      <Divider style={{ marginTop: '0' }} />
    </>
  );
}

export default Totalizadores;
