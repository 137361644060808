import React from 'react';
import { PageTitle, Informacao } from '~/components';

function Header({ configuracao }) {
  return (
    <>
      {configuracao?.Titulo && <PageTitle titulo={configuracao.Titulo} />}

      {configuracao?.Informacao?.Texto && (
        <Informacao
          titulo={configuracao.Informacao.Titulo}
          informacao={configuracao.Informacao.Texto}
        />
      )}
    </>
  );
}

export default Header;
