/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import React from 'react';
import { Row, Col, Tooltip, Input, Select, Button, Icons } from '~/components';
import { TIPO_VISAO } from '~/modules/Helper/tipo-visao';
import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';
import { useUrls } from '~/contexts/url.context';

const { Search } = Input;

function FiltroPeriodicidadeModuloDinamico({
  filtro,
  pagina,
  setPagina,
  configuracao,
  obterColunaChave,
  obterColunaLink,
  setFiltro,
  InputCarregando,
  periodicidadeSelecionada,
  setPeriocidadeSelecionada,
  listaPeriodicidades,
  periodoDesabilitado,
  periodoSelecionado,
  setPeriodoSelecionado,
  listaPeriodos,
  exercicioSelecionado,
  setExercicioSelecionado,
  listaExercicios,
  isFiltroAvancadoHabilitado,
  history,
  obterOrdenacaoPadrao,
  setDataFilterHeader,
  dataFilterHeader
}) {
  const { empilhaUrl } = useUrls();
  const tiposValorString = [
    TIPO_COLUNA.TEXTO_50,
    TIPO_COLUNA.TEXTO_100,
    TIPO_COLUNA.LINK,
    TIPO_COLUNA.BOOLEANO,
  ];

  const tiposValorData = [TIPO_COLUNA.DATA];

  const tiposValorNumber = [TIPO_COLUNA.INTEIRO, TIPO_COLUNA.DECIMAL];

  function verificaTipoInputEPlaceholder() {
    let inputType = 'text';
    let textoPlaceholder = 'Filtro';
    if (configuracao && configuracao.VisaoColunas) {
      const visaoColuna =
        configuracao.Tipo === TIPO_VISAO.AGRUPAMENTO
          ? obterColunaChave()
          : obterColunaLink();

      if (visaoColuna) {
        const { TipoValor, NomeColuna } = visaoColuna;
        
        if (TipoValor) {
          if (tiposValorString.includes(TipoValor)) {
            inputType = 'text';
          } else if (tiposValorNumber.includes(TipoValor)) {
            inputType = 'number';
          } else if (tiposValorData.includes(TipoValor)) {
            inputType = 'date';
          }
        }

        textoPlaceholder = `Filtro por ${NomeColuna}`;
      }
    }

    return { inputType, textoPlaceholder };
  }

  const {
    inputType,
    textoPlaceholder
  } = verificaTipoInputEPlaceholder();

  function restaurarOrdenacao() {
    const colunaChave =
      configuracao?.Tipo === TIPO_VISAO.AGRUPAMENTO
        ? obterColunaChave()
        : obterColunaLink();

    if (colunaChave) obterOrdenacaoPadrao(colunaChave);

    let dataFilter = {
      ...dataFilterHeader,
      Filtros: null,
    };
    setDataFilterHeader(dataFilter);
    setFiltro([]);
  }

  return (
    <Row
      gutter={[16, 16]}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 32,
        marginBottom: 18,
      }}
    >
      <Col
        lg={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Filtro">
          <Search
            placeholder={textoPlaceholder}
            maxLength={50}
            value={filtro?.length > 0 ? filtro[0]?.Valor : ''}
            type={inputType}
            onChange={event => {
              if (pagina !== 1) setPagina(1);

              const { FonteDados, TipoValor } =
                configuracao.Tipo === TIPO_VISAO.AGRUPAMENTO
                  ? obterColunaChave()
                  : obterColunaLink();

              setFiltro([
                {
                  Campo: FonteDados,
                  Valor: event.target.value,
                  TipoValor,
                },
              ]);
            }}
            loading={InputCarregando}
          />
        </Tooltip>
      </Col>

      {isFiltroAvancadoHabilitado &&
        configuracao?.Tipo === TIPO_VISAO.ANALITICO && (
          <Col
            lg={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '170px',
            }}
          >
            <Tooltip title="Busca Avançada">
              <Button
                type="primary"
                icon={<Icons.FilterFilled />}
                onClick={() => {
                  empilhaUrl(window.location.hash.split('#')[1]);
                  history.push({ pathname: `${configuracao.Uri}/busca` });
                }}
              >
                Busca Avançada
              </Button>
            </Tooltip>
          </Col>
        )}

      {configuracao?.Tipo === TIPO_VISAO.AGRUPAMENTO ||
      configuracao?.Tipo === TIPO_VISAO.ANALITICO ? (
        <>
          <Col
            lg={3}
            style={{
              flexDirection: 'column',
              minWidth: '185px',
            }}
          >
            <Tooltip title="Restaurar Ordenação Padrão">
              <Button
                type="primary"
                icon={<Icons.UndoOutlined />}
                onClick={() => restaurarOrdenacao()}
              >
                Restaurar Ordenação
              </Button>
            </Tooltip>
          </Col>
        </>
      ) : (
        <Col lg={3} />
      )}

      {periodicidadeSelecionada && (
        <>
          <Col lg={{ span: 3, offset: 2 }}>
            <Tooltip title="Periodicidade">
              <Select
                placeholder="Periodicidade"
                value={periodicidadeSelecionada}
                defaultValue={periodicidadeSelecionada || 'MENSAL'}
                onChange={value => {
                  setPeriocidadeSelecionada(value);
                  setPagina(1);
                }}
                style={{ width: '100%', color: '#000' }}
              >
                {listaPeriodicidades?.length &&
                  listaPeriodicidades.map(value => (
                    <Select.Option
                      key={value.Id}
                      value={value.Id}
                      style={{ color: '#000' }}
                    >
                      {value.Descricao}
                    </Select.Option>
                  ))}
              </Select>
            </Tooltip>
          </Col>

          <Col lg={3}>
            <Tooltip title="Período">
              <Select
                placeholder="Período"
                disabled={periodoDesabilitado}
                value={periodoSelecionado && periodoSelecionado.toUpperCase()}
                onChange={value => {
                  setPeriodoSelecionado(value);
                  setPagina(1);
                }}
                style={{ width: '100%', color: '#000' }}
              >
                {listaPeriodos?.length &&
                  listaPeriodos.map(p => (
                    <Select.Option
                      key={p.Id}
                      value={p.Id}
                      style={{ color: '#000' }}
                    >
                      {p.Descricao}
                    </Select.Option>
                  ))}
              </Select>
            </Tooltip>
          </Col>

          <Col lg={3}>
            <Tooltip title="Exercício">
              <Select
                placeholder="Exercício"
                defaultValue={new Date().getFullYear()}
                value={exercicioSelecionado}
                onChange={value => setExercicioSelecionado(value)}
                style={{ width: '100%', color: '#000' }}
              >
                {listaExercicios.map(exerc => (
                  <Select.Option
                    key={exerc.Exercicio}
                    value={exerc.Exercicio}
                    style={{ color: '#000' }}
                  >
                    {exerc.Exercicio}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
        </>
      )}
    </Row>
  );
}

export default FiltroPeriodicidadeModuloDinamico;
