import React, { createRef, useEffect, useState } from 'react';
import {
  InformacaoFilter,
  PageTitle,
  Card,
  Row,
  ButtonGoBack,
  Input,
} from '~/components';
import TreeViewModuloFixo from './components/treeViewModuloFixo.component';
import { getModuloFixoService } from './services/moduloFixo.service';

function ModuloFixo({ match, history }) {
  const [informacao, setInformacao] = useState({ __html: null });
  const [treeView, setTreeView] = useState([]);
  const [titulo, setTitulo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const treeViewMethods = createRef({});
  const infoMethods = createRef({});

  const { Search } = Input;

  const { modulo, visao } = match.params;

  useEffect(() => {
    async function getModuloFixo() {
      const response = await getModuloFixoService(modulo, visao);
      if (response && response.data) {
        setInformacao(response.data.Informacao);
        setTreeView(response.data.VisaoItens);
        setTitulo(response.data.Titulo);
      }
    }

    getModuloFixo();
  }, [modulo, visao]);

  function goBack() {
    history.push(`/`);
  }

  function onSearch(busca) {
    if (informacao?.Texto) infoMethods.current.filterElements(busca);
    if (treeView?.length > 0) treeViewMethods.current.onSearch(busca);
  }

  function onChange(e) {
    if (informacao?.Texto) infoMethods.current.onChangeInfo(e);
    if (treeView?.length > 0) treeViewMethods.current.onChange(e);
  }

  return (
    <>
      <PageTitle titulo={titulo} />
      <Search
        style={{ marginBottom: 8, width: '50%' }}
        placeholder={`Filtrar ${titulo}`}
        onSearch={busca => onSearch(busca)}
        onChange={e => onChange(e)}
        allowClear
        value={searchValue}
      />
      {informacao?.Texto && (
        <>
          <InformacaoFilter
            ref={infoMethods}
            titulo={informacao.Titulo}
            informacao={informacao.Texto}
            setSearchValue={setSearchValue}
          />
        </>
      )}
      {treeView?.length > 0 && (
        <>
          <Card>
            <TreeViewModuloFixo
              ref={treeViewMethods}
              setSearchValue={setSearchValue}
              treeView={treeView}
              history={history}
              modulo={modulo}
              visao={visao}
            />
          </Card>
        </>
      )}
      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default ModuloFixo;
