import React, { useState } from 'react';

const HomeGraficoContext = React.createContext({});

function ListaHomeGraficoContext({ children }) {
  const [item, setItem] = useState([]);
  const [home, setHome] = useState(null);
  const [exercicio, setExercicio] = useState({});
  const anoAtual = new Date().getFullYear();

  return (
    <HomeGraficoContext.Provider
      value={{
        item,
        setItem,
        home,
        setHome,
        anoAtual,
        exercicio,
        setExercicio,
      }}
    >
      {children}
    </HomeGraficoContext.Provider>
  );
}

export { ListaHomeGraficoContext, HomeGraficoContext };
