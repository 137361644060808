import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle.component';
import { Container, Content, IconSection } from './style';

function Informacao({ titulo, informacao, icon}) {
  return (
    <>
      <SectionTitle titulo={titulo} icon={icon} />
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: informacao }} />
      </Container>
    </>
  );
}

export default Informacao;
