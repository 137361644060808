import axios from 'axios';
import {
  addRequest,
  removeRequest,
} from '~/store/modules/loader/loader.actions';
import { SMARAPD_PAI_DOMAIN } from '~/services/urls';
import store from '~/store';
import history from './history';

const api = axios.create({
  baseURL: SMARAPD_PAI_DOMAIN,
});

api.interceptors.request.use(
  function handleRequest(config) {
    store.dispatch(addRequest());
    return config;
  },
  function handleError(error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function handleResponse(response) {
    store.dispatch(removeRequest());
    return response;
  },
  function handleError(error) {
    store.dispatch(removeRequest());
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function handleResponse(response) {
    return response;
  },
  function handleError(error) {
    if (error && error.response) {
      store.dispatch(removeRequest());
      const { status } = error.response;

      if (status === 404) history.push('/notfound');
    }
    return Promise.reject(error);
  }
);

export default api;
