import api from '~/services/api';

export function getItemVisaoService(chaveModulo, uriVisao, idVisaoItemDetalhe) {
  return api.get(
    `ModuloVisaoItemDetalhe/${chaveModulo}/${uriVisao}/${parseInt(
      idVisaoItemDetalhe,
      10
    )}`
  );
}
