import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  &&.ant-picker {
    width: 100%;
  }
`;
