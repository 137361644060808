import React, { useState, useRef, useEffect } from 'react';
import ReCaptcha from '~/components/ReCaptcha/reCacptcha.component';

import {
  Tooltip,
  Row,
  Col,
  Card,
  PageTitle,
  FormV3 as Form,
  Input,
  Button,
  Notification,
  Informacao,
  ButtonGoBack,
} from '~/components';
import {
  obterAutenticidade,
  obterConfiguracoes,
} from '../services/autenticidade.service';
import AutenticidadeValidacao from './autenticidadeValidacao.component';

import * as S from './autenticidade.styled';

function AutenticidadeForm({ form }) {
  const { getFieldDecorator } = form;
  const [captcha, SetCaptcha] = useState(null);
  const recaptchaInputRef = useRef({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [dadosValidacao, setDadosValidacao] = useState();
  const [configuracoes, setConfiguracoes] = useState();

  function handleOnCaptchaChange(value) {
    SetCaptcha(value);
  }

  function handleSubmit(event) {
    event.preventDefault();

    form.validateFields(async function _(err, values) {
      if (!err) {
        const { CodigoValidacao } = values;

        await obterAutenticidade(CodigoValidacao, true)
          .then(resp => {
            setDadosValidacao(resp.data);
            setHasSubmitted(true);
          })
          .catch(() => Notification.showError('Código de Validação Inválido'));
      }
    });
  }

  function goBack() {
    SetCaptcha(null);
    setHasSubmitted(false);
  }

  useEffect(() => {
    obterConfiguracoes().then(resp => setConfiguracoes(resp.data));
  }, []);

  return (
    <>
      <PageTitle titulo={`${hasSubmitted ? 'Resultado ' : ''}${configuracoes?.NomeModulo}`} />
      {!hasSubmitted ? (
        <Card>
          {configuracoes?.Informacao && (
            <S.ContainerInformacao>
              <Informacao
                titulo={configuracoes?.Informacao?.Titulo}
                informacao={configuracoes?.Informacao?.Texto}
              />
            </S.ContainerInformacao>
          )}
          <Row gutter={16} justify="center">
            <Col lg={8} md={24} sm={24} xs={24}>
              <Form onSubmit={handleSubmit} layout="vertical">
                <Row style={{ marginBottom: 16 }}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Código de Validação"
                      name="Código de Validação"
                      colon={false}
                    >
                      <Tooltip title="Código de Validação">
                        {getFieldDecorator('CodigoValidacao', {
                          rules: [
                            {
                              required: true,
                              message: 'Campo obrigatório.',
                            },
                          ],
                        })(
                          <Input
                            maxLength={100}
                            placeholder="Código de Validação"
                          />
                        )}
                      </Tooltip>
                    </Form.Item>
                  </Col>
                </Row>

                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{
                    marginBottom: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReCaptcha
                    ref={recaptchaInputRef}
                    handleOnCaptchaChange={handleOnCaptchaChange}
                  />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Row>
                    <Button
                      type="primary"
                      style={{
                        float: 'right',
                        width: '100%',
                        marginBottom: 16,
                      }}
                      htmlType="submit"
                      disabled={captcha == null}
                    >
                      Validar
                    </Button>
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Card>
      ) : (
        <AutenticidadeValidacao
          dadosValidacao={dadosValidacao}
          aviso={configuracoes?.Aviso}
        />
      )}
      {hasSubmitted && (
        <Row>
          <ButtonGoBack OnClick={goBack} />
        </Row>
      )}
    </>
  );
}
export default Form.create({
  name: 'Autenticidade',
})(AutenticidadeForm);
