import { useState, useEffect } from 'react';

export function useDebounce(text, delay) {
  const [debouncedValue, setDebouncedValue] = useState(text);

  useEffect(() => {
    const time = setTimeout(() => {
      setDebouncedValue(text);
    }, delay);

    return () => {
      clearTimeout(time);
    };
  }, [delay, text]);

  return debouncedValue;
}
