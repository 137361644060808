export const breadcrumbEstruturaOrganizacional = [
  {
    path: '/estrutura_organizacional',
    breadcrumb: [{ name: 'Estrutura Organizacional' }],
  },
  {
    path: '/estrutura_organizacional/detalhe/:id/',
    breadcrumb: [
      {
        name: 'Estrutura Organizacional',
        path: '/estrutura_organizacional',
      },
      { name: 'Detalhes' },
    ],
  },
];

export const breadcrumbHome = [{ path: '/', breadcrumb: [{ name: 'Home' }] }];

export const breadcrumbFaq = [{ path: '/faq', breadcrumb: [{ name: 'FAQ' }] }];

export const breadcrumbDadosAbertos = [{ path: '/dados_abertos', breadcrumb: [{ name: 'Dados Abertos' }] }];

export const breadcrumbEsic = [
  { path: '/esic', breadcrumb: [{ name: 'e-SIC' }] },
];

export const breadcrumbSumario = [
  { path: '/sumario', breadcrumb: [{ name: 'Sumário' }] },
];

export const breadcrumbGlossario = [
  { path: '/glossario', breadcrumb: [{ name: 'Glossário' }] },
];

export const breadcrumbFaleConosco = [
  { path: '/faleconosco', breadcrumb: [{ name: 'Fale Conosco' }] },
];

export const breadcrumbEstatisticas = [
  { path: '/estatisticas', breadcrumb: [{ name: 'Estatísticas' }] },
];

export const breadcrumbAutenticidade = [
  { path: '/autenticidade', breadcrumb: [{ name: 'Autenticidade' }] },
];
