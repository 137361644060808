import styled from 'styled-components';
import { Divider } from 'antd';

export const StyledDivider = styled(Divider)`
  &&.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0px;
  }
  && .ant-divider-inner-text {
    padding: 0 1em 0 0.5em;
  }
`;
