import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './SectionTitle.styled';

function SectionTitle({ titulo, icon }) {
  const corTema = useSelector(state => state.configuration.corTema);

  return (
    <S.Paragraph color={corTema}>
      {icon}
      {titulo}
    </S.Paragraph>
  );
}

export default SectionTitle;
