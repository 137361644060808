import React from 'react';

import {
  Tooltip,
  Row,
  Col,
  Button,
  Informacao,
  Icons,
  Tag,
  Typography,
  Card,
} from '~/components';

import * as S from './autenticidadeValidacao.styled';

import { iconEnum } from '~/modules/Helper/iconEnum';
import { ChooseIconFile } from '~/modules/Helper/chooseIconFile';

import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import { STATUS_AUTENTICIDADE } from '~/modules/Helper/statusAutenticidade';

function AutenticidadeValidacao({ dadosValidacao, aviso }) {
  function renderTagFromStatusAutenticidade() {
    if (dadosValidacao?.Status === STATUS_AUTENTICIDADE.DENTRO_PRAZO)
      return (
        <Tag style={{ display: 'flex', alignItems: 'center' }} color="success">
          Documento dentro do Prazo de Validade
        </Tag>
      );
    if (dadosValidacao?.Status === STATUS_AUTENTICIDADE.FORA_PRAZO)
      return (
        <Tag style={{ display: 'flex', alignItems: 'center' }} color="error">
          Documento com Prazo Vencido
        </Tag>
      );

    return null;
  }

  return (
    <>
      <Card style={{ marginBottom: '16px' }}>
        <S.InfosContainer>
          <Row style={{ marginBottom: '18px' }}>
            <span className="info-autenticidade">Status:</span>
            {renderTagFromStatusAutenticidade()}
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <span className="info-autenticidade">Informações do Documento</span>
          </Row>
          <S.SubInfosContainer>
            <Row>
              <span className="info-autenticidade">Nome:</span>
              {dadosValidacao?.NomeArquivo}
            </Row>
            <Row>
              <span className="info-autenticidade">Data/Hora:</span>
              {dadosValidacao?.DataHoraExportacao}
            </Row>
            <Row>
              <span className="info-autenticidade">Válido até:</span>
              {dadosValidacao?.DataHoraValidade}
            </Row>
          </S.SubInfosContainer>
        </S.InfosContainer>
        {aviso && (
          <S.ContainerAviso>
            <Informacao
              titulo={aviso?.Titulo}
              informacao={aviso?.Texto}
              icon={<Icons.InfoCircleOutlined />}
            />
          </S.ContainerAviso>
        )}
      </Card>

      <S.CardWithTitleStyled
        style={{ marginBottom: '16px' }}
        title="Página de Origem das Informações"
      >
        <Row>
          <Col xs={18} style={{ display: 'flex', alignItems: 'center' }}>
            <Icons.LinkOutlined
              style={{ marginRight: '10px', fontSize: '20px' }}
            />
            {dadosValidacao?.TituloRelatorio}
          </Col>
          <Col xs={6} style={{ justifyContent: 'right', display: 'flex' }}>
            <Tooltip title="Acessar Url">
              <Button
                shape="circle"
                type="primary"
                onClick={() =>
                  window.open(dadosValidacao?.UrlExportacao, '_blank')
                }
              >
                <Icons.EyeOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </S.CardWithTitleStyled>

      {dadosValidacao.Status === STATUS_AUTENTICIDADE.DENTRO_PRAZO && (
        <S.CardWithTitleStyled title="Arquivo Original">
          <Row>
            <Col xs={18} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography.Text style={{ marginRight: '10px' }}>
                {iconEnum
                  .find(
                    x =>
                      x.name ===
                      `file-${ChooseIconFile(
                        dadosValidacao?.NomeArquivo?.split(
                          '.',
                          2
                        )[1].toLowerCase()
                      )}`
                  )
                  ?.value({ fontSize: '20px' })}
              </Typography.Text>
              {dadosValidacao?.NomeArquivo}
            </Col>
            <Col xs={6} style={{ justifyContent: 'right', display: 'flex' }}>
              <Tooltip title="Baixar Arquivo">
                <Button
                  shape="circle"
                  type="primary"
                  onClick={() =>
                    fileManagerPaiDownload(dadosValidacao?.NomeArquivo)
                  }
                >
                  <Icons.DownloadOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </S.CardWithTitleStyled>
      )}
    </>
  );
}

export default AutenticidadeValidacao;
