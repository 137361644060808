import styled from 'styled-components';
import { Typography } from '../Typography/Typography.styled';

export const Title = styled(Typography.Title)`
  && {
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: ${({ theme }) => `${20 + 2 * theme.zoomFonte}px`};
  }
`;
