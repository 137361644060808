import styled from 'styled-components';
import Result from '../Result/Result.component';

export const InfoManutencao = styled(Result)` 
  justify-content: center;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .ant-result-subtitle {
    font-size: 1.25rem;
  }
`;
