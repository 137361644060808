import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { setConfiguration } from '~/store/modules/configuration/configuration.actions';

import api from '~/services/api';

import {
  Layout,
  Drawer,
  Sider,
  Logo,
  Menu,
  Header,
  Collapse,
  Title,
  Acessibility,
  Content,
  Footer,
  BackTopPage,
  Icons,
  Socials,
} from '~/components';
import { getFullUrl } from '~/services/FileManager/file-manager.service';
import { useMenus } from '~/contexts/menu.context';

function DefaultLayout({ children }) {
  const { isLoadingMenus } = useMenus();
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [backTopBgColor, setBackTopBgColor] = useState();

  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

  useEffect(() => {
    async function getConfiguracao() {
      const [responseConfiguracao, responseCliente] = await Promise.all([
        api.get('configuracaoaparencia'),
        api.get('cliente'),
      ]);

      const {
        BannerUrl,
        BrasaoUrl,
        BrasaoPretoBrancoUrl,
        ImagemFundoHomeUrl,
        ImagemFundoTelasUrl,
        CorTema,
        Titulo,
        CorFonteTituloPortal,
        CorFonteTituloTelas,
        CorFonte,
        CorFonteRodape,
        CorFundoRodape,
        CorFundoGrid,
        CorFundoTituloGrid,
        CorTituloGrid,
      } = responseConfiguracao.data;

      const {
        RazaoSocial,
        Endereco,
        Bairro,
        Cidade,
        CEP,
        Telefone,
        Atendimento,
        EmailSocials,
        FacebookSocials,
        InstagramSocials,
        LinkedInSocials,
        TwitterSocials,
        WhatsAppSocials,
        YoutubeSocials,
      } = responseCliente.data;

      dispatch(
        setConfiguration({
          BannerUrl: getFullUrl(BannerUrl),
          BrasaoUrl: getFullUrl(BrasaoUrl),
          BrasaoPretoBrancoUrl: getFullUrl(BrasaoPretoBrancoUrl),
          ImagemFundoHomeUrl: getFullUrl(ImagemFundoHomeUrl),
          ImagemFundoTelasUrl: getFullUrl(ImagemFundoTelasUrl),
          CorTema,
          Titulo,
          CorFonteTituloPortal,
          CorFonteTituloTelas,
          CorFonte,
          CorFonteRodape,
          RazaoSocial,
          Endereco,
          Bairro,
          Cidade,
          CEP,
          Telefone,
          Atendimento,
          CorFundoGrid,
          CorFundoTituloGrid,
          CorTituloGrid,
          EmailSocials,
          FacebookSocials,
          InstagramSocials,
          LinkedInSocials,
          TwitterSocials,
          WhatsAppSocials,
          YoutubeSocials,
        })
      );

      setBackTopBgColor(CorTema);

      window.less
        .modifyVars({
          '@primary-color': CorTema || 'rgba(0, 0, 0, 0.1);',
          '@icon-color-hover': '#f5f5f5',
          '@link-hover-decoration': '#ffffff',
          '@layout-sider-background': CorTema || '#036f5a',
          '@menu-dark-bg': `${CorTema} !important` || '#036f5a',
          '@layout-footer-background': CorFundoRodape || '#fff',
          '@heading-color': `${CorFonte}` || '#000',
          '@text-color': `${CorFonte}` || '#000',
          '@text-color-secondary': `${CorFonte}` || '#000',
          '@disabled-color': `${CorFonte}` || '#000',
          '@link-color': '#1890ff',
          // Titulo Grid
          '@table-header-color': CorTituloGrid || '#000',
          '@table-footer-color': CorTituloGrid || '#000',
          // Fundo Grid
          '@table-bg': CorFundoGrid || '#f2f2f2',
          '@table-body-sort-bg': CorFundoGrid || '#f2f2f2',
          // Fundo Titulo Grid
          '@table-header-bg': CorFundoTituloGrid || '#fff2f2',
          '@table-header-sort-bg': CorFundoTituloGrid || '#f2f2f2',
          '@table-header-sort-active-bg': CorFundoTituloGrid || '#f2f2f2',
          '@table-footer-bg': CorFundoTituloGrid || '#f2f2f2',
        })
        .then(() => setIsLoading(false));
    }

    getConfiguracao();
  }, [dispatch]);

  function handleToggle() {
    setOpenKeys([]);
    setCollapsed(!collapsed);
  }

  return isLoading || isLoadingMenus ? (
    <Layout />
  ) : (
    <Layout>
      {isMobile ? (
        <Drawer
          placement="left"
          visible={collapsed}
          closable={false}
          onClose={handleToggle}
        >
          <Sider>
            <Logo />
            <Menu
              openKeys={openKeys}
              setOpenKeys={setOpenKeys}
              setCollapsed={setCollapsed}
            />
            <Menu onClick={() => handleToggle(false)} />
          </Sider>
        </Drawer>
      ) : (
        <Sider collapsed={collapsed}>
          <Logo />
          <Menu
            openKeys={openKeys}
            setOpenKeys={setOpenKeys}
            collapsed={collapsed}
          />
        </Sider>
      )}

      <Layout id="layout">
        <Header>
          <span>
            <Collapse collapsed={collapsed} handleToggle={handleToggle} />
            <Title />
          </span>

          <span style={{ position: 'absolute', top: 0, right: 0 }}>
            <Acessibility />
          </span>
        </Header>
        <Content>{children}</Content>
        <Socials />
        <Footer />
        <BackTopPage
          icon={<Icons.UpOutlined />}
          backgroundColor={backTopBgColor || 'rgba(0, 0, 0, 0.1);'}
        />
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
