import React, { useState, useEffect } from 'react';
import { Accordion, Select, Exportacao, Notification } from '~/components';
import { Content, ContainerExportar } from './style';
import { useSelector } from 'react-redux';
import { UltimaAtualizacao } from '../../../modules/Modulos/components/Grid/UltimaAtualizacao.component';
import { getUrlComDominio } from '~/modules/Helper/getUrl-helper';
import { exportarVisao } from '../../Modulos/services/moduloVisao.service';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';

const { Panel } = Accordion;
const { Option, OptGroup } = Select;

function DadosAbertosList({ chaveModulo, visaoAnalitica, modulo }) {
  const periodicidades = visaoAnalitica && visaoAnalitica.Periodicidades ? visaoAnalitica.Periodicidades : [];
  const configuration = useSelector(state => state.configuration);
  const { corTituloGrid } = configuration;
  const dataUltimaAtualizacao = visaoAnalitica.DataUltimaSicronizacaoModulo;

  const [periodicidadeSelecionada, setPeriodicidadeSelecionada] = useState(null);
  const [periodicidadeDescricao, setPeriodicidadeDescricao] = useState('Periodicidade');
  const [exercicioSelecionado, setExercicioSelecionado] = useState(null);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(null);
  const [periodoDescricao, setperiodoDescricao] = useState('Exercício');
  

  const existePeriodicidade = periodicidades.length > 0;
  const periodicidadeUnica = periodicidades.length === 1;

  useEffect(() => {
    if (periodicidadeUnica){
      setPeriodicidadeSelecionada(periodicidades[0].Id);
      setPeriodicidadeDescricao(periodicidades[0].Descricao);
    };
  }, [visaoAnalitica]);

  const handlePeriodicidadeChange = (value) => {
    const parsedValue = JSON.parse(value);
    const { periodicidadeId, periodicidadeDescricao } = parsedValue;

    setPeriodicidadeSelecionada(periodicidadeId);
    setPeriodicidadeDescricao(periodicidadeDescricao);
    setExercicioSelecionado(null);
    setperiodoDescricao('Exercício');
  };

  const handleExercicioChange = (value) => {
    const parsedValue = JSON.parse(value);
    const { exercicio, periodoId, periodoDescricao } = parsedValue;

    setExercicioSelecionado(exercicio);
    setPeriodoSelecionado(periodoId);
    setperiodoDescricao(periodicidadeUnica && !periodoDescricao ? exercicio : periodoDescricao);
  };

  async function exportar(tipoExportacao) {
    if (existePeriodicidade && !exercicioSelecionado) {
      Notification.showError("Selecione um exercício antes de exportar.");
      return;
    }

    const payload = {
      ChaveModulo: chaveModulo,
      Exercicio: existePeriodicidade ? Number(exercicioSelecionado) : null,
      Periodicidade: periodicidadeSelecionada,
      Periodo: periodoSelecionado,
      NomeVisao: visaoAnalitica.NomeVisaoAnalitica,
      UrlExportacao: getUrlComDominio(),
    };

    try {
      const res = await exportarVisao(tipoExportacao, payload);
      fileManagerPaiDownload(res.data);
    } catch (error) {
      Notification.showError(error.response.data);
    }
  }

  return (
    <>
      <Content>
        <Panel forceRender header={modulo.Nome} key="1" style={{ marginBottom: '14px' }}>
          <Content dangerouslySetInnerHTML={{ __html: visaoAnalitica }} />
        </Panel>

        <Select 
          disabled={!existePeriodicidade || periodicidadeUnica}
          style={{ width: 240, marginBottom: 10 }} 
          onChange={handlePeriodicidadeChange}
          value={periodicidadeDescricao}
        >
          {periodicidades && periodicidades.map((periodicidade) => (
            <Option key={periodicidade.Id} value={JSON.stringify({periodicidadeId: periodicidade.Id, periodicidadeDescricao: periodicidade.Descricao})}>
              {periodicidade.Descricao}
            </Option>
          ))}
        </Select>

        {existePeriodicidade &&(
          <Select 
            style={{ width: 360, marginBottom: 10, marginLeft: 10 }} 
            onChange={handleExercicioChange}
            disabled={!periodicidadeSelecionada}
            value={periodoDescricao}
          >
            {visaoAnalitica?.Exercicios?.map((exercicio) => (    
              periodicidadeSelecionada !== 'ANUAL' ? (    
              <OptGroup key={exercicio.Exercicio} label={exercicio.Exercicio}>
                {periodicidades
                  .filter(p => p.Id === periodicidadeSelecionada)
                  .map((periodicidade) => (
                    periodicidade.Periodos?.map((periodo) => (
                      <Option 
                        key={`${exercicio.Exercicio}-${periodo.Descricao}`} 
                        value={JSON.stringify({ exercicio: exercicio.Exercicio, periodoId: periodo.Id, periodoDescricao: periodo.Descricao })}
                      >
                        {`${periodo.Descricao}`}
                      </Option>
                    ))
                  ))}
              </OptGroup>
               ) : (
              <Option key={exercicio.Exercicio} value={JSON.stringify({ exercicio: exercicio.Exercicio })}>
                {exercicio.Exercicio}
              </Option>
            )))}
          </Select>
        )}

        <ContainerExportar>
          <Exportacao onClick={exportar} semURI/>
        </ContainerExportar>

        <div style={{ marginTop: '10px' }}>
          {dataUltimaAtualizacao && (
            <UltimaAtualizacao
              dataHora={dataUltimaAtualizacao}
              corTituloGrid={corTituloGrid}
            />
          )}
        </div>
      </Content>
    </>
  );
}

export default DadosAbertosList;
