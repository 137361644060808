import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Informacao, PageTitle, Row, ButtonGoBack } from '~/components';
import { getDadosAbertosService } from '../services/dadosAbertos.service';
import DadosAbertosList from './../components/dadosAbertos.component';

function DadosAbertos() {
  const [informacao, setInformacao] = useState({ __html: null });
  const [listaModulos, setListaModulos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getDadosAbertos() {

      const response = await getDadosAbertosService();
      if (response && response.data.Informacao) {
        setListaModulos(response.data.Modulos);
        setInformacao(response.data.Informacao);
      }

    }

    getDadosAbertos();
  }, []);

  function goBack() {
    history.push('/');
  }

  return (
    <>
      <PageTitle titulo="Dados Abertos" />
      {informacao?.Texto && (
        <Informacao titulo={informacao.Titulo} informacao={informacao.Texto} />
      )}

      {Boolean(listaModulos?.length) &&
        listaModulos.map(el => (
          <DadosAbertosList
            key={el.ChaveModulo}
            modulo={el}
            chaveModulo={el.ChaveModulo}
            visaoAnalitica={el.VisaoAnalitica}
          />
        ))}

      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default DadosAbertos;
