import styled from 'styled-components';
import { Button } from 'antd';
import * as Icons from '@ant-design/icons';

export const Icon = styled(Icons.EnvironmentOutlined)`
  font-size: 14px;
`;

export const ImageButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 12px;
  }
`;
