import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '~/services/api';

export const MenuContext = createContext();

export default function MenuProvider({ children }) {
  const [menuSelecionado, setMenuSelecionado] = useState(undefined);
  const [menus, setMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function resetMenuSelecionado() {
    setMenuSelecionado(undefined);
  }

  useEffect(() => {
    const getMenus = async () => {
      const responseMenu = await api.get('menu');

      setMenus(responseMenu.data);
      setIsLoading(false);
    };

    getMenus();
  }, []);

  return (
    <MenuContext.Provider
      value={{
        menus,
        menuSelecionado,
        setMenuSelecionado,
        isLoadingMenus: isLoading,
        resetMenuSelecionado,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export function useMenus() {
  const {
    menus,
    menuSelecionado,
    setMenuSelecionado,
    isLoadingMenus,
    resetMenuSelecionado,
  } = useContext(MenuContext);

  return {
    menus,
    menuSelecionado,
    setMenuSelecionado,
    isLoadingMenus,
    resetMenuSelecionado,
  };
}
