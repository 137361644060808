import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './PageTitle.styled';

function PageTitle({ titulo }) {
  const { corFonteTituloTelas: corTitulo, corTema } = useSelector(
    state => state.configuration
  );

  return (
    <S.Title level={4} color={corTitulo || corTema}>
      {titulo}
    </S.Title>
  );
}

export default PageTitle;
