import api from '~/services/api';

function getEstatisticasAcessosVisao(exercicio) {
  return api.get(`/estatistica/grafico/visao/portal/${exercicio}`); // DTO para o Gráfico de Pie
}

function getEstatisticasAcessoAno() {
  return api.get('/estatistica/grafico/ano/portal'); // DTO para o Gráfico de Line
}

function getEstatisticasAnosComDados() {
  return api.get('/estatistica/anos'); // DTO para o select de apenas Exercícios com dados.
}

export {
  getEstatisticasAcessosVisao,
  getEstatisticasAcessoAno,
  getEstatisticasAnosComDados,
};
