function addRequest() {
  return {
    type: '@loader/ADD_REQUEST',
  };
}

function removeRequest() {
  return {
    type: '@loader/REMOVE_REQUEST',
  };
}

export { addRequest, removeRequest };
