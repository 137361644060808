import React, { createContext, useContext, useState } from 'react';

export const UrlContext = createContext();

export function UrlProvider({ children }) {
  const [urls, setUrls] = useState([]);

  function empilhaUrl(url) {
    setUrls(u => [...u, url]);
  }

  function obterUrlPaginaAnterior() {
    const valorDefault = {
      urlCompleta: null,
      urlBase: null,
      queryParam: null,
    };

    if (!urls?.length) {
      return valorDefault;
    }

    const urlAnterior = String(urls[urls.length - 1]);

    if (urlAnterior) {
      const novasUrls = urls.filter(item => item !== urlAnterior);

      setUrls(novasUrls);

      const [urlBase, queryParam] = urlAnterior.split('?');

      return {
        urlCompleta: urlAnterior,
        urlBase,
        queryParam,
      };
    }

    return valorDefault;
  }

  return (
    <UrlContext.Provider
      value={{
        urls,
        empilhaUrl,
        obterUrlPaginaAnterior,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
}

export function useUrls() {
  const { urls, empilhaUrl, obterUrlPaginaAnterior } = useContext(UrlContext);

  return {
    urls,
    empilhaUrl,
    obterUrlPaginaAnterior,
  };
}
