import { BackTop as BackTopAntd } from 'antd';
import styled from 'styled-components';

export const BackTop = styled(BackTopAntd)`
  right: 70px;
  bottom: 40px;
`;

export const BackTopStyle = styled.div`
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50px;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  color: ${({ color }) => `${color}`};
  text-align: center;
  font-size: 14px;
`;