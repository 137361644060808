import React from 'react';
import { useSelector } from 'react-redux';
import Icons from '../Icons/Icons.component';

function Collapse({ collapsed, handleToggle }) {
  const corFonteTituloPortal = useSelector(
    state => state.configuration.corFonteTituloPortal
  );

  return collapsed ? (
    <Icons.MenuUnfoldOutlined
      tabIndex={0}
      style={{ fontSize: 18, padding: 32, color: corFonteTituloPortal }}
      onKeyDown={event => {
        if (event.keyCode === 13) handleToggle();
      }}
      onClick={handleToggle}
      className="collapseButton"
      collapsed={collapsed}
    />
  ) : (
    <Icons.MenuFoldOutlined
      tabIndex={0}
      style={{ fontSize: 18, padding: 32, color: corFonteTituloPortal }}
      onKeyDown={event => {
        if (event.keyCode === 13) handleToggle();
      }}
      className="collapseButton"
      collapsed={collapsed}
      onClick={handleToggle}
    />
  );
}

export default Collapse;
