import api from '~/services/api';

function getPeriodoDetalheVisaoAnaliticaService(
  exercicio,
  periodicidade,
  periodo
) {
  let params = {};
  if (periodo) params.periodo = periodo;

  return api.get(
    `detalhevisaoanalitica/periodos/${exercicio}/${periodicidade}`,
    { params }
  );
}

function getDetalheVisaoAnaliticaService(detalheVisaoAnalitica) {
  return api.post('detalhevisaoanalitica/filter', detalheVisaoAnalitica);
}

function exportarDetalheVisao(tipoExportacao, data) {
  return api.post(`detalhevisaoanalitica/exportar/${tipoExportacao}`, data);
}

export {
  getDetalheVisaoAnaliticaService,
  getPeriodoDetalheVisaoAnaliticaService,
  exportarDetalheVisao,
};
