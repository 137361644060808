import styled from 'styled-components';
import { Layout, Col } from 'antd';

export const Footer = styled(Layout.Footer)`
  && {
    text-align: center;
  }
`;

export const MapButtonWrapper = styled(Col)`
  margin-top: 5px;
`;
