import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  padding: 20px;
  background: ${({ fundoTransparente }) =>
    fundoTransparente ? 'transparent' : 'rgba(255, 255, 255, 0.9)'};
`;

export const Content = styled.div`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
`;