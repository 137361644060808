/* eslint-disable no-param-reassign */
import produce from 'immer';

export const MIN_ZOOM_FONTE = -5;
export const MAX_ZOOM_FONTE = 5;

const INITIAL_STATE = {
  titulo: '',
  corFonteTituloPortal: '#000',
  corFonte: '#fff',
  corFundoGrid: '#fff',
  corFonteGrid: '#fff',
  corFonteRodape: '#000',
  corTema: '',

  brasao: '',
  brasaoPB: '',
  banner: '',
  bgImageHome: '',
  bgImageTelas: '',

  RazaoSocial: '',
  endereco: '',
  bairro: '',
  cidade: '',
  cep: '',
  telefone: '',
  atendimento: '',

  EmailSocials: '',
  FacebookSocials: '',
  InstagramSocials: '',
  LinkedInSocials: '',
  TwitterSocials: '',
  WhatsAppSocials: '',
  YoutubeSocials: '',

  zoomFonte: 0,
  highContrast: false,

  graficHighContrastStyle: {
    fill: 'yellow',
    shadowColor: 'yellow',
    stroke: 'yellow',
    shadowBlur: 0,
    lineWidth: 0.3,
    strokeOpacity: 0,
  },
  graficDefaultStyle: {
    fill: 'black',
    shadowColor: 'black',
    stroke: 'black',
    shadowBlur: 0,
    lineWidth: 0.3,
    strokeOpacity: 0,
  },
};

function configurationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@configuration/TOGGLE_HIGHCONTRAST':
      return produce(state, draft => {
        draft.highContrast = !state.highContrast;
      });

    case '@configuration/SET_CONFIGURATION':
      return produce(state, draft => {
        draft.titulo = action.payload.Titulo;
        draft.corFonteTituloPortal = action.payload.CorFonteTituloPortal;
        draft.corFonteTituloTelas = action.payload.CorFonteTituloTelas;
        draft.corFonteRodape = action.payload.CorFonteRodape;
        draft.corTema = action.payload.CorTema;
        draft.corFonte = action.payload.CorFonte;
        draft.corFundoGrid = action.payload.CorFundoGrid;
        draft.corFundoTituloGrid = action.payload.CorFundoTituloGrid;
        draft.corTituloGrid = action.payload.CorTituloGrid;

        draft.brasao = action.payload.BrasaoUrl;
        draft.brasaoPB = action.payload.BrasaoPretoBrancoUrl;
        draft.banner = action.payload.BannerUrl;
        draft.bgImageHome = action.payload.ImagemFundoHomeUrl;
        draft.bgImageTelas = action.payload.ImagemFundoTelasUrl;

        draft.RazaoSocial = action.payload.RazaoSocial;
        draft.endereco = action.payload.Endereco;
        draft.bairro = action.payload.Bairro;
        draft.cidade = action.payload.Cidade;
        draft.cep = action.payload.CEP;
        draft.telefone = action.payload.Telefone;
        draft.atendimento = action.payload.Atendimento;
        draft.graficHighContrastStyle = INITIAL_STATE.graficHighContrastStyle;
        draft.graficDefaultStyle = INITIAL_STATE.graficDefaultStyle;
        draft.EmailSocials = action.payload.EmailSocials;
        draft.FacebookSocials = action.payload.FacebookSocials;
        draft.InstagramSocials = action.payload.InstagramSocials;
        draft.LinkedInSocials = action.payload.LinkedInSocials;
        draft.TwitterSocials = action.payload.TwitterSocials;
        draft.WhatsAppSocials = action.payload.WhatsAppSocials;
        draft.YoutubeSocials = action.payload.YoutubeSocials;
      });

    case '@configuration/DECREASE_FONTSIZE_PAGE':
      return produce(state, draft => {
        if (draft.zoomFonte > MIN_ZOOM_FONTE) {
          draft.zoomFonte -= 1;
        }
      });

    case '@configuration/INCREASE_FONTSIZE_PAGE':
      return produce(state, draft => {
        if (draft.zoomFonte < MAX_ZOOM_FONTE) {
          draft.zoomFonte += 1;
        }
      });

    case '@configuration/RESET_FONTSIZE_PAGE':
      return produce(state, draft => {
        draft.zoomFonte = INITIAL_STATE.zoomFonte;
      });

    default:
      return state;
  }
}

export default configurationReducer;
