import api from '~/services/api';

function getEstruturaOrganizacionalConfiguracaoService() {
  return api.get(`estruturaOrganizacional`);
}

function getEstruturaOrganizacionalDetalheService(id) {
  return api.get(`estruturaOrganizacional/${id}/detalhe`);
}

export {
  getEstruturaOrganizacionalConfiguracaoService,
  getEstruturaOrganizacionalDetalheService,
};
