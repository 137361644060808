import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  Col,
  RangePickerComponent,
  FormV3 as Form,
  Input,
  InputNumber,
  Select,
} from '~/components';

import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';
import { TIPO_FILTRO } from '~/modules/Helper/tipoFiltro';
import transformaDataParaAmericano from '../../../Helper/transformaDataParaAmericano-helper';

import * as S from './FiltrosAvancados.styled';

function FiltrosAccordion({ filtros, form, paramsFiltros, handlePesquisa }) {
  const { getFieldDecorator, setFieldsValue } = form;
  const [filtrosTransformados, setFiltrosTransformados] = useState([]);

  function fazerPesquisaParams(){
    if (paramsFiltros.length > 0) {
      const objetoForm = {};

      paramsFiltros.forEach(element => {
        objetoForm[`${element.Campo}`] = {
          Campo: element.Campo,
          Valor: element.Valor,
          TipoValor: element.TipoValor,
        };

        setFieldsValue(objetoForm);
      });

      handlePesquisa();
    }
  }

  async function setValoresDoInputComoObjeto(coluna, event, isParam) {
    let valor = event;

    let dataFormatada;

    if (coluna.TipoValor === TIPO_COLUNA.DATA) {
      if (valor[0] !== '' && valor[1] !== '') {
        valor = transformaDataParaAmericano(valor);
        dataFormatada = `${valor[0]},${valor[1]}`;
      }
    }

    if (
      coluna.TipoFiltro === TIPO_FILTRO.TEXTO &&
      coluna.TipoValor !== TIPO_COLUNA.DATA &&
      !isParam
    ) {
      valor = valor.target.value;

      if (coluna.TipoValor === TIPO_COLUNA.DECIMAL || TIPO_COLUNA.INTEIRO) {
        valor = valor.replace(',', '.');
      }
    }

    const objetoForm = {};

    objetoForm[`${coluna.FonteDados}`] = {
      Campo: coluna.FonteDados,
      Valor: coluna.TipoValor === TIPO_COLUNA.DATA ? dataFormatada : valor,
      TipoValor: coluna.TipoValor,
    };

    if (coluna.TipoValor === TIPO_COLUNA.DATA && dataFormatada === undefined) {
      objetoForm[`${coluna.FonteDados}`] = undefined;
    }

    if (coluna.TipoValor === TIPO_FILTRO.OPCOES && valor === '') {
      objetoForm[`${coluna.FonteDados}`] = undefined;
    }

    await setFieldsValue(objetoForm);
  }

  function renderizaInputTexto(coluna) {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <Form.Item
          label={coluna.NomePadrao}
          name={coluna.NomePadrao}
          colon={false}
          onChange={event => {
            setValoresDoInputComoObjeto(coluna, event);
          }}
        >
          <Tooltip title={coluna.Tooltip}>
            {getFieldDecorator(coluna.FonteDados)(
              <Input placeholder={coluna.NomePadrao} />
            )}
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  function renderizaInputNumero(coluna) {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <Form.Item
          label={coluna.NomePadrao}
          name={coluna.NomePadrao}
          colon={false}
          onChange={event => {
            setValoresDoInputComoObjeto(coluna, event);
          }}
        >
          <Tooltip title={coluna.Tooltip}>
            {getFieldDecorator(coluna.FonteDados)(
              <InputNumber
                style={{ width: '100%' }}
                placeholder={coluna.NomePadrao}
                keyboard={false}
                decimalSeparator=","
              />
            )}
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  function renderizaInputData(coluna) {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <Form.Item
          label={coluna.NomePadrao}
          name={coluna.NomePadrao}
          colon={false}
        >
          <Tooltip title={coluna.Tooltip}>
            {getFieldDecorator(coluna.FonteDados)(
              <RangePickerComponent
                onCalendarChange={(event, dateString) => {
                  setValoresDoInputComoObjeto(coluna, dateString);
                }}
              />
            )}
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  function renderizaSelect(coluna) {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <Form.Item
          label={coluna.NomePadrao}
          name={coluna.NomePadrao}
          colon={false}
        >
          <Tooltip title={coluna.Tooltip}>
            {getFieldDecorator(coluna.FonteDados)(
              <Select
                showSearch
                optionFilterProp="children"
                onSelect={value => {
                  setValoresDoInputComoObjeto(coluna, value);
                }}
                placeholder={coluna.NomePadrao}
              >
                <Select.Option value="">Nenhum Selecionado</Select.Option>
                {coluna.ColunaValores?.map(valoresSelect => (
                  <Select.Option value={valoresSelect.Valor}>
                    {valoresSelect.Valor}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  function renderizaSelectBooleano(coluna) {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <Form.Item
          label={coluna.NomePadrao}
          name={coluna.NomePadrao}
          colon={false}
        >
          <Tooltip title={coluna.Tooltip}>
            {getFieldDecorator(coluna.FonteDados)(
              <Select
                showSearch
                optionFilterProp="children"
                onSelect={value => {
                  setValoresDoInputComoObjeto(coluna, value);
                }}
                placeholder={coluna.NomePadrao}
              >
                <Select.Option value="">Nenhum Selecionado</Select.Option>
                <Select.Option value={1}>Sim</Select.Option>
                <Select.Option value={0}>Não</Select.Option>
              </Select>
            )}
          </Tooltip>
        </Form.Item>
      </Col>
    );
  }

  function transformaColunaEmInput(filtrosModulo) {
    setFiltrosTransformados([]);
    filtrosModulo.Grupos.forEach(grupo => {
      setFiltrosTransformados(arrayFiltros => [
        ...arrayFiltros,
        <S.StyledDivider
          style={{ marginTop: '0px', marginBottom: '10px' }}
          orientation="left"
          plain
        >
          <b>{grupo.Nome}</b>
        </S.StyledDivider>,
      ]);

      grupo.Colunas.forEach(coluna => {
        if (
          coluna.TipoFiltro === TIPO_FILTRO.TEXTO &&
          coluna.TipoValor !== TIPO_COLUNA.DATA &&
          coluna.TipoValor !== TIPO_COLUNA.INTEIRO &&
          coluna.TipoValor !== TIPO_COLUNA.DECIMAL
        ) {
          setFiltrosTransformados(arrayFiltros => [
            ...arrayFiltros,
            renderizaInputTexto(coluna),
          ]);
        } else if (
          (coluna.TipoFiltro === TIPO_FILTRO.TEXTO &&
            coluna.TipoValor === TIPO_COLUNA.INTEIRO) ||
          coluna.TipoValor === TIPO_COLUNA.DECIMAL
        ) {
          setFiltrosTransformados(arrayFiltros => [
            ...arrayFiltros,
            renderizaInputNumero(coluna),
          ]);
        }

        if (
          coluna.TipoFiltro === TIPO_FILTRO.TEXTO &&
          coluna.TipoValor === TIPO_COLUNA.DATA
        ) {
          setFiltrosTransformados(arrayFiltros => [
            ...arrayFiltros,
            renderizaInputData(coluna),
          ]);
        }

        if (
          coluna.TipoFiltro === TIPO_FILTRO.OPCOES &&
          coluna.TipoValor === TIPO_COLUNA.BOOLEANO
        ) {
          setFiltrosTransformados(arrayFiltros => [
            ...arrayFiltros,
            renderizaSelectBooleano(coluna),
          ]);
        } else if (coluna.TipoFiltro === TIPO_FILTRO.OPCOES) {
          setFiltrosTransformados(arrayFiltros => [
            ...arrayFiltros,
            renderizaSelect(coluna),
          ]);
        }
      });
    });
  }

  useEffect(() => {
    fazerPesquisaParams();
  }, [paramsFiltros]);

  useEffect(() => {
    if (filtros) transformaColunaEmInput(filtros);
  }, [filtros, paramsFiltros]);

  return <>{filtrosTransformados}</>;
}

export default FiltrosAccordion;
