import React from 'react';
import { Select, Tooltip, Row, Typography, Card } from '~/components';

export default function FiltroExercicio({
  handleChangeExercicio,
  exercicios,
  exercicio,
}) {
  return (
    <Row style={{ justifyContent: 'flex-start', marginTop: '10px' }}>
      <Card
        style={{ width: '100%' }}
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          padding: 12,
        }}
      >
        <Typography.Title
          level={3}
          style={{ fontSize: 18, fontWeight: 500, marginBottom: 0 }}
        >
          Exercício
        </Typography.Title>
        <Tooltip title="Exercício">
          <Select
            value={exercicio}
            defaultValue={exercicio}
            onChange={handleChangeExercicio}
            style={{ width: '80px', marginLeft: '5px' }}
          >
            {exercicios.map(ano => (
              <Select.Option key={ano} Option value={ano}>
                {ano}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </Card>
    </Row>
  );
}
