import React, { useState } from 'react';

const GraficoContext = React.createContext({});

function ListaGraficoContext({ children }) {
  const [listaGrafico, setListaGrafico] = useState([]);
  const [eSic, setESic] = useState(null);
  return (
    <GraficoContext.Provider
      value={{ listaGrafico, setListaGrafico, eSic, setESic }}
    >
      {children}
    </GraficoContext.Provider>
  );
}

export { ListaGraficoContext, GraficoContext };
