import React from 'react';
import { useSelector } from 'react-redux';
import { TreeTransparente } from './styles';
import { Tree, Tooltip, Typography } from '~/components';

function TreeViewEstruturaOrganizacional({ treeView, history }) {

  const { TreeNode } = Tree;
  const { corTema } = useSelector(state => state.configuration);

  const loop = data =>
    data.map(item => {
      if (item.children && item.children.length && item.Habilitado) {
        return (
          <TreeNode
            key={item.key}
            title={
              <>
                <Tooltip title="Acesso" placement="topLeft">
                  <Typography.Text
                    style={{ display: 'block', width: '100%', outline: 0 }}
                  >
                    <span
                      style={{
                        color: corTema,
                        cursor: 'pointer',
                        width: '100%',
                        display: 'block',
                        outline: 0,
                      }}
                      onClick={() => {
                        if (item.Externo) window.open(item.Link);
                        else
                          history.push(
                            `/estrutura_organizacional/detalhe/${item.IdDetalhe}`
                          );
                      }}
                      role="link"
                      tabIndex="0"
                      aria-hidden="true"
                    >
                      {item.title}
                    </span>
                  </Typography.Text>
                </Tooltip>
              </>
            }
          >
            {loop(item.children)}
          </TreeNode>
        );
      }
      if (item.Habilitado) {
        return (
          <TreeNode
            key={item.key}
            isLeaf
            title={
              <>
                <Tooltip title="Acesso" placement="topLeft">
                  <Typography.Text
                    style={{ display: 'block', width: '100%', outline: 0 }}
                  >
                    <span
                      style={{
                        color: corTema,
                        cursor: 'pointer',
                        width: '100%',
                        display: 'block',
                        outline: 0,
                      }}
                      onClick={() => {
                        if (item.Externo) window.open(item.Link);
                        else
                          history.push(
                            `/estrutura_organizacional/detalhe/${item.IdDetalhe}`
                          );
                      }}
                      role="link"
                      tabIndex="0"
                      aria-hidden="true"
                    >
                      {item.title}
                    </span>
                  </Typography.Text>
                </Tooltip>
              </>
            }
          />
        );
      }
      return null;
    });

  return (
    <TreeTransparente
      className="draggable-tree"
      draggable={false}
      defaultExpandAll={false}
      blockNode
      autoExpandParent={false}
      selectable
    >
      {loop(treeView)}
    </TreeTransparente>
  );
}

export default TreeViewEstruturaOrganizacional;
