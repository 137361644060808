import styled from 'styled-components';
import { Button, Card } from '~/components';

export const ContainerButtons = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 1em 0 1em 0.35em;
  z-index: 1;
  margin-bottom: 1em;

  ${Button} {
    width: 2.8em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.35em 0.35em 0;
    padding: 1.2em;

    @media (max-width: 1440px) {
      width: 2.5em;
      height: 2.5em;
      font-size: 12px;
    }

    @media (max-width: 815px) {
      font-size: 10px;
    }

    @media (max-width: 768px) {
      font-size: 9px;
    }

    @media (max-width: 450px) {
      font-size: 8px;
    }

    @media (max-width: 313px) {
      font-size: 7px;
    }
  }
`;
