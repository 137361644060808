import styled from 'styled-components';
import Button from '~/components/Button/Button.component';
import Card from '~/components/Card/Card.component';
import Col from '~/components/Col/Col.component';
import {
  calcularAlturaLargura,
  calcularAlturaLarguraIconeMedio,
} from '~/modules/Helper/calcularAlturaLargura';
import {
  tamanhoFonteDescricaoGrafico,
  tamanhoFonteDescricaoDestaqueMedio,
  tamanhoFonteIconeMedio,
  tamanhoFonteDestaqueMedio,
} from '~/modules/Helper/calcularTamanhoFonte';
import media from '../../modules/Dashboard/components/mediaStyle';

export const FilterButton = styled(Button)`
  width: 35px !important;
  height: 30px !important;
  cursor: pointer;
  background-color: #efefef !important;
  display: block;
  float: right;
  opacity: 0.5;
`;

export const CardWrapper = styled(Card)`
  height: 100% !important;

  .ant-card-body {
    padding: 0 10px;
    height: calc(100% - 50px) !important;
    background-color: transparent !important;
    align-content: center;
  }

  .ant-card-body > div:nth-child(2),
  .ant-card-body > div:nth-child(2) > div,
  .ant-card-body > div:nth-child(2) > div > div,
  canvas {
    height: 100% !important;
    width: 100% !important;
  }

  .ant-card-extra {
    display: flex;
  }

  .ant-select {
    margin-right: 15px;
  }

  &:hover ${FilterButton} {
    opacity: 1;
    transition: 300ms;
  }
`;

export const TituloSpan = styled.span`
  margin-bottom: 20px;
  font-size: 25px;
`;

export const Visualizar = styled.div`
  margin-top: 5px;
  cursor: pointer;
  font-size: 18px;
`;

export const DescricaoLinkExternoDiv = styled.div`
  margin: 15px;
  font-size: 1.8rem;
`;

export const DescricaoLinkExternoDivMedio = styled.div`
  font-size: 1.8rem;
  margin-top: 16%;
`;

export const IconeDiv = styled.div`
  text-align: center;
  font-size: 55px;
  margin-right: 20px;

  ${media.Mobile`
    float: 'left'
  `};
`;

export const StatisticSpan = styled.div`
  ${media.Desktop`
    position: relative;
    top: -5px;
  `}

  ${media.Mobile`
    position: relative;
    top: -5px;
  `}
`;

export const StyledCol = styled(Col)`
  background-color: transparent !important;
`;

export const StatisticSpanMedio = styled.div`
  background-color: transparent !important;

  ${media.Desktop`
    position: relative;
    top: 15px;
    padding-left: 15px;
  `} ${media.Mobile`
    position: relative;
    top: 0px;
  `};
`;

export const ValorDescricaoSpan = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

export const ValorDestaqueDiv = styled.div`
  font-size: 25px;
`;

export const ValorSecundarioSpan = styled.span`
  font-size: 15px;

  span {
    font-weight: 700;
  }
`;

export const Grafico = styled.span`
  display: table;
  margin: auto;
`;

export const IconeMedioDiv = styled.div`
  ${media.Desktop`
    font-size: ${({ tamanho }) => tamanhoFonteIconeMedio(tamanho)};
    margin-top: 15px;
  `}

  ${media.Mobile`
    text-align: center;
    font-size: 65px;
  `}
`;

export const IconeMedioImg = styled.img`
  ${media.Desktop`
    height: ${({ tamanho }) => calcularAlturaLarguraIconeMedio(tamanho)};
    width: ${({ tamanho }) => calcularAlturaLarguraIconeMedio(tamanho)};
  `}

  ${media.Mobile`
    height: 60px;
    width: 60px;
  `}
`;

export const ValorDescricaoDestaqueMedioDiv = styled.div`
  ${media.Desktop`
    margin-top: 15px;
    font-size: ${({ tamanho }) => tamanhoFonteDescricaoDestaqueMedio(tamanho)};
    color: ${({ corFonte }) => `${corFonte}`};
  `}

  ${media.Mobile`
    font-size: 15px;
    font-weight: 500;
  `}
`;

export const ValorDestaqueMedioDiv = styled.span`
  ${media.Desktop`
    font-size: ${({ tamanho }) => tamanhoFonteDestaqueMedio(tamanho)};
    color: ${({ corFonte }) => `${corFonte}`};
    font-weight: 500;
  `}

  ${media.Mobile`
    div{
      font-size: 25px;
    }
  `}
`;

export const ValorSecundarioMedioSpan = styled.span`
  span {
    font-weight: 700;
  }

  ${media.Desktop`
    font-size: ${({ tamanho }) => tamanhoFonteDescricaoDestaqueMedio(tamanho)};
  `}

  ${media.Mobile`
    font-size: 15px;
  `}
`;

export const GraficoMedio = styled.div`
  div.g2plot-htmlStatistic,
  div.g2plot-htmlStatistic > div.ring-guide-html,
  div.g2-html-annotation {
    background-color: transparent !important;
  }

  font-size: 25px;
  height: ${({ tamanho }) => calcularAlturaLargura(tamanho)};
  width: ${({ tamanho }) => calcularAlturaLargura(tamanho)};

  div.g2plot-htmlStatistic > div.ring-guide-html > span.ring-guide-name {
    position: relative;
    top: 5px;
  }

  div.g2plot-htmlStatistic > div.ring-guide-html > span.ring-guide-value {
    font-size: 1.3rem !important;
  }

  ${media.Mobile`
      display: table;
      margin: auto;
      width: 125px;
      height: 125px;

      div.g2plot-htmlStatistic > div.ring-guide-html > span.ring-guide-name{
        position: relative;
        top: 5px
      }

      div.g2plot-htmlStatistic > div.ring-guide-html > span.ring-guide-value{
        font-size: 1.3rem !important;
      }
  `}
`;

export const DescricaoGrafico = styled.p`
  font-size: ${({ tamanho }) => tamanhoFonteDescricaoGrafico(tamanho)};
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

export const GraficoDonut = styled.div`
  padding: 0.625em 0;
  ${media.Mobile``}
`;

export const GraficoPie = styled.div`
  padding: 0.625em 0;
  ${media.Mobile``}
`;

export const GraficoLine = styled.div`
  ${media.Mobile``}
`;

export const GraficoBar = styled.div`
  ${media.Mobile``}
`;

export const GraficoColumn = styled.div`
  ${media.Mobile``}
`;
