import React from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Tooltip,
  Button,
  Icons,
} from '~/components';

import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';

const TotalEntidadeESic = 3;

function CardInfo({ dados }) {
  return (
    <Col style={{ minHeight: 70 }}>
      {dados.map(informacoes => (
        <Typography.Text key={informacoes} style={{ display: 'block' }}>
          {informacoes}
        </Typography.Text>
      ))}
    </Col>
  );
}

function Arquivo({ form }) {
  return (
    <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
      <Col lg={20}>
        <Typography.Text style={{ display: 'block', padding: '0 1.25em' }}>
          {form.Descricao}
        </Typography.Text>
      </Col>
      <Col>
        <Tooltip title="Download">
          <Button
            key={form.Url}
            onClick={() => {
              fileManagerPaiDownload(form.Url);
            }}
            type="primary"
            size="small"
            icon={<Icons.DownloadOutlined />}
          />
        </Tooltip>
      </Col>
    </Row>
  );
}

function showFormulario(formularios) {
  return (
    <>
      <Divider orientation="center" plain style={{ fontWeight: '600' }}>
        Formulários SIC
      </Divider>
      {formularios.map(form =>
        form.Url ? <Arquivo key={form.Descricao} form={form} /> : null
      )}
    </>
  );
}

function EsicCard({ dados, formularios }) {
  const cards = dados; // dados.filter(card => card.Dados.length > 0);

  return cards.map(card => {
    const formularioentidade = formularios.find(
      forms => forms.Entidade === card.Entidade
    );

    return (
      <Col
        key={card.Entidade}
        flex={cards.length / TotalEntidadeESic}
        style={{ marginBottom: 10 }}
      >
        <Card
          title={<strong>{card.Entidade}</strong>}
          size="small"
          style={{
            minHeight: 248,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }}
        >
          <CardInfo dados={card.Dados} />
          {formularioentidade && showFormulario(formularioentidade.Fomulario)}
        </Card>
      </Col>
    );
  });
}

function ESicVisoes({ dados, formularios }) {
  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <EsicCard dados={dados} formularios={formularios} />
      </Row>
    </>
  );
}

export default ESicVisoes;