import styled from 'styled-components';
import { Button as AntDesignButton } from 'antd';

export const ButtonGoBack = styled(AntDesignButton)`
float: left;
margin: 10px 20px 0 0;
  && {
    margin-left: ${({ ml }) => ml}px;
    margin-right: ${({ mr }) => mr}px;
    color: ${({ color }) => color};
  }
`;
