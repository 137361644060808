import React from 'react';
import { useHistory } from 'react-router-dom';
import { TIPO_ACESSO } from '~/modules/Helper/tipoAcesso';
import { Container, Imagem } from './style';

function CardImagemDashboard({ urlImagem, valorLink, tipoAcesso }) {
  const history = useHistory();

  function Redirecionar(link) {
    if (tipoAcesso === TIPO_ACESSO.INTERNO) return history.push(link);

    window.open(link, '_blank');
  }

  return (
    <Container onClick={() => Redirecionar(valorLink)}>
      <Imagem src={urlImagem} alt="Imagem Card" />
    </Container>
  );
}

export default CardImagemDashboard;
