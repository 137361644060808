import React from 'react';
import { Container, Content } from './style';

function InformacaoDashboard({ informacao, fundoTransparente }) {
  return (
    <>
      <Container fundoTransparente={fundoTransparente}>
        <Content dangerouslySetInnerHTML={{ __html: informacao }} />
      </Container>
    </>
  );
}

export default InformacaoDashboard;
