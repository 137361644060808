import styled from 'styled-components';
import { Button } from '~/components';

export const NextPrevButton = styled.div`
  font-size: ${({ theme }) => `${20 + 2 * theme.zoomFonte}px`};
  border-radius: 3px;
  cursor: pointer;
  padding: 8px;
  :hover {
    background-color: #dedede;
    transition: 0.2s;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const ButtonArquivoDigital = styled(Button)`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
  padding-left: 0px;
  word-wrap: anywhere;
  white-space: normal;
  text-align: left;
`;
