import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Col, Row, Icons, Typography, Divider } from '~/components';
import { NextPrevButton } from './style';

function PeriodicidadeNavigation({
  previous,
  previousFunction,
  next,
  nextFunction,
  current,
}) {
  const corTema = useSelector(state => state.configuration.corTema);
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <>
      {isMobile ? (
        <>
          <Row>
            <Col xs={12}>
              <NextPrevButton
                onClick={previousFunction}
                style={{ float: 'left', color: corTema }}
              >
                <Icons.LeftOutlined />
                {` ${previous} `}
              </NextPrevButton>
            </Col>

            <Col xs={12}>
              <NextPrevButton
                onClick={nextFunction}
                style={{ float: 'right', color: corTema }}
              >
                {`${next} `}
                <Icons.RightOutlined />
              </NextPrevButton>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Typography.Title
                level={4}
                style={{ textAlign: 'center', marginTop: '1%' }}
              >
                {`${current}`}
              </Typography.Title>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs={8}>
              <NextPrevButton
                onClick={previousFunction}
                style={{ float: 'left', color: corTema }}
              >
                <Icons.LeftOutlined />
                {` ${previous} `}
              </NextPrevButton>
            </Col>
            <Col xs={8}>
              <Typography.Title
                level={4}
                style={{ textAlign: 'center', marginTop: '1%' }}
              >
                {`${current}`}
              </Typography.Title>
            </Col>
            <Col xs={8}>
              <NextPrevButton
                onClick={nextFunction}
                style={{ float: 'right', color: corTema }}
              >
                {`${next} `}
                <Icons.RightOutlined />
              </NextPrevButton>
            </Col>
          </Row>
        </>
      )}

      <Divider style={{ background: '#d0d0d0' }} />
    </>
  );
}

export default PeriodicidadeNavigation;
