import styled from 'styled-components';
import Button from '../Button/Button.component';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Button} {
    padding: 2px;
    margin-left: 8px;
  }
`;
