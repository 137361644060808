import styled from 'styled-components';
import { Typography as AntDesignTypography } from 'antd';

export const Text = styled(AntDesignTypography.Text)`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
`;

export const Link = styled(AntDesignTypography.Link)``;

export const Title = styled(AntDesignTypography.Title)`
  &.ant-typography {
    font-size: ${({ theme, level }) => {
      let originalFontSize = 0;
      switch (level) {
        case 1:
          originalFontSize = 38;
          break;

        case 2:
          originalFontSize = 30;
          break;

        case 3:
          originalFontSize = 24;
          break;

        case 4:
          originalFontSize = 20;
          break;

        case 5:
          originalFontSize = 16;
          break;

        default:
          originalFontSize = 38;
      }

      return `${originalFontSize + 2 * theme.zoomFonte}px`;
    }};
  }
`;

export const Paragraph = styled(AntDesignTypography.Paragraph)`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
`;

export const Typography = {
  Text,
  Link,
  Title,
  Paragraph,
};
