import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import * as S from './Breadcrumb.styled';
import { useBreadcrumb } from '~/hooks/useBreadcrumb';

function Breadcrumb({ master, match }) {
  const { params, path } = match;

  const { obterBreadcrumbPorUri } = useBreadcrumb();

  const breadcrumb = obterBreadcrumbPorUri(path, params);

  function getParameterUrl(uri) {
    const keys = Object.keys(params);

    if (keys.length) {
      let newPath = uri;

      keys.forEach(key => {
        const value = params[key];
        const regex = new RegExp(`:${key}`);
        newPath = uri.replace(regex, value);
      });

      return newPath;
    }
    return uri;
  }

  if (!breadcrumb?.length) return null;

  return (
    <S.Breadcrumb>
      <S.Breadcrumb.Item>
        <Link to={master}>
          <HomeOutlined />
        </Link>
      </S.Breadcrumb.Item>

      {breadcrumb.map(bc =>
        bc.path ? (
          <S.Breadcrumb.Item key={bc.name}>
            <Link to={getParameterUrl(bc.path)}>{bc.name}</Link>
          </S.Breadcrumb.Item>
        ) : (
          <S.Breadcrumb.Item key={bc.name}>{bc.name}</S.Breadcrumb.Item>
        )
      )}
    </S.Breadcrumb>
  );
}

export default Breadcrumb;

Breadcrumb.defaultProps = {
  breadcrumb: [],
};
