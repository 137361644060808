import styled from 'styled-components';
import { Card as AntDesignCard } from 'antd';

export const Card = styled(AntDesignCard)`
  && {
    background: rgba(255, 255, 255, 0.9);

    .ant-card-head-title,
    .ant-card-body {
      font-size: ${({ theme }) => `${16 + 2 * theme.zoomFonte}px`};
    }
  }
`;
