import React from 'react';
import Icons from '../../components/Icons/Icons.component';

export const iconEnum = [
  {
    name: 'question-circle',
    value: style => <Icons.QuestionCircleOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'question',
    value: style => <Icons.QuestionOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'clock-circle',
    value: style => <Icons.ClockCircleOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'exclamation',
    value: style => <Icons.ExclamationOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'message',
    value: style => <Icons.MessageOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'bulb',
    value: style => <Icons.BulbOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'bank',
    value: style => <Icons.BankOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'calendar',
    value: style => <Icons.CalendarOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'file-text',
    value: style => <Icons.FileTextOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'file-done',
    value: style => <Icons.FileDoneOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'flag',
    value: style => <Icons.FlagOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'home',
    value: style => <Icons.HomeOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'mail',
    value: style => <Icons.MailOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'setting',
    value: style => <Icons.SettingOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'schedule',
    value: style => <Icons.ScheduleOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'smile',
    value: style => <Icons.SmileOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'tags',
    value: style => <Icons.TagsOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'wallet',
    value: style => <Icons.WalletOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'dollar',
    value: style => <Icons.DollarOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'link',
    value: style => <Icons.LinkOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'shopping-cart',
    value: style => <Icons.ShoppingCartOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'shopping',
    value: style => <Icons.ShoppingOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'team',
    value: style => <Icons.TeamOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'check',
    value: style => <Icons.CheckOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'close',
    value: style => <Icons.CloseOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'warning',
    value: style => <Icons.WarningOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'stop',
    value: style => <Icons.StopOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'delete',
    value: style => <Icons.DeleteOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'area-chart',
    value: style => <Icons.AreaChartOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'alert',
    value: style => <Icons.AlertOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'api',
    value: style => <Icons.ApiOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'cloud',
    value: style => <Icons.CloudOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'contacts',
    value: style => <Icons.ContactsOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'container',
    value: style => <Icons.ContainerOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'idcard',
    value: style => <Icons.IdcardOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'global',
    value: style => <Icons.GlobalOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'safety',
    value: style => <Icons.SafetyOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'notification',
    value: style => <Icons.NotificationOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'environment',
    value: style => <Icons.EnvironmentOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'ordered-list',
    value: style => <Icons.OrderedListOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'snippets',
    value: style => <Icons.SnippetsOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'control',
    value: style => <Icons.ControlOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'compass',
    value: style => <Icons.CompassOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'dashboard',
    value: style => <Icons.DashboardOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'api-requests',
    value: style => <Icons.ClusterOutlined style={style} />,
    filled: 'outlined',
  },
  {
    name: 'project',
    value: style => <Icons.ProjectOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'profile',
    value: style => <Icons.ProfileOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'info-circle',
    value: style => <Icons.InfoCircleOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'apartment',
    value: style => <Icons.ApartmentOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'calculator',
    value: style => <Icons.CalculatorOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'file-search',
    value: style => <Icons.FileSearchOutlined style={style} />,
    outlined: 'outlined',
  },
  {
    name: 'info-circle-filled',
    value: style => <Icons.InfoCircleFilled style={style} />,
    filled: 'filled',
  },
  {
    name: 'youtube',
    value: style => <Icons.YoutubeFilled style={style} />,
    filled: 'filled',
  },
  {
    name: 'file-pdf',
    value: style => <Icons.FilePdfOutlined style={style} />,
    filled: 'filled',
  },
  {
    name: 'file-img',
    value: style => <Icons.FileImageOutlined style={style} />,
    filled: 'filled',
  },
  {
    name: 'file-excel',
    value: style => <Icons.FileExcelOutlined style={style} />,
    filled: 'filled',
  },
  {
    name: 'line-chart',
    value: style => <Icons.LineChartOutlined style={style} />,
    filled: 'outlined',
  },
  {
    name: 'folder-open',
    value: style => <Icons.FolderOpenOutlined  style={style} />,
    filled: 'outlined',
  },
];
