import React from 'react';
import * as S from './Table.styled';
import { useSelector } from 'react-redux';

export default function Table(props) {
  const theme = useSelector(state => state.configuration);

  return (
    <S.TableResize theme={theme}>
      <S.Table {...props} />
    </S.TableResize>
  );
}
