import apiFM from '../apiFM';

const paiBaseService = '/filemanager/pai';

export async function fileManagerPaiDownload(fileName) {
  const url = `${apiFM.defaults.baseURL}${paiBaseService}/download?nomeArquivo=${fileName}`;
  window.location.assign(url);
}

export function getFullUrl(nomeArquivo) {
  return `${apiFM.defaults.baseURL}${paiBaseService}/download?nomeArquivo=${nomeArquivo}`;
}
