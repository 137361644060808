import React from 'react';
import styled from 'styled-components';
import { Breadcrumb as AntDesignBreadcrumb, Icon as AntDesignIcon } from 'antd';

export const Breadcrumb = styled(AntDesignBreadcrumb)`
  && {
    padding: 8px 0px;
    height: 50px;
  }
  a {
    text-decoration-line: underline;
  }
`;

export const BreadcrumbItem = styled(AntDesignBreadcrumb.Item)``;

export const Icon = styled(props => <AntDesignIcon {...props} />)``;
