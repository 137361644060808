import React from 'react';

import { Button, Tooltip, Icons } from '~/components';

const ButtonDownloadFileManager = ({ onClick }) => (
  <Tooltip title="Download">
    <Button
      shape="circle"
      onClick={onClick}
      type="primary"
      icon={<Icons.DownloadOutlined />}
      style={{ margin: '9px' }}
    />
  </Tooltip>
);

export default ButtonDownloadFileManager;
