export function parseToQueryString(data) {
  if (!data) return '';

  const urlSearchParams = new URLSearchParams();

  Object.keys(data).forEach(key => {
    // remove props null ou undefined
    if (data[key] === null || data[key] === undefined) {
    } else if (Array.isArray(data[key])) {
      data[key].forEach(item => urlSearchParams.append(key, item));
    } else {
      urlSearchParams.append(key, data[key]);
    }
  });

  const queryString = urlSearchParams.toString();
  return queryString;
}
