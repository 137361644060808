/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { createBrowserHistory } from 'history';
import {
  Col,
  Row,
  Card,
  Typography,
  Exportacao,
  ButtonGoBack,
} from '~/components';
import {
  getDetalheVisaoAnaliticaService,
  getPeriodoDetalheVisaoAnaliticaService,
  exportarDetalheVisao,
} from '../services/detalheVisaoAnalitica.service';
import { useUrls } from '~/contexts/url.context';

import PeriodicidadeNavigation from '../components/periodicidadeNavigation.component';
import Tabela from '../components/Tabela.component';
import ListaAnexos from '../components/listaAnexos.component';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import { UltimaAtualizacao } from '~/modules/Modulos/components/Grid/UltimaAtualizacao.component';
import { PARAMS_FILTERS_DETALHE } from '~/modules/Helper/searchParamsFilters';
import { getAndFormatSearchParams } from '~/modules/Helper/searchString-helper';
import { getUrlComDominio } from '~/modules/Helper/getUrl-helper';
import {
  obterConfiguracoes,
  obterConfiguracoesGerais,
} from '~/modules/Modulos/services/moduloVisao.service';

function DetalheVisaoAnalitica({ match, history }) {
  const [detalheVisaoAnalitica, setDetalheVisaoAnalitica] = useState({
    Titulo: undefined,
    Descricao: undefined,
    Dados: undefined,
    Anexos: [],
  });

  const { obterUrlPaginaAnterior } = useUrls();

  const [periodoAnterior, setPeriodoAnterior] = useState(null);
  const [periodoPosterior, setPeriodoPosterior] = useState(null);
  const [periodoAtual, setPeriodoAtual] = useState(null);
  const [exercicioAnterior, setExercicioAnterior] = useState(null);
  const [exercicioPosterior, setExercicioPosterior] = useState(null);
  const [exercicioAtual, setExercicioAtual] = useState(null);
  const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState(null);
  const [filtroAnterior, setFiltroAnterior] = useState(null);

  const { modulo, visao } = match.params;

  const [exercicioFilter, setExercicioFilter] = useState(undefined);
  const [periodicidadeFilter, setPeriodicidadeFilter] = useState(undefined);

  // filtros da visão agrupamento
  const [
    colunaFiltroVisaoAgrupamento,
    setColunaFiltroVisaoAgrupamento,
  ] = useState(undefined);
  const [
    valorFiltroVisaoAgrupamento,
    setValorFiltroVisaoAgrupamento,
  ] = useState(undefined);
  const [
    tipoValorFiltroVisaoAgrupamento,
    setTipoValorFiltroVisaoAgrupamento,
  ] = useState(null);

  const [periodoFilter, setPeriodoFilter] = useState(undefined);
  const [chavesPeriodicidade, setChavesPeriodicidade] = useState([]);
  const [configuracao, setConfiguracao] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [quantidadeRegistros, setQuantidadeRegistros] = useState(10);
  const [colunaOrdem, setColunaOrdem] = useState(null);

  const browserHistory = createBrowserHistory();

  const [queryParamsDefault, setQueryParamsDefault] = useState(
    PARAMS_FILTERS_DETALHE
  );

  const corTema = useSelector(state => state.configuration.corTema);

  async function obterDetalheVisao(detalheVisao) {
    try {
      const response = await getDetalheVisaoAnaliticaService(detalheVisao);
      setDetalheVisaoAnalitica(response.data);
    } catch (ex) {
      setDetalheVisaoAnalitica({ ...detalheVisaoAnalitica, Dados: [] });
    }
  }

  async function obterPeriodoDetalheVisaoAnalitica(
    exercicio,
    periodicidade,
    periodo
  ) {
    const responsePeriodo = await getPeriodoDetalheVisaoAnaliticaService(
      exercicio,
      periodicidade,
      periodo
    );

    if (responsePeriodo?.data?.PeriodoAnterior)
      setPeriodoAnterior(responsePeriodo.data.PeriodoAnterior);
    if (responsePeriodo?.data?.PeriodoAtual)
      setPeriodoAtual(responsePeriodo.data.PeriodoAtual);
    if (responsePeriodo?.data?.PeriodoPosterior)
      setPeriodoPosterior(responsePeriodo.data.PeriodoPosterior);
    if (responsePeriodo?.data?.ExercicioAnterior)
      setExercicioAnterior(responsePeriodo.data.ExercicioAnterior);
    if (responsePeriodo?.data?.ExercicioAtual)
      setExercicioAtual(responsePeriodo.data.ExercicioAtual);
    if (responsePeriodo?.data?.ExercicioPosterior)
      setExercicioPosterior(responsePeriodo.data.ExercicioPosterior);

    const detalheVisao = {
      ChaveModulo: modulo,
      Exercicio: exercicio,
      Periodicidade: periodicidade,
      Periodo: periodo,
      ChavesPeriodicidade: chavesPeriodicidade,
    };

    if (configuracao && configuracao.VisaoColunas) {
      const { VisaoColunas } = configuracao;
      const visaoColuna = VisaoColunas.find(x => x.Chave);
      if (visaoColuna) {
        const { TipoValor } = visaoColuna;
        if (TipoValor) {
          detalheVisao.TipoValor = TipoValor;
        }
      }
    }

    obterDetalheVisao(detalheVisao);

    let queryParamData = {
      chaveModulo: modulo,
      exercicio,
      periodicidade,
      periodo,
    };

    chavesPeriodicidade.forEach(item => {
      queryParamData[item.Campo] = item.Valor;
    });

    const searchParams = getAndFormatSearchParams(
      queryParamData,
      queryParamsDefault
    );

    browserHistory.replace(
      `/#/dinamico/${modulo}/${visao}/detalhevisao?${searchParams}`,
      history.location.state
    );
  }

  const getPeriodo = useCallback(
    async (exercicio, periodicidade, periodo) => {
      const [responsePeriodo, responseConfiguracao] = await Promise.all([
        getPeriodoDetalheVisaoAnaliticaService(
          exercicio,
          periodicidade,
          periodo
        ),
        obterConfiguracoes(modulo, visao),
      ]);

      if (responseConfiguracao.data) setConfiguracao(responseConfiguracao.data);
      if (responsePeriodo?.data?.PeriodoAnterior)
        setPeriodoAnterior(responsePeriodo.data.PeriodoAnterior);
      if (responsePeriodo?.data?.PeriodoAtual)
        setPeriodoAtual(responsePeriodo.data.PeriodoAtual);
      if (responsePeriodo?.data?.PeriodoPosterior)
        setPeriodoPosterior(responsePeriodo.data.PeriodoPosterior);
      if (responsePeriodo?.data?.ExercicioAnterior)
        setExercicioAnterior(responsePeriodo.data.ExercicioAnterior);
      if (responsePeriodo?.data?.ExercicioAtual)
        setExercicioAtual(responsePeriodo.data.ExercicioAtual);
      if (responsePeriodo?.data?.ExercicioPosterior)
        setExercicioPosterior(responsePeriodo.data.ExercicioPosterior);

      const params = new URLSearchParams(history.location.search);

      const ChavesPeriodicidade = responseConfiguracao.data.ChavesPeriodicidades.map(
        item => {
          const { TipoValor, FonteDados } = item;
          const Valor = params.get(FonteDados);

          return {
            Campo: FonteDados,
            Valor,
            TipoValor,
          };
        }
      );

      const campos = ChavesPeriodicidade.map(x => x.Campo);

      setQueryParamsDefault([...queryParamsDefault, ...campos]);

      setChavesPeriodicidade(ChavesPeriodicidade);

      const detalheVisao = {
        ChaveModulo: modulo,
        Exercicio: exercicio,
        Periodicidade: periodicidade,
        Periodo: periodo,
        ChavesPeriodicidade,
      };

      if (
        responseConfiguracao &&
        responseConfiguracao.data &&
        responseConfiguracao.data.VisaoColunas
      ) {
        const { VisaoColunas } = responseConfiguracao.data;
        const visaoColuna = VisaoColunas.find(x => x.Chave);
        if (visaoColuna) {
          const { TipoValor } = visaoColuna;
          if (TipoValor) {
            detalheVisao.TipoValor = TipoValor;
          }
        }
      }

      obterDetalheVisao(detalheVisao);
    },
    [modulo, visao]
  );

  async function obterDataUltimaAlteracao() {
    const response = await obterConfiguracoesGerais(modulo);

    if (response?.data?.DataUltimaSicronizacaoModulo)
      setDataUltimaAtualizacao(response.data.DataUltimaSicronizacaoModulo);
  }

  useEffect(() => {
    setPagina(history.location.state?.pagina);
    setQuantidadeRegistros(history.location.state?.quantidadeRegistros);
    setColunaFiltroVisaoAgrupamento(
      history.location.state?.colunaFiltroVisaoAgrupamento
    );
    setValorFiltroVisaoAgrupamento(
      history.location.state?.valorFiltroVisaoAgrupamento
    );
    setTipoValorFiltroVisaoAgrupamento(
      history.location.state?.tipoValorFiltroVisaoAgrupamento
    );

    setColunaOrdem({
      colunaOrdem: history.location.state?.colunaOrdem,
      tipoOrdem: history.location.state?.tipoOrdem,
    });
    setFiltroAnterior(history.location.state?.filtro);
  }, []);

  useEffect(() => {
    if (modulo) obterDataUltimaAlteracao();
  }, [modulo]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const exercicio = Number(params.get('exercicio'));
    const periodicidade = params.get('periodicidade')?.toUpperCase();
    const periodo = params.get('periodo')?.toUpperCase();

    setExercicioFilter(exercicio);
    setPeriodicidadeFilter(periodicidade);

    setPeriodoFilter(periodo);

    getPeriodo(exercicio, periodicidade, periodo);
  }, [getPeriodo, history.location.search, modulo, visao]);

  function obterAnterior() {
    if (periodoAnterior) {
      obterPeriodoDetalheVisaoAnalitica(
        periodoAnterior.Exercicio,
        periodicidadeFilter,
        periodoAnterior.Valor
      );
    } else {
      obterPeriodoDetalheVisaoAnalitica(
        exercicioAnterior,
        periodicidadeFilter,
        null
      );
    }
  }

  function obterProximo() {
    if (periodoPosterior) {
      obterPeriodoDetalheVisaoAnalitica(
        periodoPosterior.Exercicio,
        periodicidadeFilter,
        periodoPosterior.Valor
      );
    } else {
      obterPeriodoDetalheVisaoAnalitica(
        exercicioPosterior,
        periodicidadeFilter,
        null
      );
    }
  }

  function exportar(tipoExportacao) {
    let payload = {
      ChaveModulo: modulo,
      Exercicio: exercicioFilter,
      Periodicidade: periodicidadeFilter,
      Periodo: periodoAtual?.Valor,
      ChavesPeriodicidade: chavesPeriodicidade,
      UrlExportacao: getUrlComDominio(),
    };

    if (configuracao && configuracao.VisaoColunas) {
      const { VisaoColunas } = configuracao;
      const visaoColuna = VisaoColunas.find(x => x.Chave);
      if (visaoColuna) {
        const { TipoValor } = visaoColuna;
        payload = { ...payload, TipoValor };
      }
    }

    if (tipoExportacao === 'uri') {
      navigator.clipboard.writeText(window.location.href);
      notification.success({
        message: 'URI Copiada com Sucesso!',
      });
    } else {
      exportarDetalheVisao(tipoExportacao, payload).then(res => {
        fileManagerPaiDownload(res.data);
      });
    }
  }

  const tituloVisaoAnalitica = () => {
    if (periodoAtual)
      return `${periodoAtual.Descricao}/${periodoAtual.Exercicio}`;
    else if (exercicioAtual) return exercicioAtual;
    else return '';
  };

  const tituloVisaoAnaliticaPosterior = () => {
    if (periodoPosterior)
      return `${periodoPosterior.Descricao}/${periodoPosterior.Exercicio}`;
    else if (exercicioPosterior) return exercicioPosterior;
    else return '';
  };

  const tituloVisaoAnaliticaAnterior = () => {
    if (periodoAnterior)
      return `${periodoAnterior.Descricao}/${periodoAnterior.Exercicio}`;
    else if (exercicioAnterior) return exercicioAnterior;
    else return '';
  };

  function voltarPaginaAnterior() {
    const { urlBase, queryParam } = obterUrlPaginaAnterior();
    if (urlBase && queryParam) {
      history.push({ pathname: urlBase, search: queryParam });
    } else {
      history.push({
        pathname: `/dinamico/${modulo}/${visao}`,
        state: {
          pagina,
          periodicidade: periodicidadeFilter,
          exercicio: exercicioFilter,
          periodo: periodoFilter,
          quantidadeRegistros,
          valorFiltroVisaoAgrupamento,
          colunaFiltroVisaoAgrupamento,
          tipoValorFiltroVisaoAgrupamento,
          colunaOrdem: colunaOrdem.colunaOrdem,
          tipoOrdem: colunaOrdem.tipoOrdem,
          filtro: filtroAnterior,
        },
      });
    }
  }

  return (
    <>
      <PeriodicidadeNavigation
        current={tituloVisaoAnalitica()}
        next={tituloVisaoAnaliticaPosterior()}
        previous={tituloVisaoAnaliticaAnterior()}
        nextFunction={obterProximo}
        previousFunction={obterAnterior}
      />
      <Typography.Title
        level={3}
        style={{ textAlign: 'center', color: corTema }}
      >
        {detalheVisaoAnalitica.Descricao}
      </Typography.Title>

      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        {detalheVisaoAnalitica.Titulo}
      </Typography.Title>

      {detalheVisaoAnalitica.Dados && detalheVisaoAnalitica.Dados.length > 0 ? (
        <>
          <Tabela detalheVisaoAnalitica={detalheVisaoAnalitica} />
          <ListaAnexos anexos={detalheVisaoAnalitica.Anexos} />
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Col lg={24}>
              <Card>
                <Row>
                  <Col lg={12} style={{ display: 'flex' }}>
                    {dataUltimaAtualizacao && (
                      <UltimaAtualizacao dataHora={dataUltimaAtualizacao} />
                    )}
                  </Col>
                  <Col lg={12}>
                    <Exportacao onClick={exportar} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        detalheVisaoAnalitica.Dados &&
        detalheVisaoAnalitica.Dados.length === 0 && (
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            Não há dados para este período.
          </Typography.Title>
        )
      )}

      <Row>
        <ButtonGoBack OnClick={voltarPaginaAnterior} />
      </Row>
    </>
  );
}

export default DetalheVisaoAnalitica;
