const { protocol, host, hostname } = window.location;

let SMARAPD_PAI_DOMAIN, SMARAPD_FILEMANAGER_DOMAIN;

function getUrl(resource) {
   if (process.env.NODE_ENV === 'development') {
      return `${protocol}//${hostname}/${resource}`;
   } else {
      return `${protocol}//${host}/${resource}`;
   } 
}

SMARAPD_PAI_DOMAIN = getUrl('paiportalserver');
SMARAPD_FILEMANAGER_DOMAIN = getUrl('fileserver');

export { SMARAPD_PAI_DOMAIN, SMARAPD_FILEMANAGER_DOMAIN };
