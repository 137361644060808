/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Row, Col, Typography, Card } from '~/components';
import { TIPO_DETALHE } from '~/modules/Helper/tipoDetalheAnalitica';

function Tabelas({ detalheVisaoAnalitica }) {
  const CamposGrid = ({ coluna, fonte, fundo, index }) => {
    const gridStyle = {
      width: '100%',
      background: fundo,
      gridColumn: index === 0 ? 1 : 'unset',
    };

    const TextoValorBoolean = ({ valor }) => {
      return valor === true ? 'Sim' : 'Não';
    };

    const { Descricao, Valor } = coluna;

    return (
      <Card.Grid style={gridStyle}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>
            <Typography.Text style={{ color: fonte }} strong>
              {Descricao}:
            </Typography.Text>
          </div>
          <div style={{ marginLeft: '10px', wordBreak: 'break-word' }}>
            <Typography.Text style={{ color: fonte }}>
              {typeof Valor === 'boolean' ? (
                <TextoValorBoolean valor={Valor} />
              ) : (
                Valor
              )}
            </Typography.Text>
          </div>
        </div>
      </Card.Grid>
    );
  };

  const Grid = ({ tabela }) => {
    switch (tabela.Tipo) {
      case TIPO_DETALHE.CAMPO:
        return (
          <Row style={{ marginBottom: '30px' }}>
            <Col lg={24}>
              <Card
                headStyle={{
                  color: tabela.Grupo.CorFonteTitulo,
                  backgroundColor: tabela.Grupo.CorFundoTitulo,
                }}
                bodyStyle={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  backgroundColor: tabela.Grupo.CorFundo,
                }}
                title={tabela.Grupo.Descricao}
              >
                {tabela.Grupo.Colunas.map((coluna, index) => (
                  <CamposGrid
                    key={index}
                    coluna={coluna}
                    fonte={tabela.Grupo.CorFonte}
                    fundo={tabela.Grupo.CorFundo}
                    index={index}
                  />
                ))}
              </Card>
            </Col>
          </Row>
        );
      case TIPO_DETALHE.TOTAL:
        return (
          <Row style={{ marginBottom: '30px' }}>
            <Col lg={24}>
              <Card bodyStyle={{ backgroundColor: tabela.Coluna.CorFundo }}>
                <Card.Grid
                  style={{
                    backgroundColor: tabela.Coluna.CorFundo,
                    width: '100%',
                  }}
                >
                  <Typography.Text
                    style={{ color: tabela.Coluna.CorFonte }}
                    strong
                  >
                    {tabela.Coluna.Descricao}:
                  </Typography.Text>
                  <Typography.Text
                    style={{ color: tabela.Coluna.CorFonte, float: 'right' }}
                    strong
                  >
                    {tabela.Coluna.Valor}
                  </Typography.Text>
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        );
      case TIPO_DETALHE.CAMPO_TOTAL:
        return (
          <>
            <Row style={{ marginBottom: '30px' }}>
              <Col lg={24}>
                <Card
                  title={tabela.Grupo.Descricao}
                  headStyle={{
                    color: tabela.Grupo.CorFonteTitulo,
                    backgroundColor: tabela.Grupo.CorFundoTitulo,
                  }}
                  bodyStyle={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    backgroundColor: tabela.Coluna.CorFundo,
                  }}
                >
                  {tabela.Grupo.Colunas.map((coluna, index) => (
                    <CamposGrid
                      key={index}
                      coluna={coluna}
                      fonte={tabela.Grupo.CorFonte}
                      fundo={tabela.Grupo.CorFundo}
                      index={index}
                    />
                  ))}
                </Card>
                <Card.Grid
                  style={{
                    backgroundColor: tabela.Coluna.CorFundo,
                    width: '100%',
                  }}
                >
                  <Typography.Text
                    style={{ color: tabela.Coluna.CorFonte }}
                    strong
                  >
                    {tabela.Coluna.Descricao}:
                  </Typography.Text>

                  <Typography.Text
                    style={{
                      color: tabela.Coluna.CorFonte,
                      float: 'right',
                    }}
                    strong
                  >
                    {tabela.Coluna.Valor}
                  </Typography.Text>
                </Card.Grid>
              </Col>
            </Row>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {detalheVisaoAnalitica.Dados.map((tabela, index) => (
        <Grid key={index} tabela={tabela} />
      ))}
    </>
  );
}

export default Tabelas;
