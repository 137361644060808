import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { UltimaAtualizacaoArea } from './Footer.styled';

export function UltimaAtualizacao({ dataHora, corTituloGrid }) {
  const data = new Date(dataHora).toLocaleDateString('pt-Br');
  const horas = new Date(dataHora).toLocaleTimeString('pt-Br');

  return (
    <UltimaAtualizacaoArea corTituloGrid={corTituloGrid}>
      <div className="title">Última Atualização:</div>
      <div className="dates">
        <p>{data}</p>
        <p>
          <ClockCircleOutlined style={{ marginRight: 3 }} />
          {horas}
        </p>
      </div>
    </UltimaAtualizacaoArea>
  );
}
