import api from '~/services/api';

async function getColunasFiltro(nomeModulo) {
  const response = await api.get(`Modulo/${nomeModulo}/colunasfiltro`);
  return response.data;
}

async function obterDadosFiltroAvancado(data) {
  const response = await api.post(`ModuloVisao/filtroavancado`, data);
  return response;
}

async function exportarBuscaAvancada(tipoExportacao, data) {
  const response = await api.post(
    `Modulovisao/exportacaogridfiltroavancado/${tipoExportacao}`,
    data
  );
  return response;
}

export { getColunasFiltro, exportarBuscaAvancada, obterDadosFiltroAvancado };
