import api from '~/services/api';
import history from '~/services/history';

function getESicService() {
  return api.get('esic');
}

function filtrarGrafico(data) {
  return api.post('esic/filtrar', data);
}

function redirectNotFound() {
  history.push('/notfound');
}

function getVisoesData(params) {
  return api.post('integracaoesic', params);
}

export { getESicService, redirectNotFound, getVisoesData, filtrarGrafico };
