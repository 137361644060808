import React from 'react';
import { Form } from 'antd';
import { Select, Typography } from '~/components';

const { Option } = Select;

const listaMes = [
  { name: 'Janeiro', value: 1 },
  { name: 'Fevereiro', value: 2 },
  { name: 'Março', value: 3 },
  { name: 'Abril', value: 4 },
  { name: 'Maio', value: 5 },
  { name: 'Junho', value: 6 },
  { name: 'Julho', value: 7 },
  { name: 'Agosto', value: 8 },
  { name: 'Setembro', value: 9 },
  { name: 'Outubro', value: 10 },
  { name: 'Novembro', value: 11 },
  { name: 'Dezembro', value: 12 },
];

const listaBimestral = [
  {
    name: '1º Bimestre',
    value: 1,
  },
  {
    name: '2º Bimestre',
    value: 2,
  },
  {
    name: '3º Bimestre',
    value: 3,
  },
  {
    name: '4º Bimestre',
    value: 4,
  },
];

const listaSemestral = [
  {
    name: '1º Semestre',
    value: 1,
  },
  {
    name: '2º Semestre',
    value: 2,
  },
];

export const FILTRO_DEFAULT = {
  MENSAL: 1,
  ANUAL: 2,
  SEM_FILTRO: 3,
};

export const periodicidadeAnual = (
  <>
    <div style={{ marginBottom: 10 }}>
      <Typography.Text style={{ fontWeight: 'bold' }}>
        Por Exercício
      </Typography.Text>
    </div>
    <Form.Item label="Exercício" name="Periodo">
      <Select
        value={new Date().getFullYear()}
        defaultValue={new Date().getFullYear()}
        style={{ width: 120 }}
      >
        <Option value={new Date().getFullYear()}>
          {new Date().getFullYear()}
        </Option>
        <Option value={new Date().getFullYear() - 1}>
          {new Date().getFullYear() - 1}
        </Option>
        <Option value={new Date().getFullYear() - 2}>
          {new Date().getFullYear() - 2}
        </Option>
        <Option value={new Date().getFullYear() - 3}>
          {new Date().getFullYear() - 3}
        </Option>
        <Option value={new Date().getFullYear() - 4}>
          {new Date().getFullYear() - 4}
        </Option>
      </Select>
    </Form.Item>
  </>
);

export const periodicidadeMensal = periodos => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontWeight: 'bold' }}>
          Por Mês
        </Typography.Text>
      </div>
      <Form.Item label="Mês" name="Periodo">
        <Select defaultValue="Janeiro" style={{ width: 120 }}>
          {periodos.map(({ Id, Descricao }) => {
            return <Option value={Id}>{Descricao}</Option>;
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export const periodicidadeBimestral = periodos => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontWeight: 'bold' }}>
          Por Bimestre
        </Typography.Text>
      </div>
      <Form.Item label="Bimestre" name="Periodo">
        <Select defaultValue={1} style={{ width: 120 }}>
          {periodos.map(({ Id, Descricao }) => {
            return <Option value={Id}>{Descricao}</Option>;
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export const periodicidadeSemestral = periodos => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontWeight: 'bold' }}>
          Por Semestre
        </Typography.Text>
      </div>
      {periodos && (
        <Form.Item label="Semestre" name="Periodo">
          <Select defaultValue={periodos[0].Id} style={{ width: 120 }}>
            {periodos.map(({ Id, Descricao }) => {
              return <Option value={Id}>{Descricao}</Option>;
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
