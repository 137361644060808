import styled from 'styled-components';
import YouTube from 'react-youtube';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
`;

export const Youtube = styled(YouTube)`
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`;