import styled from 'styled-components';
import { Divider as antdDivider} from 'antd';

export const Divider = styled(antdDivider)`
    &&{
        .ant-divider-inner-text{
            font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
        }
    }
        
`;