import styled from 'styled-components';

export const Container = styled.div`
  height: 80vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ItemGlossarioArea = styled.div`
  padding-top: 5em;

  @media (max-width: 1244px) {
    padding-top: 6.6em;
  }
`;
