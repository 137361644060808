import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './Title.styled';

function Title() {
  const configuration = useSelector(state => state.configuration);
  const { corFonteTituloPortal, titulo } = configuration;

  return <S.Title color={corFonteTituloPortal}>{titulo}</S.Title>;
}

export default Title;
