import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { WidthProvider, Responsive } from 'react-grid-layout';
import InformacaoDashboard from '~/components/InformacaoDashboard/InformacaoDashboard.component';
import CardImagemDashboard from '~/components/CardImagemDashboard/cardImagemDashboard.component';
import YoutubePlayer from '~/components/YoutubePlayer/YoutubePlayer.component';
import DonutGraphic from '~/components/Grafico/DonutGraphic.component';
import PieGraphic from '~/components/Grafico/PieGraphic.component';
import BarGraphic from '~/components/Grafico/BarGraphic.component';
import ColumnGraphic from '~/components/Grafico/ColumnGraphic.component';
import EmptyCardGrafico from '~/components/Grafico/EmptyCardGraphic.component';
import { useMenus } from '~/contexts/menu.context';
import { getFullUrl } from '~/services/FileManager/file-manager.service';
import { getHomeService } from '../services/dashboard.service';
import StatisticCard from '../components/StatisticCard.component';

import {
  CardNoConfigHome,
  IconNoConfigHome,
  TextNoConfigHome,
} from './dashboard.container.styled';

import {
  ListaHomeGraficoContext,
  HomeGraficoContext,
} from '../context/listaHomeGraficoContext';
import { TIPO_ACESSO } from '../../Helper/tipoAcesso';

function Dashboard() {
  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const { resetMenuSelecionado } = useMenus();

  const [menuIsCollapsed, setMenuIsCollapsed] = useState(false);

  useEffect(() => {
    function forceComponentReRender() {
      setTimeout(() => setMenuIsCollapsed(!menuIsCollapsed), 800);
    }

    function addButtonListeners(buttonNode) {
      buttonNode.addEventListener('click', forceComponentReRender);
      buttonNode.addEventListener('keydown', forceComponentReRender);
    }

    function removeButtonListeners(buttonNode) {
      buttonNode.removeEventListener('click', forceComponentReRender);
      buttonNode.removeEventListener('keydown', forceComponentReRender);
    }

    let collapseButton = document.getElementsByClassName('collapseButton');

    function updateButtonOnResize() {
      removeButtonListeners(collapseButton[0]);
      collapseButton = document.getElementsByClassName('collapseButton');
      addButtonListeners(collapseButton[0]);
    }

    addButtonListeners(collapseButton[0]);
    window.addEventListener('resize', updateButtonOnResize);

    return () => {
      removeButtonListeners(collapseButton[0]);
      window.removeEventListener('resize', updateButtonOnResize);
    };
  }, [menuIsCollapsed]);

  const [layouts, setLayouts] = useState();
  const { item, setItem, home, setHome, anoAtual } = useContext(
    HomeGraficoContext
  );
  const [noConfigError, setNoConfigError] = useState('');

  const gridContainer = {
    overflow: 'hidden',
    left: -10,
  };

  const gridBackground = {
    width: '100%',
    height: '100%',
    left: '0px',
    right: '0px',
    backgroundSize: '3% 3%',
  };

  const { corFonte } = useSelector(state => state.configuration);

  useEffect(() => {
    async function getHome() {
      const response = await getHomeService(anoAtual);
      if (response && response.data) setHome(response.data);
    }
    getHome();
  }, []);

  useEffect(() => {
    if (item) {
      const layoutDefault = item.map(el => ({
        i: el.id,
        ...el.position,
        static: true,
      }));

      const sm = [];
      let previousItemY = 0;

      item.forEach(el => {
        const newItem = {
          i: el.id,
          ...el.position,
          x: 0,
          y: previousItemY,
          static: true,
        };
        sm.push(newItem);
        previousItemY += el.position.h;
      });

      const newlayouts = {
        sm,
        md: layoutDefault,
        lg: layoutDefault,
      };
      setLayouts(newlayouts);
    }
  }, [item]);

  useEffect(() => {
    async function getListaGrafico() {
      const listaHomeItem = home?.ListaHomeItem.map(homeItem => {
        const titulo =
          homeItem.Titulo &&
          anoAtual &&
          !homeItem.ExibirExercicio &&
          homeItem.HomeItemBotao?.TipoAcesso !== TIPO_ACESSO.EXTERNO
            ? `${homeItem.Titulo} - Ano ${anoAtual}`
            : homeItem.Titulo || '';

        if (homeItem.HomeItemBotao) {
          return {
            id: homeItem.Id,
            position: homeItem.Posicao,
            component: (
              <StatisticCard
                id={homeItem.Id}
                Tamanho={homeItem.Tamanho}
                Titulo={titulo}
                Icone={homeItem.HomeItemBotao?.Icone}
                IconePersonalizado={getFullUrl(
                  homeItem.HomeItemBotao?.UrlIconePersonalizado
                )}
                IconeCor={homeItem.HomeItemBotao?.IconeCor}
                TipoAcesso={homeItem.HomeItemBotao.TipoAcesso}
                DescricaoValorDestaque={
                  homeItem.HomeItemBotao.DescricaoValorDestaque
                }
                ValorDestaque={homeItem.HomeItemBotao.ValorDestaque}
                ExibirValorSecundario={
                  homeItem.HomeItemBotao.ExibirValorSecundario
                }
                ExibirGraficoPorcentagem={
                  homeItem.HomeItemBotao.ExibirGraficoPorcentagem
                }
                DescricaoValorSecundario={
                  homeItem.HomeItemBotao.DescricaoValorSecundario
                }
                ValorSecundario={homeItem.HomeItemBotao.ValorSecundario}
                DescricaoLinkExterno={homeItem.HomeItemBotao.ValorExterno}
                PorcentagemValorDestaque={
                  homeItem.HomeItemBotao.PorcentagemValorDestaque
                }
                PorcentagemValorSecundario={
                  homeItem.HomeItemBotao.PorcentagemValorSecundario
                }
                ValorLink={homeItem.Link}
                corFonte={corFonte}
                showExercicioSelect={homeItem.ExibirExercicio}
              />
            ),
          };
        }
        if (homeItem.HomeItemTexto) {
          return {
            id: homeItem.Id,
            position: homeItem.Posicao,
            component: (
              <InformacaoDashboard
                informacao={homeItem.HomeItemTexto.Informacao}
                fundoTransparente={homeItem.HomeItemTexto.FundoTransparente}
              />
            ),
          };
        }
        if (homeItem.HomeItemVideo) {
          return {
            id: homeItem.Id,
            position: homeItem.Posicao,
            component: (
              <YoutubePlayer videoId={homeItem.HomeItemVideo.VideoId} />
            ),
          };
        }
        if (homeItem.HomeItemCardImagem) {
          return {
            id: homeItem.Id,
            position: homeItem.Posicao,
            component: (
              <CardImagemDashboard
                urlImagem={getFullUrl(homeItem.HomeItemCardImagem.UrlImagem)}
                valorLink={homeItem.HomeItemCardImagem.Link}
                tipoAcesso={homeItem.HomeItemCardImagem.TipoAcesso}
              />
            ),
          };
        }
        if (homeItem.HomeItemGrafico) {
          switch (homeItem.HomeItemGrafico.TipoGrafico) {
            case 1:
              return {
                id: homeItem.Id,
                position: homeItem.Posicao,
                component: (
                  <PieGraphic
                    id={homeItem.Id}
                    titulo={titulo}
                    data={homeItem.HomeItemGrafico.Dados}
                    legenda={homeItem.HomeItemGrafico.LegendaCampoReferencia}
                    tootltipTitle={homeItem.HomeItemGrafico.LegendaCampoValor}
                    ValorLink={homeItem.Link}
                    corFonte={corFonte}
                    showEyeButton
                    showExercicioSelect={homeItem.ExibirExercicio}
                  />
                ),
              };
            case 2:
              return {
                id: homeItem.Id,
                position: homeItem.Posicao,
                component: (
                  <ColumnGraphic
                    id={homeItem.Id}
                    titulo={titulo}
                    cor={homeItem.HomeItemGrafico.CorGrafico}
                    data={homeItem.HomeItemGrafico.Dados}
                    legendaEixoX={
                      homeItem.HomeItemGrafico.LegendaCampoReferencia
                    }
                    legendaEixoY={homeItem.HomeItemGrafico.LegendaCampoValor}
                    subTitulo={homeItem.HomeItemGrafico.Periodo}
                    ValorLink={homeItem.Link}
                    corFonte={corFonte}
                    showEyeButton
                    showExercicioSelect={homeItem.ExibirExercicio}
                  />
                ),
              };
            case 3:
              return {
                id: homeItem.Id,
                position: homeItem.Posicao,
                component: (
                  <BarGraphic
                    id={homeItem.Id}
                    titulo={titulo}
                    cor={homeItem.HomeItemGrafico.CorGrafico}
                    data={homeItem.HomeItemGrafico.Dados}
                    legendaEixoX={
                      homeItem.HomeItemGrafico.LegendaCampoReferencia
                    }
                    legendaEixoY={homeItem.HomeItemGrafico.LegendaCampoValor}
                    ValorLink={homeItem.Link}
                    corFonte={corFonte}
                    showEyeButton
                    showExercicioSelect={homeItem.ExibirExercicio}
                  />
                ),
              };
            case 4:
              return {
                id: homeItem.Id,
                position: homeItem.Posicao,
                component: (
                  <DonutGraphic
                    id={homeItem.Id}
                    titulo={titulo}
                    data={homeItem.HomeItemGrafico.Dados}
                    legenda={homeItem.HomeItemGrafico.LegendaCampoReferencia}
                    tootltipTitle={homeItem.HomeItemGrafico.LegendaCampoValor}
                    ValorLink={homeItem.Link}
                    corFonte={corFonte}
                    showEyeButton
                    showExercicioSelect={homeItem.ExibirExercicio}
                  />
                ),
              };
            default:
              return {
                id: homeItem.Id,
                position: homeItem.Posicao,
                component: (
                  <EmptyCardGrafico
                    id={homeItem.Id}
                    titulo={titulo}
                    valorLink={homeItem.Link}
                    tamanho={homeItem.Tamanho}
                    corFonte={corFonte}
                    showExercicioSelect={homeItem.ExibirExercicio}
                  />
                ),
              };
          }
        } else {
          return {
            id: homeItem.Id,
            position: homeItem.Posicao,
            component: (
              <EmptyCardGrafico
                id={homeItem.Id}
                titulo={homeItem.Titulo}
                valorLink={homeItem.Link}
                tamanho={homeItem.Tamanho}
                corFonte={corFonte}
                showExercicioSelect={homeItem.ExibirExercicio}
              />
            ),
          };
        }
      });
      if (listaHomeItem) {
        if (!listaHomeItem.itemHome)
          setNoConfigError(
            <CardNoConfigHome>
              <IconNoConfigHome />
              <TextNoConfigHome>
                Nenhuma Configuração Realizada na Home
              </TextNoConfigHome>
            </CardNoConfigHome>
          );
        setItem(
          listaHomeItem.filter(itemHome => {
            return itemHome;
          })
        );
      }
    }

    getListaGrafico();
  }, [home, setHome]);

  useEffect(() => {
    resetMenuSelecionado();
  }, []);

  return (
    <div style={gridContainer}>
      <div style={gridBackground} />
      {item?.length ? (
        <ResponsiveReactGridLayout
          breakpoints={{ lg: 1440, md: 1024, sm: 768 }}
          cols={{ lg: 12, md: 12, sm: 1 }}
          layouts={layouts}
          rowHeight={75}
        >
          {item?.length &&
            item.map(el => <div key={el.id}>{el.component}</div>)}
        </ResponsiveReactGridLayout>
      ) : (
        <>{noConfigError}</>
      )}
    </div>
  );
}

function Container() {
  return (
    <ListaHomeGraficoContext>
      <Dashboard />
    </ListaHomeGraficoContext>
  );
}

export default Container;
