import React from 'react';
import { Container, Youtube } from './style';

function YouTubePlayer({ videoId }) {
  const optionsYoutubePlayer = {
    playerVars: {
      rel: 0,
    },
  };

  return (
    <>
      <Container>
        <Youtube videoId={videoId} opts={optionsYoutubePlayer} />
      </Container>
    </>
  );
}

export default YouTubePlayer;
