export const CHAVES_MODULO = {
  ESIC: 'esic',
  FAQ: 'faq',
  ESTRUTURA_ORGANIZACIONAL: 'estrutura_organizacional',
  ESTATISTICAS: 'estatisticas',
  SUMARIO: 'sumario',
  FALE_CONOSCO: 'faleconosco',
  GLOSSARIO: 'glossario',
  HOME: 'home',
  AUTENTICIDADE: 'autenticidade',
  DADOS_ABERTOS: 'dados_abertos',
};
