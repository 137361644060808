/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { Pie } from '@antv/g2plot';
import { useHistory } from 'react-router-dom';
import { Row, Col } from '~/components';
import { iconEnum } from '~/modules/Helper/iconEnum';
import {
  ValorDestaqueDiv,
  IconeDiv,
  StatisticSpan,
  ValorDescricaoSpan,
  IconeMedioDiv,
  IconeMedioImg,
  ValorDescricaoDestaqueMedioDiv,
  ValorDestaqueMedioDiv,
  ValorSecundarioMedioSpan,
  GraficoMedio,
  DescricaoGrafico,
  CardWrapper,
  DescricaoLinkExternoDiv,
  DescricaoLinkExternoDivMedio,
  StatisticSpanMedio,
  StyledCol,
} from '~/components/Grafico/style';

import {
  useWindowListener,
  tiposDevice,
} from '~/components/Grafico/windowListener.component';
import ExercicioSelect from '~/components/Grafico/ExercicioSelect.component';
import { TIPO_ACESSO } from '../../Helper/tipoAcesso';

function StatisticCard({
  id,
  Tamanho,
  Titulo,
  IconePersonalizado,
  Icone,
  IconeCor,
  TipoAcesso,
  ValorDestaque,
  DescricaoValorDestaque,
  ExibirValorSecundario,
  ValorSecundario,
  DescricaoValorSecundario,
  ExibirGraficoPorcentagem,
  DescricaoLinkExterno,
  PorcentagemValorDestaque,
  PorcentagemValorSecundario,
  ValorLink,
  corFonte,
  showExercicioSelect,
}) {
  const windowListener = useWindowListener();

  const donutData = [
    {
      type: DescricaoValorDestaque,
      value: parseFloat(PorcentagemValorDestaque),
    },
    {
      type: DescricaoValorSecundario,
      value: parseFloat(PorcentagemValorSecundario),
    },
  ];

  const [donut, setDonut] = useState(null);
  const { zoomFonte } = useSelector(state => state.configuration);
  const [config] = useState({
    title: false,
    description: false,
    label: {
      visible: false,
    },
    legend: {
      visible: false,
      flipPage: false,
    },
    autoFit: true,
    radius: 0.8,
    statistic: {
      visible: true,
      title: {
        content: 'Total',
        style: {
          fontSize: 15,
        },
      },
      content: {
        content: `${Math.round(parseFloat(PorcentagemValorDestaque))}%`,
        style: {
          fontSize: 18,
        },
      },
      triggerOn: null,
      triggerOff: null,
    },
    colorField: 'type',
    angleField: 'value',
    color: ['#5AD8A6', '#D9D9D9'],
    innerRadius: 1.2,
  });

  const history = useHistory();

  useEffect(() => {
    if (
      windowListener &&
      windowListener.device === tiposDevice.mobile &&
      donut
    ) {
      setDonut(null);
    } else if (
      windowListener &&
      windowListener.device === tiposDevice.desktop &&
      !donut
    ) {
      const element = document.getElementById(id);
      if (element) {
        const donutRender = new Pie(element, { data: donutData, ...config });
        setDonut(donutRender);
        donutRender.render();
      }
    }
  }, [config, donut, donutData, id, windowListener, zoomFonte]);

  function hrefFunction(link) {
    if (TipoAcesso === TIPO_ACESSO.INTERNO) return history.push(link);

    window.open(link, '_blank');
  }

  return (
    <>
      {Tamanho === 1 ? (
        <Tooltip title="Clique para mais detalhes">
          <CardWrapper
            title={
              <span
                style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
              >
                {Titulo}
              </span>
            }
            extra={showExercicioSelect && <ExercicioSelect id={id} />}
            hoverable
            size="small"
            style={{
              fontSize: '25px',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              maxHeight: '99.5%',
              cursor: 'default',
            }}
          >
            <div
              onClick={() => hrefFunction(ValorLink)}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col
                  lg={8}
                  xs={8}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconeDiv>
                    {Icone &&
                      iconEnum
                        .find(x => x.name === Icone)
                        ?.value({ color: IconeCor })}
                  </IconeDiv>
                  {!Icone && (
                    <IconeMedioImg
                      tamanho={Tamanho}
                      src={IconePersonalizado}
                      alt="Icone personalizado"
                    />
                  )}
                </Col>
                <Col
                  lg={16}
                  xs={16}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {TipoAcesso === TIPO_ACESSO.INTERNO ? (
                    <StatisticSpan>
                      <Row>
                        <Col lg={24}>
                          <ValorDescricaoSpan>
                            {DescricaoValorDestaque}
                          </ValorDescricaoSpan>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={24}>
                          <ValorDestaqueDiv>{ValorDestaque}</ValorDestaqueDiv>
                        </Col>
                      </Row>
                    </StatisticSpan>
                  ) : (
                    <DescricaoLinkExternoDiv>
                      {DescricaoLinkExterno}
                    </DescricaoLinkExternoDiv>
                  )}
                </Col>
              </Row>
            </div>
          </CardWrapper>
        </Tooltip>
      ) : (
        <Tooltip title="Clique para mais detalhes">
          <CardWrapper
            title={
              <span
                style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
              >
                {Titulo}
              </span>
            }
            extra={showExercicioSelect && <ExercicioSelect id={id} />}
            hoverable
            size="small"
            style={{
              height: '100%',
              fontSize: '25px',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              cursor: 'default',
            }}
          >
            <div
              onClick={() => hrefFunction(ValorLink)}
              style={{ cursor: 'pointer', height: '100%' }}
            >
              {windowListener &&
              windowListener.device === tiposDevice.mobile &&
              id ? (
                <>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Col
                      lg={6}
                      xs={10}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <IconeMedioDiv tamanho={Tamanho}>
                        {Icone &&
                          iconEnum
                            .find(x => x.name === Icone)
                            ?.value({ color: IconeCor })}
                      </IconeMedioDiv>
                      {!Icone && (
                        <IconeMedioImg
                          tamanho={Tamanho}
                          src={IconePersonalizado}
                          alt="Icone personalizado"
                        />
                      )}
                    </Col>
                    <StyledCol lg={11} xs={14}>
                      {TipoAcesso === TIPO_ACESSO.INTERNO ? (
                        <StatisticSpanMedio>
                          <Row>
                            <Col lg={24}>
                              <ValorDescricaoDestaqueMedioDiv
                                corFonte={corFonte}
                                tamanho={Tamanho}
                              >
                                {DescricaoValorDestaque}
                                <br />
                                <ValorDestaqueMedioDiv
                                  corFonte={corFonte}
                                  tamanho={Tamanho}
                                >
                                  {ValorDestaque}
                                </ValorDestaqueMedioDiv>
                              </ValorDescricaoDestaqueMedioDiv>
                            </Col>
                          </Row>
                          {ExibirValorSecundario && (
                            <>
                              <Row>
                                <Col lg={24}>
                                  <ValorSecundarioMedioSpan tamanho={Tamanho}>
                                    {`${DescricaoValorSecundario}: `}
                                    <span>{`${ValorSecundario}`}</span>
                                  </ValorSecundarioMedioSpan>
                                </Col>
                              </Row>
                            </>
                          )}
                        </StatisticSpanMedio>
                      ) : (
                        <DescricaoLinkExternoDiv>
                          {DescricaoLinkExterno}
                        </DescricaoLinkExternoDiv>
                      )}
                    </StyledCol>
                  </Row>
                </>
              ) : (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Col
                    lg={6}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <IconeMedioDiv tamanho={Tamanho}>
                      {Icone &&
                        iconEnum
                          .find(x => x.name === Icone)
                          ?.value({ color: IconeCor })}
                    </IconeMedioDiv>
                    {!Icone && (
                      <IconeMedioImg
                        tamanho={Tamanho}
                        src={IconePersonalizado}
                        alt="Icone personalizado"
                      />
                    )}
                  </Col>
                  <StyledCol lg={11} xs={14}>
                    {TipoAcesso === TIPO_ACESSO.INTERNO ? (
                      <StatisticSpanMedio>
                        <Row>
                          <Col lg={24}>
                            <ValorDescricaoDestaqueMedioDiv tamanho={Tamanho}>
                              {DescricaoValorDestaque}
                              <br />
                              <ValorDestaqueMedioDiv tamanho={Tamanho}>
                                {ValorDestaque}
                              </ValorDestaqueMedioDiv>
                            </ValorDescricaoDestaqueMedioDiv>
                          </Col>
                        </Row>
                        {ExibirValorSecundario && (
                          <>
                            <Row>
                              <Col lg={24}>
                                <ValorSecundarioMedioSpan tamanho={Tamanho}>
                                  {`${DescricaoValorSecundario} `}
                                  <span>{`${ValorSecundario}`}</span>
                                </ValorSecundarioMedioSpan>
                              </Col>
                            </Row>
                          </>
                        )}
                      </StatisticSpanMedio>
                    ) : (
                      <DescricaoLinkExternoDivMedio>
                        {DescricaoLinkExterno}
                      </DescricaoLinkExternoDivMedio>
                    )}
                  </StyledCol>
                  <StyledCol
                    lg={6}
                    xs={8}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {ExibirGraficoPorcentagem && (
                      <div>
                        <GraficoMedio id={id} tamanho={Tamanho} />
                        <DescricaoGrafico tamanho={Tamanho}>
                          {`Percentual de ${DescricaoValorDestaque} sobre ${DescricaoValorSecundario}`}
                        </DescricaoGrafico>
                      </div>
                    )}
                  </StyledCol>
                </Row>
              )}
            </div>
          </CardWrapper>
        </Tooltip>
      )}
    </>
  );
}

export default StatisticCard;
