export const PARAMS_FILTERS_GRID = [
  'periodicidade',
  'exercicio',
  'pagina',
  'periodo',
  'quantidaderegistros',
  'colunafiltrovisaoagrupamento',
  'valorfiltrovisaoagrupamento',
  'tipovalorfiltrovisaoagrupamento',
  'colunaordem',
  'tipoordem',
  'listacolunaordem',
];

export const PARAMS_FILTERS_DETALHE = [
  'periodicidade',
  'exercicio',
  'periodo',
  'pagina',
  'chavesPeriodicidade',
];

export const PARAMS_FILTERS_GRID_BUSCA_AVANCADA = [
  'pagina',
  'quantidaderegistros',
  'listacolunaordem',
  'filtros',
];
