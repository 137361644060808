import styled, { createGlobalStyle } from 'styled-components';
import { Map as LeafletMap } from 'react-leaflet';
import { Card } from '~/components';

export const Map = styled(LeafletMap)`
  width: 100%;
  height: 459px;
  z-index: 0;
`;

export const PopupStyles = createGlobalStyle`
  .leaflet-zoom-animated{
    bottom: 35px !important;
  }
`;

export const PopupLink = styled.a`
  font-size: 14px;
  text-decoration: underline;
  &:hover{
    text-decoration: underline;
  }
`;

export const MapTextCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 459px;
`;
