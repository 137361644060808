import React, { useEffect, useState } from 'react';
import { Line } from '@antv/g2plot';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Icons } from '~/components';
import { GraficoLine, Visualizar, CardWrapper } from './style';

function LineGraphic({
  id,
  titulo,
  data,
  xField = 'EixoY',
  yField = 'EixoX',
  seriesField,
  legendaEixoX,
  legendaEixoY,
  legenda,
  ValorLink,
  showEyeButton = false,
  corFonte,
  onLineClick,
  onPointClick,
}) {
  const [line, setLine] = useState(null);

  const NumberFormatter = function NumberFormatter(value) {
    let newNum = value;
    const suffix = ['', ' Mil', ' Mi', ' Bi', ' Tri'];
    let suffixNum = 0;
    if (newNum > 10000) {
      while (newNum >= 1000) {
        newNum /= 1000;
        suffixNum += 1;
      }
      return Math.trunc(newNum) + suffix[suffixNum];
    }
    return newNum;
  };

  const {
    graficHighContrastStyle,
    highContrast,
    graficDefaultStyle,
    zoomFonte,
  } = useSelector(state => state.configuration);

  const getConfigStyle = highContrast
    ? graficHighContrastStyle
    : graficDefaultStyle;

  const [config] = useState({
    title: false,
    autoFit: true,
    data,
    xField,
    yField,
    seriesField,
    xAxis: {
      title: {
        visible: legendaEixoX || false,
        text: legendaEixoX,
        style: getConfigStyle,
      },
      label: {
        style: getConfigStyle,
        formatter: v => {
          return v.length >= 7 ? `${v.substring(0, 7)}...` : v;
        },
      },
    },
    yAxis: {
      title: {
        visible: false,
        text: legendaEixoY,
        offset: 45,
        style: getConfigStyle,
      },
      label: {
        formatter: v => {
          return NumberFormatter(v);
        },
        style: getConfigStyle,
      },
    },
    color: [
      '#30BBAA',
      '#F39366',
      '#876AB0',
      '#FDF25B',
      '#77C966',
      '#EE7B80',
      '#7F8BB2',
      '#F9D04F',
      '#D85B81',
      '#D3E045',
      '#2DA0D7',
      '#FBB141',
    ],
    point: {
      visible: true,
      size: 5,
      shape: 'circle',
      style: {
        lineWidth: 2,
      },
    },
    legend: {
      visible: true,
      title: {
        visible: true,
        text: legenda,
        style: getConfigStyle,
      },
      itemName: {
        formatter: v => {
          return v.trim().length >= 25 ? `${v.substring(0, 25)}...` : v.trim();
        },
        style: getConfigStyle,
      },
    },
    tooltip: {
      title: () => legendaEixoY,
    },
    smooth: true,
  });

  useEffect(() => {
    if (line) return;

    const element = document.getElementById(id);
    if (element) {
      const lineRender = new Line(element, {
        data,
        ...config,
        events: {
          onLineClick,
          onPointClick,
        },
      });
      setLine(lineRender);
      lineRender.render();
    }
  }, [line, config, data, id]);

  useEffect(() => {
    if (line) {
      line.update({
        xAxis: {
          ...config.xAxis,
          title: {
            ...config.xAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.xAxis.label,
            style: getConfigStyle,
          },
        },
        yAxis: {
          ...config.yAxis,
          title: {
            ...config.yAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.yAxis.label,
            style: getConfigStyle,
          },
        },
        legend: {
          ...config.legend,
          itemName: {
            ...config.itemName,
            style: getConfigStyle,
          },
          title: {
            ...config.label,
            style: getConfigStyle,
          },
        },
        smooth: config.smooth,
        point: config.point,
        tooltip: config.tooltip,
      });
    }

    if (line) line.render();
  }, [
    line,
    config.xAxis,
    config.yAxis,
    config.legend,
    getConfigStyle,
    highContrast,
    zoomFonte,
  ]);

  useEffect(() => {
    if (line) {
      line.update({
        data,
      });
      line.render();
    }
  }, [data, line]);

  return (
    <>
      <CardWrapper
        title={
          <span
            style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
          >
            {titulo}
          </span>
        }
        extra={
          showEyeButton ? (
            <Visualizar>
              <Link to={ValorLink} style={{ color: corFonte }}>
                <Icons.EyeOutlined />
              </Link>
            </Visualizar>
          ) : (
            ''
          )
        }
        size="small"
      >
        <Row />
        <Row>
          <GraficoLine id={id} />
        </Row>
      </CardWrapper>
    </>
  );
}

export default LineGraphic;
