import React, { useState, useEffect } from 'react';
import { PageTitle } from '~/components';
import GraficoAnos from '../components/GraficoAnos.component';
import GraficoVisoes from '../components/GraficoVisoes.component';
import FiltroExercicio from '../components/FiltroExercicio.component';
import * as EstatisticasService from '../services/Estatisticas.service';

export default function Estatiscas() {
  const anoAtual = new Date().getFullYear();

  const [exercicioSelcionado, setExercicioSelecionado] = useState(anoAtual);
  const [exercicios, setExercicios] = useState([]);

  useEffect(() => {
    EstatisticasService.getEstatisticasAnosComDados().then(({ data }) => {
      if (data) setExercicios(data);
    });
  }, []);

  function handleChangeExercicio(anoSelecionado) {
    setExercicioSelecionado(anoSelecionado);
  }

  return (
    <>
      <PageTitle titulo="Estatísticas de Acesso" />
      <GraficoAnos />
      <FiltroExercicio
        handleChangeExercicio={value => handleChangeExercicio(value)}
        exercicios={exercicios}
        exercicio={exercicioSelcionado}
      />
      <GraficoVisoes anoSelecionado={exercicioSelcionado} />
    </>
  );
}
