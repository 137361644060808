import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getFaqService } from '../services/faq.service';
import { Informacao, PageTitle, Row, ButtonGoBack } from '~/components';
import FaqItem from '../components/FaqItem/faqitem.component';

function Faq() {
  const [informacao, setInformacao] = useState({ __html: null });
  const [listaFaqItem, setListaFaqItem] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getFaq() {
      const response = await getFaqService();
      if (response && response.data) {
        setListaFaqItem(response.data.FaqItem);
        setInformacao(response.data.Informacao);
      }
    }

    getFaq();
  }, []);

  function goBack() {
    history.push('/');
  }

  return (
    <>
      <PageTitle titulo="FAQ" />
      {informacao?.Texto && (
        <Informacao titulo={informacao.Titulo} informacao={informacao.Texto} />
      )}

      {Boolean(listaFaqItem?.length) &&
        listaFaqItem.map(el => (
          <FaqItem
            key={el.TituloPergunta}
            pergunta={el.TituloPergunta}
            resposta={el.TextoResposta}
          />
        ))}

      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default Faq;
