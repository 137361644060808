import React, { useEffect, useState } from 'react';
import PieGraphic from '../../../components/Grafico/PieGraphic.component';
import EmptyCardGrafico from '../../../components/Grafico/EmptyCardGraphic.component';
import * as EstatisticasService from '../services/Estatisticas.service';

export default function GraficoVisoes({ anoSelecionado }) {
  const [acessosVisoes, setAcessosVisoes] = useState([]);

  useEffect(() => {
    EstatisticasService.getEstatisticasAcessosVisao(anoSelecionado).then(
      ({ data }) => {
        if (data) setAcessosVisoes(data);
      }
    );
  }, [anoSelecionado]);

  const tooltipFormatter = ({ Acessos, NomeVisao }) => {
    return {
      name: NomeVisao,
      value: new Intl.NumberFormat('pt-BR').format(Acessos),
    };
  };

  return acessosVisoes?.length ? (
    <div className="maxHeight500">
      <PieGraphic
        id="acessosVisoes"
        titulo="Acessos por Página"
        data={acessosVisoes}
        colorField="NomeVisao"
        angleField="Acessos"
        legendPosition="bottom-left"
        legenda="Legenda"
        tootltipTitle={() => 'Acessos'}
        legendMaxRow={5}
        legendItemMarginBottom={8}
        legendItemSpacing={8}
        tooltipFormatter={tooltipFormatter}
      />
    </div>
  ) : (
    <EmptyCardGrafico
      id="acessosVisoes"
      titulo="Acessos por Página"
      tamanho={2}
    />
  );
}
