import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './Modal.styled';

function Modal({
  title,
  width,
  visible,
  onCancel,
  maxHeight,
  minHeight,
  children,
}) {
  const configuration = useSelector(state => state.configuration);
  const { corFonteTituloTelas } = configuration;

  return (
    <S.Modal
      title={title}
      width={width}
      visible={visible}
      onCancel={onCancel}
      maxHeight={maxHeight}
      destroyOnClose
      maskClosable={false}
      footer={null}
      minHeight={minHeight}
      corFonteTituloTelas={corFonteTituloTelas}
    >
      {children}
    </S.Modal>
  );
}

export default Modal;

Modal.defaultProps = {
  width: 520,
  visible: false,
  maxHeight: '70vh',
};

Modal.confirm = S.Modal.confirm;
