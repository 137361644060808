import styled from 'styled-components';
import { Layout } from 'antd';

export const Content = styled(Layout.Content)`
  min-height: auto;
  padding: 24px 32px;
  background: url(${({ src }) => `'${src}'`}) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
`;
