/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { Breadcrumb } from '~/components';
import { salvaEstatistica } from './routes.service';

function Route({ path, component: Component, hasStatistics }) {
  function handleStatistics(url) {
    const payload = {
      Rota: url,
    };

    salvaEstatistica(payload);
  }

  return (
    <ReactRoute
      path={path}
      exact
      render={props => {
        if (hasStatistics) handleStatistics(props.location.pathname);
        return (
          <>
            <Breadcrumb master="/" {...props} />
            <Component {...props} />
          </>
        );
      }}
    />
  );
}

export default Route;
