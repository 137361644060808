import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

function Theme({ children }) {
  const { highContrast, zoomFonte } = useSelector(state => state.configuration);

  return (
    <ThemeProvider theme={{ highContrast, zoomFonte }}>
      {children}
    </ThemeProvider>
  );
}

export default Theme;
