import React, { useState, useEffect, useContext } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import DonutGraphic from '~/components/Grafico/DonutGraphic.component';
import PieGraphic from '~/components/Grafico/PieGraphic.component';
import BarGraphic from '~/components/Grafico/BarGraphic.component';
import ColumnGraphic from '~/components/Grafico/ColumnGraphic.component';
import {
  periodicidadeBimestral,
  periodicidadeAnual,
  periodicidadeMensal,
  periodicidadeSemestral,
} from '~/modules/Helper/FiltrosPeriodicidade';
import api from '~/services/api';

import {
  ListaGraficoContext,
  GraficoContext,
} from '../context/listaGraficoContext';

import {
  getESicService,
  redirectNotFound,
  getVisoesData,
} from '../services/esic.service';

import {
  Informacao,
  PageTitle,
  ImageButton,
  Row,
  ButtonGoBack,
} from '~/components';
import ESicVisoes from '../components/visoes.component';

function ESic() {
  const history = useHistory();
  const [visoesESic, setVisoesESic] = useState(null);
  const [formularioESIC, setFormularioESIC] = useState(null);

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const { listaGrafico, setListaGrafico, eSic, setESic } = useContext(
    GraficoContext
  );
  const [periodicidade, setPeriodicidade] = useState(null);
  const gridContainer = {
    overflow: 'hidden',
    left: -10,
  };

  const gridBackground = {
    width: '100%',
    height: '100%',
    left: '0px',
    right: '0px',
    backgroundSize: '3% 3%',
  };

  const showEyeButton = false;

  useEffect(() => {
    async function getPeriodos() {
      const responsePeriodicidade = await api.get('periodicidade/periodos');
      setPeriodicidade(responsePeriodicidade.data);
    }

    getPeriodos();
  }, []);

  function obterUrlFormulario({
    UrlArquivoPessoaFisicaReclamacao,
    UrlArquivoPessoaFisicaRecurso,
    UrlArquivoPessoaFisicaeSIC,
    UrlArquivoPessoaJuridicaReclamacao,
    UrlArquivoPessoaJuridicaRecurso,
    UrlArquivoPessoaJuridicaeSIC,
  }) {
    const data = [
      {
        Entidade: 'e-SIC',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaeSIC,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaeSIC,
          },
        ],
      },
      {
        Entidade: 'Reclamações',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaReclamacao,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaReclamacao,
          },
        ],
      },
      {
        Entidade: 'Recursos',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaRecurso,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaRecurso,
          },
        ],
      },
    ];

    setFormularioESIC(data);
  }

  async function obterDadosVisoes({
    AtendimentoQuantidade,
    AtendimentoRespondido,
    AtendimentoPrazoMedio,
    RecursoQuantidade,
    RecursoRespondido,
    RecursoPrazoMedio,
    ReclamacaoQuantidade,
    ReclamacaoRespondido,
    ReclamacaoPrazoMedio,
  }) {
    const params = {
      AtendimentoQuantidade,
      AtendimentoRespondido,
      AtendimentoPrazoMedio,
      RecursoQuantidade,
      RecursoRespondido,
      RecursoPrazoMedio,
      ReclamacaoQuantidade,
      ReclamacaoRespondido,
      ReclamacaoPrazoMedio,
    };

    const { data } = await getVisoesData(params);
    setVisoesESic(data);
  }

  useEffect(() => {
    async function getESic() {
      const response = await getESicService();
      if (response && response.data) {
        if (!response.data.Habilitado) redirectNotFound();
        setESic(response.data);
        obterDadosVisoes(response.data);
        obterUrlFormulario(response.data);
      }
    }

    if (periodicidade) getESic();
  }, [periodicidade, setESic]);

  useEffect(() => {
    function getFilterContent(exibicao) {
      switch (exibicao) {
        case 1:
          return {
            Periodicidade: periodicidade[0]?.Periodos[0]?.Id,
            Content: periodicidadeMensal(periodicidade[0]?.Periodos),
          };
        case 2:
          return {
            Periodicidade: periodicidade[1]?.Periodos[0]?.Id,
            Content: periodicidadeBimestral(periodicidade[1]?.Periodos),
          };
        case 5:
          return {
            Periodicidade: periodicidade[4]?.Periodos[0]?.Id,
            Content: periodicidadeSemestral(periodicidade[4]?.Periodos),
          };
        case 6:
          return {
            Periodicidade: 2021,
            Content: periodicidadeAnual,
          };
        default:
          return null;
      }
    }

    function getListaGraficos() {
      const listaGraficosESic = eSic?.ListaGraficos.map(graficoESic => {
        switch (graficoESic.TipoGrafico) {
          case 1:
            return {
              id: graficoESic.Id,
              position: graficoESic.Posicao,
              component: (
                <PieGraphic
                  id={graficoESic.Id}
                  titulo={graficoESic.Titulo}
                  data={graficoESic.Dados}
                  legenda={graficoESic.LegendaCampoValor}
                  height="370px"
                  filterDescription={graficoESic.DescricaoPeriodo}
                  hasFilter
                  contentFilter={getFilterContent(
                    graficoESic.Exibicao,
                    periodicidade
                  )}
                  showEyeButton={showEyeButton}
                />
              ),
            };
          case 2:
            return {
              id: graficoESic.Id,
              position: graficoESic.Posicao,
              component: (
                <ColumnGraphic
                  id={graficoESic.Id}
                  titulo={graficoESic.Titulo}
                  data={graficoESic.Dados}
                  height="370px"
                  filterDescription={graficoESic.DescricaoPeriodo}
                  hasFilter
                  contentFilter={getFilterContent(graficoESic.Exibicao)}
                  showEyeButton={showEyeButton}
                />
              ),
            };
          case 3:
            return {
              id: graficoESic.Id,
              position: graficoESic.Posicao,
              component: (
                <BarGraphic
                  id={graficoESic.Id}
                  titulo={graficoESic.Titulo}
                  data={graficoESic.Dados}
                  height="370px"
                  filterDescription={graficoESic.DescricaoPeriodo}
                  hasFilter
                  contentFilter={getFilterContent(graficoESic.Exibicao)}
                  showEyeButton={showEyeButton}
                />
              ),
            };
          case 4:
            return {
              id: graficoESic.Id,
              position: graficoESic.Posicao,
              component: (
                <DonutGraphic
                  id={graficoESic.Id}
                  titulo={graficoESic.Titulo}
                  data={graficoESic.Dados}
                  legenda={graficoESic.LegendaCampoValor}
                  height="370px"
                  filterDescription={graficoESic.DescricaoPeriodo}
                  hasFilter
                  contentFilter={getFilterContent(graficoESic.Exibicao)}
                  showEyeButton={showEyeButton}
                />
              ),
            };
          default:
            return null;
        }
      });
      setListaGrafico(
        listaGraficosESic.filter(itemGrafico => {
          return itemGrafico;
        })
      );
    }

    if (eSic && periodicidade) getListaGraficos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eSic, periodicidade]);

  function createElement(el) {
    return (
      <div
        key={el.id}
        data-grid={{ ...el.position, isDraggable: false, static: true }}
      >
        {el.component}
      </div>
    );
  }

  function goBack() {
    history.push('/');
  }

  return (
    <>
      {eSic && (
        <>
          <PageTitle titulo={eSic.TituloPagina} />
          <ImageButton
            type="default"
            shape="round"
            imagem={eSic.Icone}
            uri={eSic.UrlAplicacao || null}
            tooltip="Acesse o Portal do e-SIC"
          />

          {eSic?.Informacao?.Texto && (
            <Informacao
              titulo={eSic.Informacao.Titulo}
              informacao={eSic.Informacao.Texto}
            />
          )}

          {visoesESic != null && (
            <ESicVisoes dados={visoesESic} formularios={formularioESIC} />
          )}
        </>
      )}

      <div style={gridContainer}>
        <div style={gridBackground} />
        <ResponsiveReactGridLayout
          breakpoints={{ lg: 1440, md: 1024, sm: 768 }}
          cols={{ lg: 12, md: 12, sm: 1 }}
        >
          {_.map(listaGrafico, el => createElement(el))}
        </ResponsiveReactGridLayout>
      </div>

      <Row>
        <ButtonGoBack OnClick={goBack} MarginLeft="10px" />
      </Row>
    </>
  );
}

function Container() {
  return (
    <ListaGraficoContext>
      <ESic />
    </ListaGraficoContext>
  );
}

export default Container;
