import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getItemVisaoService } from '../services/visaoItem.service';
import {
  Informacao,
  Typography,
  Icons,
  Row,
  Col,
  ButtonGoBack,
  Card,
  Button,
  Tooltip,
} from '~/components';
import { iconEnum } from '~/modules/Helper/iconEnum';
import { ChooseIconFile } from '~/modules/Helper/chooseIconFile';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';

function VisaoItem({ match }) {
  const history = useHistory();
  const [visaoItem, setVisaoItem] = useState(null);

  const { corTema } = useSelector(state => state.configuration);

  useEffect(() => {
    async function getItemVisao() {
      const { modulo, visao, itemVisao } = match.params;
      const response = await getItemVisaoService(modulo, visao, itemVisao);

      if (response?.data) setVisaoItem(response.data);
    }

    getItemVisao();
  }, [match.params]);

  function goBack() {
    history.goBack();
  }

  return (
    <>
      <Typography.Title level={3}>{visaoItem?.Titulo}</Typography.Title>
      <Informacao
        titulo="Informações"
        informacao={visaoItem?.TextoInformativo}
      />
      {visaoItem?.NomeArquivo && (
        <Card
          title="Arquivo"
          size="small"
          style={{
            padding: 0,
            color: corTema,
            cursor: 'pointer',
          }}
        >
          <Tooltip title="Baixar Arquivo" placement="topRight">
            <Row onClick={() => fileManagerPaiDownload(visaoItem?.UrlArquivo)}>
              <Col style={{ marginRight: 5 }}>
                <Typography.Text>
                  {iconEnum
                    .find(
                      x =>
                        x.name ===
                        `file-${ChooseIconFile(
                          visaoItem?.NomeArquivo?.split('.', 2)[1].toLowerCase()
                        )}`
                    )
                    ?.value({ fontSize: '20px' })}
                </Typography.Text>
              </Col>
              <Col lg={22}>
                <Typography.Text role="link" tabIndex="0" aria-hidden="true">
                  {visaoItem?.NomeArquivo}
                </Typography.Text>
              </Col>
              <Col lg={1}>
                <Button
                  style={{ marginLeft: '10px' }}
                  type="primary"
                  shape="circle"
                  icon={<Icons.DownloadOutlined />}
                />
              </Col>
            </Row>
          </Tooltip>
        </Card>
      )}
      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default VisaoItem;
