import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const NumberRegistersArea = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ant-pagination {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-select {
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-pagination-item-active a,
  .ant-pagination-item,
  .ant-pagination-item a,
  .ant-select,
  .ant-pagination-prev svg,
  .ant-pagination-next svg,
  .ant-select-arrow svg,
  .ant-pagination-item:focus a {
    color: ${({ corTituloGrid }) => `${corTituloGrid}`};
  }

  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-pagination-item:focus {
    border-color: ${({ corTituloGrid }) => `${corTituloGrid}`};
  }

  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a,
  .ant-pagination-item:hover a,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${({ corTituloGrid }) => `${corTituloGrid}`};
    color: ${({ corTituloGrid }) => `${corTituloGrid}`};
  }

  @media (max-width: 795px) {
    width: 100%;
    .ant-select {
      width: 100%;
      margin-right: 0px;
    }
  }

  @media (max-width: 700px) and (min-width: 545px) {
    .ant-select {
      width: auto;
      margin-right: 15px;
    }
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 30px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;

  .ant-typography {
    color: ${({ corTituloGrid }) => `${corTituloGrid}`};
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const UltimaAtualizacaoArea = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  font-size: ${({ theme }) => `${12 + 2 * theme.zoomFonte}px`};

  .title {
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .dates {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;

    p {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
`;
