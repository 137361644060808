import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Button } from '~/components';
import { ContainerButtons } from './style';
import { alfabeto } from '../constants/alfabeto';

function GlossarioButtons({ letrasAtivas = [] }) {
  const verificaBotaoDesabilitado = letra =>
    letrasAtivas.includes(letra) === false;

  return (
    <ContainerButtons
      bodyStyle={{
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {alfabeto.map(letra => (
        <HashLink to={`#${letra}`} key={letra}>
          <Button type="primary" disabled={verificaBotaoDesabilitado(letra)}>
            {letra}
          </Button>
        </HashLink>
      ))}
    </ContainerButtons>
  );
}

export default GlossarioButtons;
