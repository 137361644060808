import styled from 'styled-components';
import { Layout } from 'antd';

export const Header = styled(Layout.Header)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 160px;
    background: url(${({ src }) => `'${src}'`}) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-color: #fff;
  }
`;
