/* eslint-disable import/no-extraneous-dependencies */
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'leaflet/dist/leaflet.css';

import './config/reactotron';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import VLibras from '@djpfs/react-vlibras';

import GlobalStyle from '~/styles/global';
import { Internationalization, Theme, Loader } from '~/components';

import Routes from './routes';

import store, { persistor } from './store';
import MenuProvider from '~/contexts/menu.context';
import { UrlProvider } from '~/contexts/url.context';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Internationalization>
          <Theme>
            <UrlProvider>
              <MenuProvider>
                <Loader>
                  <VLibras />
                  <Routes />
                  <GlobalStyle />
                </Loader>
              </MenuProvider>
            </UrlProvider>
          </Theme>
        </Internationalization>
      </PersistGate>
    </Provider>
  );
}

export default App;
