import React from 'react';
import { Tooltip } from 'antd';
import * as S from './MapButton.styled';

function MapButton({ rua, numero, bairro, cidade }) {
  let query = `${rua || ' '} ${numero || ''} ${bairro || ''} ${cidade || ''}`;
  query = query.replace(/\s/g, '+');

  function redirect() {
    window.open(`https://www.google.com/maps/search/?api=1&query=${query}`);
  }

  return (
    <Tooltip title="Clique Para Visualizar o Mapa">
      <S.ImageButton shape="round" type="primary" onClick={redirect}>
        <S.Icon />
      </S.ImageButton>
    </Tooltip>
  );
}
export default MapButton;
