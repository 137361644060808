import styled from 'styled-components';
import { Table as AntDesignTable } from 'antd';

export const Table = styled(AntDesignTable)`
  .ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
    opacity: 0.9;
  }
  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: unset;
    opacity: 0.9;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px 6px;
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 14px 14px;
  }
  .ant-empty-img-simple-g {
    stroke: #d9d9d9;
  }
  .ant-empty-img-simple-path {
    fill: #fafafa;
  }
  .ant-empty-description,
  .ant-empty-img-simple-ellipse {
    color: #c9c9c9;
  }

  .ant-table-column-sorters-with-tooltip {
    text-align: center;

    .ant-table-column-title,
    .ant-table-cell,
    .btn-chave-visao,
    .btn-link-externo {
      font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
    }
  }
`;

export const TableResize = styled.section`
  @media only screen and (max-width: 1200px) {
    .ant-table-expanded-row-fixed {
      width: auto !important;
    }

    .ant-table-placeholder td {
      padding-left: 0px !important;
    }

    .ant-table {
      background: #ffff !important;
    }

    .ant-pagination {
      overflow-wrap: break-word;
      flex-wrap: wrap;
    }

    div.dates {
      flex-wrap: wrap;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block !important;
    }

    table {
      width: auto !important;
    }

    thead tr {
      position: absolute !important;
      top: -9999px !important;
      left: -9999px !important;
    }

    tr:first-child {
      margin-top: -2px;
    }

    tr {
      border: 1px solid #ccc !important;
      border-bottom: 2px solid #eee !important;
    }

    tr:nth-child(even) {
      background: ${({ theme }) => `${theme.corFundoGrid}`} !important;

      & td.ant-table-cell {
        background: ${({ theme }) => `${theme.corFundoGrid}`} !important;
      }

      & td.ant-table-column-sort {
        background: ${({ theme }) => `${theme.corFundoGrid}`} !important;
      }
    }

    tr:nth-child(odd) {
      background: ${({ theme }) => `${theme.corFundoGrid}70`} !important;

      & td.ant-table-cell {
        background: ${({ theme }) => `${theme.corFundoGrid}70`} !important;
      }

      & td.ant-table-column-sort {
        background: ${({ theme }) => `${theme.corFundoGrid}70`} !important;
      }
    }

    td {
      border: none !important;
      border-bottom: 1px solid #eee !important;
      min-height: 1.25em !important;
      white-space: normal !important;
      text-align: left !important;
      display: flex !important;
    }

    td:before {
      width: 45% !important;
      padding-right: 10px !important;
      font-weight: bold !important;
      color: #006b76 !important;
    }

    td:before {
      content: attr(data-title);
    }

    button.btn-chave-visao {
      padding: 0 !important;
      height: auto !important;
      flex: 1 !important;
      white-space: normal !important;
    }

    td > span {
      width: 50% !important;
    }
  }

  @media only screen and (max-width: 820px) and (min-width: 700px) {
    ul.ant-pagination {
      justify-content: center !important;
    }

    ul.ant-pagination > li.ant-pagination-total-text {
      white-space: nowrap !important;
      min-width: 90%;
    }
  }

  @media only screen and (max-width: 580px) and (min-width: 440px) {
    ul.ant-pagination {
      justify-content: center !important;
    }

    ul.ant-pagination > li.ant-pagination-total-text {
      white-space: nowrap !important;
      min-width: 90%;
    }
  }

  @media only screen and (max-width: 440px) {
    .ant-table-footer > div:first-child {
      display: contents !important;
    }

    ul.ant-pagination {
      flex-direction: column !important;
      align-items: center !important;
      width: 100% !important;
    }
  }
`;
