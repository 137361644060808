import React, { useEffect } from 'react';
import * as ModuloService from '../../../services/modulos.service';
import { CHAVES_MODULO } from '../../Helper/chave-modulo-constants';
import FaleConoscoForm from '../components/faleConosco.form.component';

function FaleConosco() {
  useEffect(() => {
    ModuloService.verificaModuloHabilitado(CHAVES_MODULO.FALE_CONOSCO);
  }, []);

  return <FaleConoscoForm />;
}

export default FaleConosco;
