import styled from 'styled-components';
import { Collapse } from 'antd';

const Accordion = styled(Collapse)`
  div.ant-collapse-content,
  div.ant-collapse-content > .ant-collapse-content-box {
    background-color: transparent !important;
  }
  div.ant-collapse-header {
    font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
  }
`;

export default Accordion;
