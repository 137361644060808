import { TAMANHO_HOME_ITEM_ENUM } from '~/modules/Helper/tamanhoHomeItemEnum';

export const tamanhoFonteDescricaoGrafico = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '1.35rem';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '1.7rem';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '2.05rem';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '2.40rem';
    default:
      return '1rem';
  }
};

export const tamanhoFonteDescricaoDestaqueMedio = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '24px';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '34px';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '44px';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '54px';
    default:
      return '22px';
  }
};

export const tamanhoFonteDestaqueMedio = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '2.30rem';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '3.5rem';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '4.7rem';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '5.9rem';
    default:
      return '2.15rem';
  }
};

export const tamanhoFonteIconeMedio = tamanho => {
  switch (tamanho) {
    case TAMANHO_HOME_ITEM_ENUM.T_3x6:
    case TAMANHO_HOME_ITEM_ENUM.T_3x9:
    case TAMANHO_HOME_ITEM_ENUM.T_3x12:
      return '9rem';
    case TAMANHO_HOME_ITEM_ENUM.T_4x12:
      return '12rem';
    case TAMANHO_HOME_ITEM_ENUM.T_5x12:
      return '15rem';
    case TAMANHO_HOME_ITEM_ENUM.T_6x12:
      return '18rem';
    default:
      return '7rem';
  }
};
